import React, {useEffect, useState} from "react";

import "./user.modules.css"
import UserForm from "./UserForm";
import {useParams} from "react-router-dom";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";
import {useGetUserByIdQuery} from "./usersApi";

const UpdateUser: React.FC = () => {

    let {userId} = useParams();
    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getUserById, {userId}, {
        redirectToErrorPage: true,
        skip: !userId
    });

    const userByIdApi = useGetUserByIdQuery(userId);
    const [crumbs, setCrumbs] = useState<any[]>([]);

    useEffect(() => {
        let userObj = userByIdApi?.data;
        if (userObj) {
            setCrumbs([
                {content: "Home", url: "/home"},
                {content: "Users", url: "/access-control/users"},
                {content: userObj?.username, url: ""}
            ]);

            document.title = `${userObj?.username}`;
        }
    }, [userByIdApi]);

    return (
        <UserForm crumbs={crumbs} title={"Update User"} btnText={"Update"} isEditable={hasPermission(["USR-U"])}/>
    );
};
export default UpdateUser;
