import CannedFileNameForm from "./CannedFileNameForm";
import {useState} from "react";

const CreateCannedFileName = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/"},
        {content: "Canned File Names", url: "/canned-file-names"},
        {content: "Create Canned File Name", url: "/canned-file-names/create"},
    ]);


    return (
        <>
            <CannedFileNameForm title={"Create Canned File Name"} btnText={"Create"}
                                crumbs={crumbs}/>
        </>
    )
}
export default CreateCannedFileName;