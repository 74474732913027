import React, {FC} from "react";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {useGetAssignmentLogsQuery} from "../productsApi";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";
import {formatValueAsDate} from "../../../helpers/utils/DateUtils";


interface props {
    productId?: string | null,
}

const AssignmentLogs: FC<props> = ({productId}) => {

    const columns: DataTableColumn[] = [
        {
            label: "Type",
            accessor: "assignmentInfo.assigneeType",
        },
        {
            label: "Assigned To",
            accessor: "assignmentInfo.assigneeId",
            cell:  (props : any) => {
                return <>
                    {props.value ? <span>{props.value}</span> : '-'}
                </>
            },
        },
        {
            label: "Assigned By",
            accessor: "assignmentInfo.assignedBy",
            cell:  (props : any) => {
                return <>
                    {props.value ? <span>{props.value}</span> : '-'}
                </>
            },
        },
        {
            label: "Assigned On",
            accessor: "assignmentInfo.assignedDate",
            cell: (props: any) => {
                return formatValueAsDate(props.row.original.assignmentInfo.assignedDate);
            }
        },
        {
            label: "Reason",
            accessor: "reason",
        },
    ]

    return (
        <>
            <div className="bg-surface-2 rounded-md p-3 mt-3">
                <div className="flex gap-2 items-center justify-between border-b border-surface-3 pb-2">
                    <h3 className="text-text-1 font-semibold">Assignment Logs</h3>
                </div>
                <div className='overflow-y-auto max-h-56'>

                    <DataTable
                        columns={columns}
                        actions={{
                            fetchAction: useGetAssignmentLogsQuery
                        }}
                        defaultFilter={{
                            productId: productId
                        }}
                        appendFilterToUrl={false}
                        renderOptions={{
                            skipUserSettingRender:true
                        }}
                    />

                </div>
            </div>
        </>
    )
}

export default AssignmentLogs;