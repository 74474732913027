import ModalButton from "./ModalButton";
import IconButton from "./IconButton";
import {FaStethoscope} from "react-icons/fa";
import AuditableInfoModal from "../AuditableInfoModal";
import React, {FC} from "react";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";

interface props {
    obj: any
}

const AuditableInfoButton: FC<props> = ({obj}) => {

    return (
        <>
            {obj && hasPermission(["AUDIT-V"]) && <div className={"flex gap-3"}>
                <ModalButton
                    triggerButton={
                        <IconButton icon={<FaStethoscope/>}
                                    title={"Auditable Info"} hasBackground={true}/>
                    }
                    onOpen={() => {
                    }}
                    modal={
                        <AuditableInfoModal
                            show={true}
                            setShow={() => {
                            }}
                            createdBy={obj?.createdBy}
                            creationDate={obj?.creationDate}
                            modifiedBy={obj?.modifiedBy}
                            modifiedDate={obj?.modifiedDate}
                        />
                    }
                />
            </div>}

        </>
    )
}
export default AuditableInfoButton;