import MetroAreaForm from "./MetroAreaForm";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";
import {useGetMetroAreaByIdQuery} from "./metroAreasApi";

const UpdateMetroArea = () => {

    let {metroAreaId} = useParams();
    // @ts-ignore
   fetchWithExtraOptions(strApi.endpoints.getMetroAreaById, {metroAreaId}, {
        redirectToErrorPage: true,
        skip: !metroAreaId
    });

    const metroAreaByIdApi = useGetMetroAreaByIdQuery(metroAreaId);

    const [crumbs, setCrumbs] = useState<any[]>([]);

    useEffect(() => {
        let metroAreaObj = metroAreaByIdApi?.data;
        if (metroAreaObj) {
            setCrumbs([
                {content: "Home", url: "/home"},
                {content: "Metro Areas", url: "/metro-areas"},
                {content: metroAreaObj?.name, url: ""}
            ]);

            document.title = `${metroAreaObj?.name}`;
        }
    }, [metroAreaByIdApi]);

    return (
        <>
            <MetroAreaForm crumbs={crumbs} title={"Update Metro Area"} btnText={"Update"}
                           isEditable={hasPermission(["METR-U"])}/>

        </>
    )
}
export default UpdateMetroArea;