import React, {FormEvent, useState} from "react";
import {hasPermission} from "../../../../helpers/utils/AccessControlUtils";
import {useParams} from "react-router-dom";
import {DataTable} from "../../../../components/common/datatable/DataTable";
import {useSearchRegionsQuery} from "../regionApi";
import {FaPlus, FaTrash} from "react-icons/fa6";
import ModalButton from "../../../../components/common/form/ModalButton";
import AddUpdateRegionModal from "../modal/AddUpdateRegionModal";
import ConfirmationModal from "../../../../components/layout/modal/ConfirmationModal";
import {useDeleteRegionByIdMutation} from "../regionApi";
import {DataTableColumn} from "../../../../components/common/datatable/DataTableTypes";

const RegionTab = () => {

    let {customerId} = useParams();
    const [selectedRegionId, setSelectedRegionId] = useState("");
    const [deleteRegionApi, {isLoading: deleteRegionApiResponse}] = useDeleteRegionByIdMutation();

    const handleDeleteRegionSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (selectedRegionId) {
            return deleteRegionApi(selectedRegionId);
        }
    }

    let columns: DataTableColumn[] = [
        {
            label: "Name", accessor: "name",
            cell: (props: any) => {
                if (hasPermission(["CUSRGN-R"])) {
                    return <>

                        <ModalButton
                            triggerButton={
                                <span
                                    className="text-highlight-6 hover:text-highlight-7 cursor-pointer">{props.row.original.name}
                                </span>
                            }
                            onOpen={() => {
                            }}
                            modal={
                                <AddUpdateRegionModal
                                    show={true}
                                    setShow={() => {
                                    }}
                                    title={"Update Region"}
                                    customerId={customerId}
                                    regionId={props.row.original.id}
                                    btnText={"Update"}
                                    isEditable={hasPermission(["CUSRGN-U"])}
                                />
                            }
                        />
                    </>
                } else {
                    return <>
                        <span>{props.row.original.name}</span>
                    </>
                }
            }
        },
        {label: "State", accessor: "stateName", canSort: false},
        {
            label: "Action",
            visibility: {
                canColumnShow: hasPermission(["CUSRGN-D"]),
                showByDefault: hasPermission(["CUSRGN-D"]),
                hasShowSetting: false,
            },
            accessor: "creationDate",
            canSort: false,
            cell: (props: any) => {
                return <>
                    <div className="ml-4">
                        <ModalButton
                            triggerButton={
                                <FaTrash
                                    className={"cursor-pointer text-highlight-3 text-lg hover:text-highlight-7"}
                                    title={"Delete Region"}/>
                            }
                            onOpen={() => {
                                setSelectedRegionId(props.row.original.id)
                            }}
                            modal={
                                <ConfirmationModal
                                    show={true}
                                    setShow={() => {
                                    }}
                                    title={"Delete Region"}
                                    bodyMessage={"Are you sure you want to delete this region ?"}
                                    onSubmit={handleDeleteRegionSubmit}
                                    isLoading={deleteRegionApiResponse}
                                />
                            }
                        />
                    </div>
                </>
            }
        }
    ]

    return (
        <>
            {hasPermission(["CUSRGN-C"]) &&
                <div className={"flex justify-end"}>
                    <ModalButton
                        triggerButton={<div
                            className="h-7 w-10 text-text-1 rounded-md bg-highlight-3 flex justify-center items-center cursor-pointer hover:bg-highlight-7 absolute top-0 left-auto">
                            <FaPlus/>
                        </div>}
                        onOpen={() => {
                        }}
                        modal={
                            <AddUpdateRegionModal
                                show={true}
                                setShow={() => {
                                }}
                                title={"Create Region"}
                                customerId={customerId}
                                btnText={"Create"}
                            />
                        }
                    />
                </div>
            }

            <div className='-mt-4'>
                <DataTable
                    columns={columns}
                    actions={{
                        fetchAction: useSearchRegionsQuery
                    }}
                    defaultFilter={{
                        customerId: customerId
                    }}
                    userSettingGroup={"Region"}
                    appendFilterToUrl={false}
                />
            </div>
        </>
    )
}
export default RegionTab;