import CannedFileNameForm from "./CannedFileNameForm";
import {useGetCannedFileNameByIdQuery} from "./cannedFileNamesApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";

const UpdateCannedFileName = () => {

    const {cannedFileNameId} = useParams();
    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getCannedFileNameById, {cannedFileNameId}, {
        redirectToErrorPage: true,
        skip: !cannedFileNameId
    });

    const cannedFileNameByIdApi = useGetCannedFileNameByIdQuery(cannedFileNameId);
    const [crumbs, setCrumbs] = useState<any[]>([]);

    useEffect(() => {
        let obj = cannedFileNameByIdApi?.data;
        if (obj) {
            setCrumbs([
                {content: "Home", url: "/home"},
                {content: "Canned File Names", url: "/canned-file-names"},
                {content: obj?.name, url: ""}
            ]);

            document.title = `${obj?.name}`;
        }
    }, [cannedFileNameByIdApi]);

    return (
        <>
            <CannedFileNameForm title={"Update Canned File Name"}
                                btnText={"Update"}
                                crumbs={crumbs}
                                isEditable={hasPermission(["CANFN-U"])}/>
        </>
    )
}
export default UpdateCannedFileName;