import {useNavigate, useParams} from "react-router-dom";
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import {OptionType} from "../../../helpers/utils/StyleUtils";
import Breadcrumb, {Crumb} from "../../../components/common/Breadcrumbs";
import InputField from "../../../components/common/form/InputField";
import Form from "../../../components/common/form/Form";
import {useGetAllTenantsQuery} from "../../user/customer/tenantApi";
import SelectField from "../../../components/common/form/SelectField";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import {jwtDecode} from "../../../helpers/utils/AccessControlUtils";
import {
    useCreateFollowUpTypeMutation,
    useUpdateFollowUpTypeIdMutation,
    useGetFollowUpTypeByIdQuery
} from "./followUpTypesApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {skipToken} from "@reduxjs/toolkit/query";
import AuditableInfoButton from "../../../components/common/form/AuditableInfoButton";
import {Routes as AppRoutes} from "../../../routes";
import ButtonTray from "../../../components/common/form/ButtonTray";

interface props {
    crumbs: Crumb[];
    title: string,
    btnText: string,
    isEditable?: boolean
}

const FollowUpTypeForm: FC<props> = ({crumbs, title, btnText, isEditable = true}) => {

    const navigate = useNavigate();
    const tenantList = useGetAllTenantsQuery("");
    let {typeId} = useParams();

    const [followUpObj, setFollowUpObJ] = useState<any>({
        "hexColor": "#000000"
    });
    const [createFollowUpTypeApi, createFollowUpTypeApiResponse] = useCreateFollowUpTypeMutation();
    const [updateFollowUpTypApi, updateFollowUpTypApiResponse] = useUpdateFollowUpTypeIdMutation();
    const followUpById = useGetFollowUpTypeByIdQuery(typeId ?? skipToken);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;
        setFollowUpObJ({
            ...followUpObj,
            [name]: value,
        })
    }

    useEffect(() => {
        let obj = followUpById?.data;
        if (obj) {
            setFollowUpObJ(
                {
                    ...obj,
                    tenantId: {
                        value: obj?.tenantId,
                        label: tenantList?.data?.filter((el: any) => el.id === obj?.tenantId)?.[0].name
                    }
                }
            )
        }

    }, [followUpById?.data, tenantList?.data])

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const commonObj = {
            ...followUpObj,
            tenantId: followUpObj?.tenantId ? followUpObj?.tenantId.value : null
        };

        const createOrUpdateUser = async () => {
            if (btnText === "Create") {
                await createFollowUpTypeApi(commonObj).unwrap();
            } else {
                await updateFollowUpTypApi({
                    id: typeId,
                    requestBody: commonObj,
                }).unwrap();
            }
        };

        await handleRTKQuery(createOrUpdateUser, () => {
            setFollowUpObJ({
                "hexColor": "#000000"
            });
            navigate("/follow-up-types");
        });

    }

    return (
        <>
            <div className="adduser_container px-4 py-2">

                <div className="justify-between sm:block md:flex lg:flex">
                    <Breadcrumb crumbs={crumbs}/>
                    <AuditableInfoButton obj={followUpById?.data}/>
                </div>

                <div className="flex justify-center mt-3">
                    <div className="bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">{title}</h3>
                        <Form onSubmit={handleSubmit} fields={<>
                            <div className="grid grid-cols-12 adduser_Wrapper">

                                <InputField
                                    name={"name"}
                                    label="Name"
                                    onChange={handleOnChange}
                                    value={followUpObj?.name || ""}
                                    type="text"
                                    placeholder="Enter Name..."
                                    required={true}
                                />

                                <InputField
                                    name={"hexColor"}
                                    label="Color"
                                    onChange={handleOnChange}
                                    value={followUpObj?.hexColor || ""}
                                    type="color"
                                    placeholder="Enter Color..."
                                    required={true}
                                    className={"h-12 w-14"}
                                />

                                {jwtDecode()?.customerLevel === "SYS" && <SelectField
                                    placeholder='Select Tenant...'
                                    value={followUpObj?.tenantId || null}
                                    options={createOptionListForSelectTag(tenantList?.data, "name", "id")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "tenantId", value: selectedOption})
                                    }}
                                    required={true}
                                    label={"Tenant"}
                                />}

                            </div>

                            <ButtonTray
                                buttons={[
                                    {
                                        buttonProps: {
                                            btnText: "Cancel",
                                            onClick: () => navigate(AppRoutes.FollowUpTypes.path),
                                            type: "close",
                                        },
                                        buttonType: "button"
                                    },
                                    {
                                        buttonProps: {
                                            btnText: "Submit",
                                            type: "submit",
                                            isLoading: (createFollowUpTypeApiResponse.isLoading || updateFollowUpTypApiResponse.isLoading),
                                            isVisible: isEditable
                                        },
                                        buttonType: "button",

                                    }
                                ]}
                                align={"end"}
                                gap={2}
                            />
                        </>}
                        />
                    </div>
                </div>
            </div>

        </>
    )
}
export default FollowUpTypeForm;