import React, {FC} from "react";

const SkeletonLoader: FC<{ count: number; className?:string}> = ({count,className=""}) => {
    return <>
        {
            Array.from({length: count}, (x) => x).map((_,index) => {
                return (
                    <div key={index} role="status" className={" animate-pulse " +className}>
                        <div className="p-3.5 h-2.5 bg-highlight-5 rounded-full w-full"></div>
                        <span className="sr-only">Loading...</span>
                    </div>
                )
            })
        }
    </>

}
export default SkeletonLoader