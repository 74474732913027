import {useState} from "react";
import SimplifileConfigForm from "./SimplifileConfigForm";

const CreateSimplifileConfig = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/"},
        {content: "Simplifile Configs", url: "/simplifile-configs"},
        {content: "Create Simplifile Config", url: ""},
    ]);

    return (
        <>
            <SimplifileConfigForm crumbs={crumbs} title={"Create Simplifile Config"} btnText={"Create"}/>
        </>
    )
}

export default CreateSimplifileConfig;