import {strApi} from "../../../app/strApi";
import {
    getAuthorizationHeader,
    getFileDownloadHeaders,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../../helpers/utils/RtkQueryUtils";
import {getFileName, serialize} from "../../../helpers/utils/Utils";
import {downloadFile} from "../../../helpers/utils/FileUtils";

export const partnerPricingApi = strApi.injectEndpoints({
    endpoints: (build) => ({

        searchPartnerPricing: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/partner-pricings/search?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['partnerPricing'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        getPartnerPricingById: build.query({
            query: (regionId) => {
                return {
                    url: `/partner-pricings/${regionId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        createPartnerPricing: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/partner-pricings`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['partnerPricing'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await  handleQueryErrorAndSuccess(queryFulfilled, "Created" , "Partner Pricing" );
            },
        }),

        updatePartnerPricing: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/partner-pricings/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['partnerPricing', 'partnerPricingById'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await  handleQueryErrorAndSuccess(queryFulfilled,"Updated" , "Partner Pricing");
            },
        }),

        deletePartnerPricingById: build.mutation({
            query: (id) => {
                return {
                    url: `/partner-pricings/${id}`,
                    method: 'DELETE',
                    headers: getAuthorizationHeader()
                };
            },
            invalidatesTags: ['partnerPricing'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await  handleQueryErrorAndSuccess(queryFulfilled, "Deleted" , "Partner Pricing");
            },
        }),

        exportPartnerPricingData: build.query({
            query: (query) => {
                return {
                    url: `/partner-pricings/export-csv`,
                    method: 'GET',
                    params: query,
                    headers: getFileDownloadHeaders(),
                    responseType: 'arraybuffer',
                    responseHandler: async (response: { blob: () => any; }) => {
                        const blob = await response.blob();
                        downloadFile(blob, getFileName("partnerPricing"))
                        return {success: true};
                    },
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
    })
})

export const {
    useSearchPartnerPricingQuery,
    useUpdatePartnerPricingMutation,
    useCreatePartnerPricingMutation,
    useDeletePartnerPricingByIdMutation,
    useGetPartnerPricingByIdQuery,
    useLazyExportPartnerPricingDataQuery
} = partnerPricingApi