import React, {FC, useCallback, useEffect, useRef} from "react";
import {IoIosClose} from "react-icons/io";
import Draggable from "react-draggable";

type ModalProps = {
    fields: React.ReactNode;
    title: string;
    show: boolean;
    setShow: (show: boolean) => void;
    className?: string;
};

const Modal: FC<ModalProps> = ({fields, title, show, setShow, className}) => {
    const draggleRef = useRef(null);

    const handleEscape = useCallback((e: any) => {
        if (e.key === 'Escape') {
            setShow(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", handleEscape, false);

        return () => {
            document.removeEventListener("keydown", handleEscape, false);
        };
    }, [handleEscape]);

    function dismissModal(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        setShow(false);
    }

    const ModalHeader = () => {
        return <div
            className="flex justify-between items-center pb-4 pt-4 border-b border-surface-3 mx-5 handler cursor-pointer">
            <div className="flex gap-2 items-center">
                <h2 className="cursor-pointer text-lg font-semibold text-text-2">{title}</h2>
            </div>
            <button
                className="text-3xl hover:text-gray-800 text-text-1"
                onClick={dismissModal}
                aria-label="Close Modal">
                <IoIosClose className='hover:text-highlight-7'/>
            </button>
        </div>
    };

    return (
        <>
            {show && <div
                className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full overlay index model_overflow">
                <Draggable handle=".handler" bounds='parent'>
                    <div
                        className={`border border-surface-4 relative bg-surface-2 rounded-lg shadow-xl ${className} w-3/5`}
                        ref={draggleRef}>

                        <ModalHeader/>
                        <div className="p-4 md:p-5">{fields}</div>
                    </div>
                </Draggable>
            </div>
            }
        </>
    );
};

export default Modal;
