import {useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {Tabs} from "react-simple-tabs-component";
import TeamForm from "./TeamForm";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import MemberTab from "./member/MemberTab";
import LeaderTab from "./leader/LeaderTab";
import RuleTab from "./rule/RuleTab";
import AuditableInfoButton from "../../../components/common/form/AuditableInfoButton";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";
import {useGetTeamByIdQuery} from "./teamsApi";

const TeamDetailPage = () => {

    const {teamId} = useParams();
    const [crumbs, setCrumbs] = useState<any[]>([]);
    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getTeamById, {teamId}, {
        redirectToErrorPage: true,
        skip: !teamId
    });

    const teamByIdQuery = useGetTeamByIdQuery(teamId);

    useEffect(() => {
        const teamObj = teamByIdQuery?.data;
        if (teamObj) {
            setCrumbs([
                {content: "Home", url: "/home"},
                {content: "Teams", url: "/access-control/teams"},
                {content: teamObj?.teamName, url: ""}
            ]);

            document.title = `${teamObj?.teamName}`;
        }
    }, [teamByIdQuery?.data]);

    // Compute tabs using useMemo
    const tabs = useMemo(() => {
        const result: any[] = [];
        if (hasPermission(["TEAM-R"])) {
            result.push({
                label: 'Details',
                Component: TeamForm
            });
        }
        return result;
    }, []);

    // Compute bottomTabs using useMemo
    const bottomTabs = useMemo(() => {
        const result: any[] = [];
        if (hasPermission(["TEAM-V"])) {
            result.push({
                label: 'Members',
                Component: MemberTab
            });
            result.push({
                label: 'Leaders',
                Component: LeaderTab
            });
            result.push({
                label: 'Rules',
                Component: RuleTab
            });
        }
        return result;
    }, []);

    return (
        <>
            <div className="px-4 py-2">
                <div className={"justify-between sm:block md:flex lg:flex"}>
                    <Breadcrumb crumbs={crumbs}/>
                    <AuditableInfoButton obj={teamByIdQuery?.data}/>
                </div>
                <Tabs tabs={tabs} className="bootstrap-tabs-component mt-3"/>
                <Tabs tabs={bottomTabs} className="bootstrap-tabs-component mt-3"/>
            </div>
        </>
    )
}

export default TeamDetailPage;