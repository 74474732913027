import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import TenantPricingForm from "./TenantPricingForm";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";
import {useGetTenantPricingByIdQuery} from "./tenantPricingApi";

const UpdateTenantPricing = () => {

    let {tenantPricingId} = useParams();
    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getTenantPricingById, {tenantPricingId}, {
        redirectToErrorPage: true,
        skip: !tenantPricingId
    });

    const tenantByIdApi = useGetTenantPricingByIdQuery(tenantPricingId);
    const [crumbs, setCrumbs] = useState<any[]>([]);

    useEffect(() => {
        let obj = tenantByIdApi?.data;
        if (obj) {
            setCrumbs([
                {content: "Home", url: "/"},
                {content: "Tenant Pricing", url: "/accounting/tenant-pricing"},
                {content: obj?.productType, url: ""}
            ]);

            document.title = `${obj?.productType}`;
        }
    }, [tenantByIdApi]);

    return (
        <>
            <TenantPricingForm crumbs={crumbs} title={"Update Tenant Pricing"} btnText={"Update"}
                               isEditable={hasPermission(["TNPRC-U"])}/>
        </>
    )
}
export default UpdateTenantPricing;