import PlatformPricingForm from "./PlatformPricingForm";
import {useState} from "react";

const CreatePlatformPricing = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/"},
        {content: "Platform Pricing", url: "/accounting/platform-pricing"},
        {content: "Create Platform Pricing", url: "/accounting/platform-pricing/create"},
    ]);

    return (
        <>
            <PlatformPricingForm crumbs={crumbs}
                                 title={"Create Platform Pricing"}
                                 btnText={"Create"}></PlatformPricingForm>
        </>
    )
}
export default CreatePlatformPricing;