import React, {useState} from "react";

import "./user.modules.css"
import UserForm from "./UserForm";

const CreateUser: React.FC = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/"},
        {content: "Users", url: "/access-control/users"},
        {content: "Create User", url: "/access-control/users/create"},
    ]);

    return (
        <UserForm crumbs={crumbs} title={"Create User"} btnText="Create"/>
    );
};
export default CreateUser;
