import {FC, useEffect, useState} from "react";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {useParams} from "react-router-dom";
import ProductTabSection from "./ProductTabSection";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";
import {useGetOrderByIdQuery} from "../ordersApi";

const OrderDetailPage: FC = () => {

    let {orderId} = useParams();

    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getOrderById, {orderId}, {
        redirectToErrorPage: true,
        skip: !orderId
    });

    const orderQuery = useGetOrderByIdQuery(orderId);

    const [crumbs, setCrumbs] = useState<any[]>([])

    useEffect(() => {
        if (orderQuery?.data) {
            setCrumbs([
                {content: "Home", url: "/home"},
                {content: "Orders", url: "/orders"},
                {content: orderQuery?.data?.orderNumber, url: ""}
            ])

            document.title = `${orderQuery?.data?.orderNumber}`;
        }
    }, [orderQuery?.data]);

    return (
        <>
            <div className="px-5 py-5">
                <Breadcrumb crumbs={crumbs}/>
                <ProductTabSection orderQuery={orderQuery}/>
            </div>
        </>
    )
}
export default OrderDetailPage