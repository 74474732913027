import {FaArrowUpRightFromSquare} from "react-icons/fa6";
import React, {FC} from "react";
import {useLazyGetAuthenticatedLinkQuery} from "../simplifileApi";
import {openInNewTab} from "../../../helpers/utils/NavUtils";
import {AuthTokenPage} from "../AuthTokenPage";
import IconButton from "../../../components/common/form/IconButton";

interface SimplifileLinkButtonProps {
    tenantId: string;
    page: AuthTokenPage,
    objectId?: string
    className?: string
    includeLabel?: boolean;
}

const SimplifileLinkButton: FC<SimplifileLinkButtonProps> = (props: SimplifileLinkButtonProps) => {

    const itemType = props.page === AuthTokenPage.VIEW_PACKAGE ?
        'package' :
        'document';

    // This lazy hook allows us to call it at-will and respond to it by awaiting it.
    // Useful since we need to kick off an action using its data.
    const [getAuthenticatedLink] = useLazyGetAuthenticatedLinkQuery();

    const getLinkAndOpen = async () => {
        const authLink: { link: string } = await getAuthenticatedLink({
            pageType: AuthTokenPage[props.page],
            objectId: props.objectId,
            tenantId: props.tenantId
        }).unwrap();

        openInNewTab(authLink?.link);
    };

    return <IconButton icon={<FaArrowUpRightFromSquare/>}
                       title={`View this ${itemType} in SimpliFile`}
                       className={props.className}
                       label={props.includeLabel ? 'View' : ''}
                       onClick={getLinkAndOpen}/>
}

export default SimplifileLinkButton
