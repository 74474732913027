import {getFileName, serialize} from "../../helpers/utils/Utils";
import {strApi} from "../../app/strApi";
import {
    getAuthorizationHeader,
    getFileDownloadHeaders,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../helpers/utils/RtkQueryUtils";
import {downloadFile} from "../../helpers/utils/FileUtils";

export const productsApi = strApi.injectEndpoints({

    endpoints: (build) => ({
        // Define the endpoint for searching orders
        getProductById: build.query({
            query: (id) => {
                return {
                    url: `/products/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ["productById"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        searchProducts: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/products/search?${paramData}`,
                    method: 'POST',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        getTeamAndUserAssignments: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/products/work-assignments?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        updateProductStatus: build.mutation({
            query: ({productId, productStatus}) => {
                return {
                    url: `/products/update-product-status/${productId}?${serialize({productStatus: productStatus})}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader()
                };
            },
            invalidatesTags: ["productById"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Product Status")
            },
        }),

        getProductPricingById: build.query({
            query: (id) => {
                return {
                    url: `/product-pricings/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags: ["productPricing"],
        }),

        getProductPricingByProductId: build.query({
            query: (id) => {
                return {
                    url: `/product-pricings/product/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags: ["productPricing"],
        }),

        updateProductPricing: build.mutation({
            query: ({pricingId, body}) => {
                return {
                    url: `/product-pricings/${pricingId}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: body
                };
            },
            invalidatesTags: ["productPricing"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Product Pricing")
            },
        }),

        startProductFollowUp: build.mutation({
            query: ({productId, followUpObj}) => {
                return {
                    url: `/products/${productId}/start-follow-up`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: followUpObj
                };
            },
            invalidatesTags: ["productById"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        endProductFollowUp: build.mutation({
            query: (productId) => {
                return {
                    url: `/products/${productId}/close-follow-up`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                };
            },
            invalidatesTags: ["productById", "followUpTypeById", "products"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        updateAssignmentInfo: build.mutation({
            query: ({productId, assigneeId, assigneeType, reason}) => {
                return {
                    url: `/products/${productId}/assignment-info?${serialize({
                        assigneeId: assigneeId,
                        assigneeType: assigneeType,
                        reason: reason
                    })}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                };
            },
            invalidatesTags: ["productById", "assignmentLogs"],
            async onQueryStarted({assigneeId}, {queryFulfilled,}) {
                if (assigneeId) {
                    await handleQueryErrorAndSuccess(queryFulfilled, "Assigned", "Product")
                } else {
                    await handleQueryErrorAndSuccess(queryFulfilled, "UnAssigned", "Product")
                }
            },
        }),

        getAssignmentLogs: build.query({
            query: (data) => {
                let paramData = serialize(data);

                return {
                    url: `/products/assigment-logs?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ["assignmentLogs"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),


        assignProductToUser: build.mutation({
            query: () => {
                return {
                    url: `/products/next-order`,
                    method: 'PUT',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        findAllInvoiceLines: build.query({
            query: (productId) => {
                return {
                    url: `/products/invoice-lines?productId=${productId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags:["invoiceLines"]
        }),

        findAllInvoiceNotes: build.query({
            query: (productId) => {
                return {
                    url: `/products/invoice-notes?productId=${productId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags:["invoiceNotes"]
        }),

        exportProductData: build.query({
            query: (query) => {
                return {
                    url: `/products/export-csv`,
                    method: 'GET',
                    params: query,
                    headers: getFileDownloadHeaders(),
                    responseType: 'arraybuffer',
                    responseHandler: async (response: { blob: () => any; }) => {
                        const blob = await response.blob();
                        downloadFile(blob, getFileName("orders"))
                        return {success: true};
                    },
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        findOrderCountByStatuses: build.query({
            query: () => {
                return {
                    url: `/products/count-by-statuses`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

    }),
});

export const {
    useGetProductByIdQuery,
    useSearchProductsQuery,
    useLazySearchProductsQuery,
    useUpdateProductStatusMutation,
    useGetProductPricingByIdQuery,
    useGetProductPricingByProductIdQuery,
    useUpdateProductPricingMutation,
    useStartProductFollowUpMutation,
    useEndProductFollowUpMutation,
    useGetTeamAndUserAssignmentsQuery,
    useAssignProductToUserMutation,
    useUpdateAssignmentInfoMutation,
    useGetAssignmentLogsQuery,
    useFindAllInvoiceLinesQuery,
    useFindAllInvoiceNotesQuery,
    useLazyExportProductDataQuery,
    useFindOrderCountByStatusesQuery
} = productsApi;