import React, { ChangeEvent, useRef, useState, useEffect } from "react";
import InputField from "../../../../components/common/form/InputField";

interface Props {
    submitBtnText: string;
    handleSubmitAction: (personObj: any) => void;
    obj: any;
}

export const HelperInfoForm: React.FC<Props> = ({
                                                    submitBtnText,
                                                    handleSubmitAction,
                                                    obj
                                                }) => {
    const [helperObj, setHelperObj] = useState<any>(obj);
    const fileInputRef = useRef<any>(null);
    const lastSubmittedFile = useRef<File | null>(null);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: any; files?: null }) => {
        const { name, value, files } = "target" in e ? e.target : e;
        setHelperObj((prevHelperObj: any) => ({
            ...prevHelperObj,
            [name]: files ? files[0] : value,
        }));
    };

    // Trigger the submit action when helperObj changes
    useEffect(() => {
        if (helperObj.file && helperObj.file !== lastSubmittedFile.current) {
            handleSubmitAction(helperObj);
            lastSubmittedFile.current = helperObj.file; // Update last submitted file
        }
    }, [helperObj.file, handleSubmitAction]);

    return (
        <form className={`border border-surface-6 p-3 ${submitBtnText === "Add" ? "rounded-md" : "rounded-b-md"} bg-surface-2`}>
            <div className="grid grid-cols-12 gap-2">
                <InputField
                    label="Type"
                    value={helperObj?.label || ""}
                    type="text"
                    placeholder="Enter Type..."
                    onChange={handleOnChange}
                    name={"label"}
                    labelPosition={"top"}
                    disabled={true}
                />
            </div>
            <div className="grid gap-2 mt-3">
                <input
                    className={"w-full bg-surface-2 rounded-md border border-surface-4 text-text-1 form_control p-2"}
                    ref={fileInputRef}
                    onChange={handleOnChange}
                    name={"file"}
                    type="file"
                    accept={".pdf"}
                />
                <div className={"text-text-1"}> {helperObj?.file?.name}</div>
            </div>
        </form>
    );
};
