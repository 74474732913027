import React from "react";

export const sliceNames = Object.freeze({
    LOGIN: "login",
    ORDER: "order",
    GLOBAL: "global",
})

export const PageControl = [
        {
            "constant": "TOP",
            "displayName": "Top"
        },
        {
            "constant": "BOTTOM",
            "displayName": "Bottom"
        },
        {
            "constant": "BOTH",
            "displayName": "Both"
        }
    ]

export const LinkOpeningStyle = [
        {
            "constant": "CURRENT TAB",
            "displayName": "Current Tab"
        },
        {
            "constant": "NEW TAB",
            "displayName": "New Tab"
        }

    ]

export interface ModalProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}