import {useState} from "react";
import FollowUpTypeForm from "./FollowUpTypeForm";

const CreateFollowUpType = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/"},
        {content: "Follow Up Types", url: "/follow-up-types"},
        {content: "Create Follow Up Type", url: "/follow-up-types/create"},
    ]);

    return (
        <>
            <FollowUpTypeForm crumbs={crumbs} title={"Create Follow Up Type"} btnText={"Create"}/>
        </>
    )
}
export default CreateFollowUpType;