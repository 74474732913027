import {strApi} from "./strApi";
import {getAuthorizationHeader, handleQueryError} from "../helpers/utils/RtkQueryUtils";


export const globalApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        getEnums: build.query({
            query: () => ({
                url: `/utility/str/enums`,
                method: "GET",
                headers: getAuthorizationHeader()
            }),
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
    }),
})

export const {
    useGetEnumsQuery
} = globalApi;