import React from 'react'

import {IconType} from 'react-icons';
import {useNavigate} from "react-router-dom";
import {objectToQueryParams} from "./datatable/DataTableUtils";

interface FilterQuery {
    path: string,
    query: object
}

interface DashboardCardViewProps {
    label: string;
    icon: IconType;
    data: number;
    filterQuery: FilterQuery,
    isAllowedClick: boolean,
    isLoading: boolean
}

const DashboardCardView: React.FC<DashboardCardViewProps> = ({
                                                                 label,
                                                                 icon: Icon,
                                                                 data,
                                                                 filterQuery,
                                                                 isAllowedClick,
                                                                 isLoading
                                                             }) => {

    const navigate: any = useNavigate();

    return (
        <div
            className={`px-3 py-6 bg-surface-2 rounded hover:scale-105 transform ${isAllowedClick && "cursor-pointer"}`}
            onClick={() => {
                if (isAllowedClick) {
                    navigate({
                        pathname: filterQuery.path,
                        search: objectToQueryParams(filterQuery.query),
                        state: {detail: objectToQueryParams(filterQuery.query)}
                    })
                }
            }}>
            <div className="flex justify-between items-center">
                <div>
                    <h3 className="text-text-3 text-base hover:text-dark-blue hover:transition-all duration-500 ease-in-out">{label}</h3>
                    {isLoading ?
                        <i className="fa fa-spinner fa-spin text-highlight-3 text-3xl"></i>
                        : <h3 className="text-text-1 font-semibold text-xl">{data}
                        </h3>
                    }
                </div>
                <div className="w-14 h-24 items-center flex text-center justify-center">
                    <Icon className={`text-3xl text-highlight-3`}/>
                </div>
            </div>
        </div>
    )
}

export default DashboardCardView