import InputField from "../../../components/common/form/InputField";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {OptionType} from "../../../helpers/utils/StyleUtils";
import Form from "../../../components/common/form/Form";
import {useGetAllTenantsQuery} from "../customer/tenantApi";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import SelectField from "../../../components/common/form/SelectField";
import {useNavigate, useParams} from "react-router-dom";
import {useGetAllSubordinateCustomersQuery, useLazySearchCustomersQuery} from "../customer/customerApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useCreateTeamMutation, useGetTeamByIdQuery, useUpdateTeamMutation} from "./teamsApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {Routes as AppRoutes} from "../../../routes";
import ButtonTray from "../../../components/common/form/ButtonTray";

const TeamForm = () => {

    const {teamId} = useParams();

    const navigate = useNavigate();
    const tenantData = useGetAllTenantsQuery("");
    const [teamData, setTeamData] = useState<any>({});
    const [searchCustomer, customerData] = useLazySearchCustomersQuery();
    const subordinateData = useGetAllSubordinateCustomersQuery(teamData?.customerId?.value ?? "");
    const [createTeamApi, createTeamApiResponse] = useCreateTeamMutation();
    const [updateTeamApi, updateTeamApiResponse] = useUpdateTeamMutation();
    const teamByIdQuery = useGetTeamByIdQuery(teamId ?? skipToken);

    useEffect(() => {
        if (teamData?.tenantId) {
            searchCustomer({
                pageSize: 100,
                tenantId: teamData?.tenantId?.value,
            });
        }
    }, [teamData?.tenantId]);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;
        setTeamData({
            ...teamData,
            [name]: value,
        })
    };

    useEffect(() => {
        let obj = teamByIdQuery?.data;
        if (obj) {
            setTeamData({
                ...obj,
                tenantId: {
                    label: tenantData?.data?.filter((el: any) => el.id === obj?.tenantId)?.[0].name,
                    value: obj.tenantId
                },
                customerId: {
                    value: obj.customerId, label: customerData?.data?.data.filter((el: any) => {
                        return el.id === obj.customerId
                    })[0]?.customerName
                },
                permittedCustomers: obj.permittedCustomers.map((el: any) => {
                    let customer = customerData?.data?.data.filter((pl: any) => {
                        return pl.id === el
                    })[0];
                    if (customer) {
                        return {label: customer.customerName, value: customer.id}
                    }
                })
            })
        }
    }, [teamByIdQuery?.data, customerData?.data, tenantData?.data]);


    const handleToggle = (property: string) => (e: any) => {

        setTeamData((prevData: any) => ({
            ...prevData,
            [property]: !prevData[property]
        }));
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let obj = {
            ...teamData,
            tenantId: teamData?.tenantId?.value,
            customerId: teamData?.customerId?.value,
            permittedCustomers: (!teamId || !teamData?.permitAllSubordinateCustomers)
                ? teamData?.permittedCustomers?.map((el: any) => el?.value)
                : []
        };

        const createOrUpdateUser = async () => {
            if (!teamId) {
                await createTeamApi(obj).unwrap();
            } else {
                await updateTeamApi({
                    id: teamId,
                    requestBody: obj,
                }).unwrap();
            }
        };

        await handleRTKQuery(createOrUpdateUser, () => {
            setTeamData({});
            navigate("/access-control/teams");
        });

    }

    return (
        <>
            <Form onSubmit={handleSubmit} fields={
                <>
                    <div className={teamId ? "bg-surface-2 p-5 rounded-b-md rounded-sm sm:w-full" : ""}>
                        <div className={teamId ? "grid grid-cols-12" : ""}>
                            <InputField
                                name={"teamName"}
                                label="Name"
                                onChange={handleOnChange}
                                value={teamData?.teamName || ""}
                                type="text"
                                placeholder="Enter Team Name..."
                                required={true}
                            />

                            <SelectField
                                placeholder='Select Tenant...'
                                options={createOptionListForSelectTag(tenantData?.data, "name", "id")}
                                value={teamData?.tenantId || null}
                                onChange={(selectedOption) => {
                                    handleOnChange({name: "tenantId", value: selectedOption})
                                }}
                                required={true}
                                label={"Tenant"}
                            />

                            <SelectField
                                placeholder='Select Customer...'
                                options={createOptionListForSelectTag(customerData?.data?.data, "customerName", "id")}
                                value={teamData?.customerId || null}
                                onChange={(selectedOption) => {
                                    handleOnChange({name: "customerId", value: selectedOption})
                                }}
                                label={"Customer"}
                            />

                            <InputField
                                label={"Allow All Subordinates"}
                                type={"checkbox"}
                                checked={teamData.permitAllSubordinateCustomers || ""}
                                name={"permitAllSubordinateCustomers"}
                                onChange={handleToggle("permitAllSubordinateCustomers")}
                            />

                            <SelectField
                                placeholder='Select Permitted Customers...'
                                options={createOptionListForSelectTag(subordinateData?.data, "customerName", "id")}
                                value={teamData?.permittedCustomers || null}
                                onChange={(selectedOption) => {
                                    handleOnChange({name: "permittedCustomers", value: selectedOption})
                                }}
                                label={"Permitted Customers"}
                                isMulti={true}
                                isDisabled={teamData?.permitAllSubordinateCustomers === true}
                            />

                        </div>


                        <ButtonTray
                            buttons={[
                                {
                                    buttonProps: {
                                        btnText: "Cancel",
                                        onClick: () => navigate(AppRoutes.Teams.path),
                                        type: "close",
                                    },
                                    buttonType: "button"
                                },
                                {
                                    buttonProps: {
                                        btnText: "Submit",
                                        type: "submit",
                                        isLoading: (createTeamApiResponse.isLoading || updateTeamApiResponse.isLoading),
                                        isVisible: !teamId ? true : hasPermission(["TEAM-U"])
                                    },
                                    buttonType: "button",

                                }
                            ]}
                            align={!teamId ? "end" : "start"}
                            gap={2}
                        />

                    </div>

                </>}
            />
        </>
    )
}
export default TeamForm;