import {useGetAllAssignToLeadersQuery} from "../../user/teams/teamsApi";
import {DataTable} from "../../../components/common/datatable/DataTable";
import React, {FC, useState} from "react";
import {ModalProps} from "../../../helpers/constants";
import Button from "../../../components/common/form/Button";
import Modal from "../../../components/common/form/Modal";
import {formatValueAsDate} from "../../../helpers/utils/DateUtils";
import {useUpdateAssignmentInfoMutation} from "../productsApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";

interface props extends ModalProps {
    tenantId: string,
    productId: string
}

const AssignProductToLeaderModal: FC<props> = ({tenantId, productId, show, setShow}) => {

    const columns = [
        {
            label: "Name",
            accessor: "username",
        },
        {
            label: "Email",
            accessor: "email"
        },
        {
            label: "Last Logged On", accessor: "lastLoginDate",
            cell: (props: any) => {
                return formatValueAsDate(props.row.original.lastLoginDate);
            },
        },
    ]

    const [selectedId, setSelectedId] = useState<any>(null);
    const [updateAssignmentInfoApi, updateAssignmentInfoApiResponse] = useUpdateAssignmentInfoMutation();

    const handleRowSelection = (newSelectedRow: any, row: any) => {
        setSelectedId(row?.original?.id);
    };

    const handleSubmit = async () => {
        if (selectedId) {
            await handleRTKQuery(() => {
                updateAssignmentInfoApi({
                    productId: productId,
                    assigneeId: selectedId,
                    assigneeType: "USER",
                    reason: "Escalated to Team Leader"
                });
            }, () => {
                setShow(false);
            });
        }
    }

    return (
        <>
            <Modal title={"Escalate to Leader"} show={show} setShow={setShow} className={"w-3/5 mx-4"}
                   fields={
                       <>
                           <DataTable
                               columns={columns}
                               actions={{
                                   fetchAction: useGetAllAssignToLeadersQuery
                               }}
                               defaultFilter={{
                                   tenantId: tenantId,
                                   sort : "managers_id"
                               }}
                               renderOptions={{
                                   skipUserSettingRender: true
                               }}
                               appendFilterToUrl={false}
                               rowSelectionOptions={{
                                   enableRowSelection: true,
                                   onSelectionChange: handleRowSelection,
                                   enableMultiRowSelection: false,
                                   rowIdAccessor: 'id'
                               }}
                           />

                           <div className="flex justify-end gap-1 border-t border-surface-3 pt-4 mt-4">
                               <Button btnText={"Close"} isLoading={false} type={"close"}
                                       onClick={() => setShow(false)}/>
                               <Button btnText={"Assign"} isLoading={false}
                                       onClick={handleSubmit}
                                       isDisabled={updateAssignmentInfoApiResponse.isLoading || !selectedId}
                                       type={"submit"}></Button>
                           </div>
                       </>
                   }
            />

        </>
    )
}
export default AssignProductToLeaderModal;