import {useParams} from "react-router-dom";
import React, {FormEvent, useState} from "react";
import {DataTable} from "../../../../components/common/datatable/DataTable";
import {useBranchContactsQuery} from "../branchContactApi";
import {hasPermission} from "../../../../helpers/utils/AccessControlUtils";
import ModalButton from "../../../../components/common/form/ModalButton";
import {FaPlus, FaTrash} from "react-icons/fa6";
import ConfirmationModal from "../../../../components/layout/modal/ConfirmationModal";
import AddUpdateBranchContactModal from "../modal/AddUpdateBranchContactModal";
import {useDeleteBranchContactByIdMutation} from "../branchContactApi";
import {DataTableColumn} from "../../../../components/common/datatable/DataTableTypes";

const ContactTab = () => {

    let {branchId} = useParams();
    const [selectedContactId, setSelectedContactId] = useState("");
    const [deleteContactApi, {isLoading: deleteContactApiResponse}] = useDeleteBranchContactByIdMutation();

    const handleDeleteContactSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (selectedContactId) {
            return deleteContactApi(selectedContactId);
        }
    }

    let columns: DataTableColumn[] = [
        {
            label: "Name", accessor: "contact.name",
            cell: (props: any) => {
                if (hasPermission(["BRAN-U"])) {
                    return <>
                        <ModalButton
                            triggerButton={
                                <span
                                    className="text-highlight-6 hover:text-highlight-7 cursor-pointer">{props.row.original.contact?.name}
                                </span>
                            }
                            onOpen={() => {
                            }}
                            modal={
                                <AddUpdateBranchContactModal
                                    show={true}
                                    setShow={() => {
                                    }}
                                    title={"Update Branch Contact"}
                                    btnText={"Update"}
                                    branchContactId={props.row.original.id}
                                    isEditable={hasPermission(["BRAN-U"])}
                                />
                            }
                        />
                    </>
                } else {
                    return <>
                        <span>{props.row.original.contact?.name}</span>
                    </>
                }
            }
        },
        {
            label: "Phone", accessor: "contact.phone"
        },
        {
            label: "Email", accessor: "contact.email"
        },
        {
            label: "Website", accessor: "contact.website"
        },
        {
            label: "Address", accessor: "contact.address.mailingAddress"
        },
        {
            label: "Action",
            visibility: {
                canColumnShow: hasPermission(["BRAN-U"]),
                showByDefault: hasPermission(["BRAN-U"]),
                hasShowSetting: false,
            },
            accessor: "creationDate",
            canSort: false,
            cell: (props: any) => {
                return <>
                    <div className="ml-4">
                        <ModalButton
                            triggerButton={
                                <FaTrash
                                    className={"cursor-pointer text-highlight-3 text-lg hover:text-highlight-7"}
                                    title={"Delete Branch Contact"}/>
                            }
                            onOpen={() => {
                                setSelectedContactId(props.row.original.id)
                            }}
                            modal={
                                <ConfirmationModal
                                    show={true}
                                    setShow={() => {
                                    }}
                                    title={"Delete Branch Contact"}
                                    bodyMessage={"Are you sure you want to delete this contact ?"}
                                    onSubmit={handleDeleteContactSubmit}
                                    isLoading={deleteContactApiResponse}
                                />
                            }
                        />
                    </div>
                </>
            }
        }
    ]

    return (
        <>
            {hasPermission(["BRAN-U"]) && <div className={"flex justify-end"}>
                <div
                    className="h-7 w-10 text-text-1 rounded-md bg-highlight-3 flex justify-center items-center cursor-pointer hover:bg-highlight-7 add_icon_outer">
                    <ModalButton
                        triggerButton={<FaPlus className="text-inherit"/>}
                        onOpen={() => {
                        }}
                        modal={
                            <AddUpdateBranchContactModal
                                show={true}
                                setShow={() => {
                                }}
                                title={"Create Branch Contact"}
                                btnText={"Create"}
                            />
                        }
                    />

                </div>
            </div>}
            <div className='-mt-4'>
                <DataTable
                    columns={columns}
                    actions={{
                        fetchAction: useBranchContactsQuery
                    }}
                    defaultFilter={{
                        branchId: branchId
                    }}
                    userSettingGroup={"Branch Contact"}
                    appendFilterToUrl={false}
                />
            </div>
        </>
    )
}
export default ContactTab;