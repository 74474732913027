import Modal from "../../../components/common/form/Modal";
import {ModalProps} from "../../../helpers/constants";
import React, {ChangeEvent, FC, FormEvent, useCallback, useEffect, useState} from "react";
import InputField from "../../../components/common/form/InputField";
import {
    useGetPdfSettingsByCustomerIdQuery
} from "../../user/customer/pdfSettingsApi";
import {skipToken} from "@reduxjs/toolkit/query";
import Form from "../../../components/common/form/Form";
import {useGenerateInvoicePdfMutation} from "../orderFilesApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useGetTenantCustomerByCustomerNameQuery} from "../../user/customer/customerApi";
import {
    useFindAllInvoiceLinesQuery,
    useFindAllInvoiceNotesQuery,
    useGetProductPricingByProductIdQuery
} from "../productsApi";
import {useGetAllInvoiceLinesByTenantQuery} from "../../data/cannedInvoiceLines/CannedInvoiceLinesApi";
import SelectField from "../../../components/common/form/SelectField";
import {createOptionListForSelectTag, getFirstNonNull} from "../../../helpers/utils/Utils";
import {FaCirclePlus, FaTrash} from "react-icons/fa6";
import TextAreaField from "../../../components/common/form/TextAreaField";
import {DragDropContext, Draggable, DraggableProvided, Droppable} from "react-beautiful-dnd";
import ButtonTray from "../../../components/common/form/ButtonTray";

interface Props extends ModalProps {
    tenantId: string,
    productId: string | null
}

const GenerateInvoiceModal: FC<Props> = ({tenantId, productId, show, setShow}) => {
    const [invoiceObj, setInvoiceObj] = useState<any>({});

    const tenantCustomerQuery = useGetTenantCustomerByCustomerNameQuery(tenantId ?? skipToken);
    const pdfSettingQuery = useGetPdfSettingsByCustomerIdQuery(tenantCustomerQuery?.data?.id ?? skipToken);
    const invoiceLinesQuery = useGetAllInvoiceLinesByTenantQuery(tenantId ?? skipToken);

    const productPricingQuery = useGetProductPricingByProductIdQuery(productId ?? skipToken);
    const invoiceLineQuery = useFindAllInvoiceLinesQuery(productId ?? skipToken);
    const invoiceNoteQuery = useFindAllInvoiceNotesQuery(productId ?? skipToken);

    const [generateInvoiceApi, generateInvoiceApiResponse] = useGenerateInvoicePdfMutation();
    const [notes, setNotes] = useState<any[]>([]);
    const [invoiceLines, setInvoiceLines] = useState<any[]>([]);

    const getMaxSequence = useCallback((array: any[]) => {
        if (!array || array.length === 0) return 0; // Handle empty or undefined array
        return Math.max(...array.map(o => o.sequence || 0)); // Handle missing or null `sequence`
    }, []);

    useEffect(() => {
        const pdfData = pdfSettingQuery?.data;
        const pricingData = productPricingQuery?.data;

        if (pdfData || pricingData) {
            setInvoiceObj((prevObj: any) => ({
                ...prevObj,
                remitName: pdfData?.remitName ?? prevObj.remitName ?? null,
                remitAddress: pdfData?.remitAddress ?? prevObj.remitAddress ?? null,
            }));
        }
    }, [pdfSettingQuery?.data, productPricingQuery?.data]);

    useEffect(() => {

        let invoiceLineData = invoiceLineQuery?.data;
        const pricingData = productPricingQuery?.data;

        if (invoiceLineData && invoiceLineData.length > 0) {
            setInvoiceLines(invoiceLineData);
        }

        if (invoiceLineData && invoiceLineData.length === 0) {

            let tenantPlatformFee = getFirstNonNull([pricingData?.overridePlatformFee,
                pricingData?.calculatedPlatformFee,
                pricingData?.basePlatformFee]) + getFirstNonNull([pricingData?.overridePrice,
                pricingData?.calculatedPrice,
                pricingData?.basePrice]);

            let vendorFee = getFirstNonNull([pricingData?.overrideVendorFee,
                pricingData?.calculatedVendorFee,
                pricingData?.baseVendorFee]);


            invoiceLines.push({
                sequence: getMaxSequence(invoiceLines) + 1,
                label: 'Processing/Verification Fee',
                amount: tenantPlatformFee ? tenantPlatformFee : 0,
                systemGenerated: true
            });

            invoiceLines.push({
                sequence: getMaxSequence(invoiceLines) + 1,
                label: 'Recording Fee',
                amount: 0,
                systemGenerated: true
            });

            invoiceLines.push({
                sequence: getMaxSequence(invoiceLines) + 1,
                label: 'Government Recording Fee',
                amount: vendorFee ? vendorFee : 0,
                systemGenerated: true
            });

            invoiceLines.push({
                sequence: getMaxSequence(invoiceLines) + 1,
                label: 'Transfer Tax Fee',
                amount: 0,
                systemGenerated: true
            });
        }

    }, [invoiceLineQuery?.data, productPricingQuery?.data]);

    useEffect(() => {
        if (invoiceNoteQuery?.data) {
            setNotes(invoiceNoteQuery?.data?.map((el: any) => el.message));
        }
    }, [invoiceNoteQuery?.data]);

    const handleOnChange = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement> | { name: string; value: any }
    ) => {
        const {name, value} = "target" in e ? e.target : e;
        setInvoiceObj((prev: any) => ({...prev, [name]: value}));

        if (name === "invoiceLine" && value) {
            const item = invoiceLinesQuery.data.find((line: any) => line.id === value.value);
            setInvoiceObj((prev: any) => ({...prev, amount: item?.amount || null}));
        } else if (name === "invoiceLine") {
            setInvoiceObj((prev: any) => ({...prev, amount: null}));
        }
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await handleRTKQuery(
            async () => {
                let obj = {
                    ...invoiceObj,
                    notes: notes,
                    invoiceLines: invoiceLines
                }
                return await generateInvoiceApi({productId: productId, body: obj}).unwrap();
            },
            () => {
                setShow(false);
                setInvoiceObj("");
            }
        );
    };

    const handleAddInvoiceLine = (e: any) => {
        e.preventDefault();
        if (invoiceObj?.invoiceLine?.label) {
            setInvoiceLines((prev) => ([
                ...prev,
                {
                    label: invoiceObj?.invoiceLine?.label !== "Custom" ? invoiceObj?.invoiceLine?.label : invoiceObj?.customLabel,
                    amount: invoiceObj?.amount,
                    sequence: getMaxSequence(invoiceLines) + 1,
                    product: productId
                }
            ]));

            setInvoiceObj((prev: any) => ({...prev, invoiceLine: null, amount: null, customLabel: null}));
        }

    };

    const handleInputChange = (index: number, field: 'label' | 'amount', value: string | number) => {
        setInvoiceLines((prevLines) => {
            return prevLines.map((line, i) => {
                if (i === index) {
                    return {
                        ...line,
                        [field]: field === 'amount' ? parseFloat(value as string) || '' : value,
                    };
                }
                return line;
            });
        });
    };

    const handleNoteInputChange = (i: number, value: any) => {
        setNotes((prevNotes) =>
            prevNotes.map((note: string | number, index: number) =>
                index === i ? value : note
            )
        );
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) return;

        const items = Array.from(invoiceLines);
        const [reorderedItem] = items.splice(result.source.index, 1);

        items.splice(result.destination.index, 0, reorderedItem);

        const updatedItems = items.map((item, index) => ({
            ...item,
            sequence: index + 1
        }));

        setInvoiceLines(updatedItems);
    };

    return (
        <>
            <Modal
                title="Generate Invoice"
                show={show}
                setShow={setShow}
                className="w-3/5 mx-4"
                fields={
                    <Form onSubmit={handleSubmit} fields={
                        <>
                            <InputField
                                name="remitName"
                                label="Remit Name"
                                onChange={handleOnChange}
                                value={invoiceObj.remitName || ""}
                                type="text"
                                placeholder="Enter Remit Name..."
                                required
                            />
                            <InputField
                                name="remitAddress"
                                label="Remit Address"
                                onChange={handleOnChange}
                                value={invoiceObj.remitAddress || ""}
                                type="text"
                                placeholder="Enter Remit Address..."
                                required
                            />
                            <div className={"relative note-section"}>
                                <TextAreaField
                                    name="note"
                                    label={'Notes (' + notes.length + ')'}
                                    onChange={handleOnChange}
                                    value={invoiceObj.note || ""}
                                    placeholder="Enter Note..."
                                    rows={2}
                                />
                                <FaCirclePlus
                                    className={"absolute cursor-pointer hover:text-highlight-7 mr-1 right-5 text-highlight-3 text-lg top-10"}
                                    title={"Add Note"}
                                    onClick={() => {
                                        if (invoiceObj && invoiceObj?.note) {
                                            // @ts-ignore
                                            notes.push(invoiceObj?.note);
                                            setNotes(notes);
                                            setInvoiceObj({
                                                ...invoiceObj,
                                                note: ""
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className={"overflow-y-auto max-h-64"}>
                                {notes.map((line, lineIndex) => (
                                    <div className="relative note-section" key={lineIndex}>
                                        <TextAreaField
                                            name="note"
                                            label=""
                                            onChange={(e: any) => {
                                                handleNoteInputChange(lineIndex, e.target.value);
                                            }}
                                            value={line}
                                            placeholder="Enter Note..."
                                            rows={2}
                                        />
                                        <FaTrash
                                            className={"absolute cursor-pointer hover:text-highlight-7 mr-1 right-5 text-highlight-3 text-lg top-10"}
                                            title={"Delete Note"}
                                            onClick={() => {
                                                setNotes(notes.filter((_, index) => index !== lineIndex));
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="relative note-section invoice_wrapper">
                                <SelectField
                                    placeholder='Select Invoice Line...'
                                    value={invoiceObj?.invoiceLine || null}
                                    options={createOptionListForSelectTag(invoiceLinesQuery?.data, "label", "id")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "invoiceLine", value: selectedOption});
                                    }}
                                    isLoading={invoiceLinesQuery.isLoading}
                                    label={'Invoice Lines (' + invoiceLines.length + ')'}
                                />


                                {invoiceObj?.invoiceLine?.label !== "Custom" &&
                                    <div className={"flex absolute right-6 top-3 gap-7 items-center"}>
                                        <input
                                            type="number"
                                            name={"amount"}
                                            placeholder="Enter Amount"
                                            className={`placeholder:text-text-2 rounded-md border p-2 text-text-1 w-full bg-surface-2 border-surface-4`}
                                            value={invoiceObj?.amount || ''}
                                            onChange={handleOnChange}
                                        />
                                        <FaCirclePlus
                                            className={"cursor-pointer hover:text-highlight-7 right-5 text-highlight-3 text-lg top-10 flex-none"}
                                            title={"Add Invoice Line"}
                                            onClick={handleAddInvoiceLine}
                                        />
                                    </div>}
                            </div>

                            {invoiceObj?.invoiceLine?.label === "Custom" &&
                                <div className="relative note-section invoice_wrapper">
                                    <InputField
                                        placeholder='Enter Label...'
                                        value={invoiceObj?.customLabel || null}
                                        name={"customLabel"}
                                        onChange={handleOnChange}
                                    />

                                    <div className={"flex absolute right-6 top-3 gap-7 items-center"}>
                                        <input
                                            type="number"
                                            name={"amount"}
                                            placeholder="Enter Amount"
                                            className={`placeholder:text-text-2 rounded-md border p-2 text-text-1 w-full bg-surface-2 border-surface-4`}
                                            value={invoiceObj?.amount || ''}
                                            onChange={handleOnChange}
                                        />
                                        <FaCirclePlus
                                            className={"cursor-pointer hover:text-highlight-7 right-5 text-highlight-3 text-lg top-10 flex-none"}
                                            title={"Add Invoice Line"}
                                            onClick={handleAddInvoiceLine}
                                        />
                                    </div>
                                </div>
                            }


                            <div className={"overflow-y-auto max-h-64"}>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                                {invoiceLines.map((line, index) => (
                                                    <Draggable key={line.id} draggableId={line?.sequence?.toString()}
                                                               index={index}>
                                                        {(provided: DraggableProvided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{...provided.draggableProps.style}}
                                                                className="relative note-section invoice_wrapper"
                                                            >

                                                                <InputField
                                                                    placeholder="Enter Label"
                                                                    required
                                                                    value={line.label ? line.label : ""}
                                                                    onChange={(e) => handleInputChange(line.sequence, 'label', e.target.value)}
                                                                    icon={
                                                                        <div
                                                                            {...provided.dragHandleProps} // Dragging will be handled from this icon
                                                                            className="cursor-grab mr-2 mt-2"
                                                                            title="Drag to rearrange"
                                                                        >
                                                                            <i className="fas fa-grip-vertical text-lg text-text-1"></i>
                                                                        </div>
                                                                    }
                                                                />
                                                                <div
                                                                    className="flex absolute right-6 top-3 gap-7 items-center">
                                                                    <input
                                                                        type="number"
                                                                        placeholder="Enter Amount"
                                                                        className={"placeholder:text-text-2 rounded-md border p-2 text-text-1 w-full bg-surface-2 border-surface-4"}
                                                                        value={line.amount === undefined || line.amount === "" ? "" : line.amount}
                                                                        onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
                                                                    />

                                                                    <FaTrash
                                                                        className={`text-lg flex-none right-5 top-10 hover:text-highlight-7 text-highlight-3 cursor-pointer`}
                                                                        title={"Delete Invoice Line " + line.sequence}
                                                                        onClick={() => {
                                                                            setInvoiceLines(invoiceLines.filter((item, i) => i !== index));
                                                                        }}
                                                                    />

                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>

                            <ButtonTray
                                buttons={[
                                    {
                                        buttonProps: {
                                            btnText: "Cancel",
                                            onClick: () => setShow(false),
                                            type: "close",
                                        },
                                        buttonType: "button"
                                    },
                                    {
                                        buttonProps: {
                                            btnText: "Generate",
                                            type: "submit",
                                            isLoading: (generateInvoiceApiResponse.isLoading),
                                        },
                                        buttonType: "button",

                                    }
                                ]}
                                align={"end"}
                                gap={2}
                            />

                        </>
                    }/>
                }
            />
        </>
    );
}

export default GenerateInvoiceModal;
