import {strApi} from "../../../app/strApi";
import {serialize} from "../../../helpers/utils/Utils";
import {
    getAuthorizationHeader,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../../helpers/utils/RtkQueryUtils";

export const branchApi = strApi.injectEndpoints({
    endpoints: (build) => ({

        getBranchesForCustomer: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/branches/search?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['branches'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        getBranchesByCustomerIds: build.query({
            query: (customerIds) => {
                // Serialize the data to query parameters
                let paramData = serialize({customerIds: customerIds});
                return {
                    url: `/branches/by-customer-ids?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        getBranchById: build.query({
            query: ({branchId}) => {
                return {
                    url: `/branches/${branchId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags:["branchById"]
        }),

        createBranch: build.mutation({
            query: ({customerId, requestBody}) => {
                return {
                    url: `/branches/customerId/${customerId}`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['branches'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Branch");
            },
        }),

        updateBranch: build.mutation({
            query: ({customerId, branchId, requestBody}) => {
                return {
                    url: `/branches/customerId/${customerId}/branchId/${branchId}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['branches','branchById'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Branch");
            },
        }),
    })
})

export const {
    useGetBranchByIdQuery,
    useLazyGetBranchByIdQuery,
    useGetBranchesForCustomerQuery,
    useLazyGetBranchesForCustomerQuery,
    useCreateBranchMutation,
    useUpdateBranchMutation,
    useGetBranchesByCustomerIdsQuery
} = branchApi