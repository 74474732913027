import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import PlatformPricingForm from "./PlatformPricingForm";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";
import {useGetPlatformPricingByIdQuery} from "./platformPricingApi";

const UpdatePlatformPricing = () => {

    let {platFormId} = useParams();
    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getPlatformPricingById, {platFormId}, {
        redirectToErrorPage: true,
        skip: !platFormId
    });

    const platFormByIdApi = useGetPlatformPricingByIdQuery(platFormId);
    const [crumbs, setCrumbs] = useState<any[]>([]);

    useEffect(() => {
        let obj = platFormByIdApi?.data;
        if (obj) {
            setCrumbs([
                {content: "Home", url: "/"},
                {content: "Platform Pricing", url: "/accounting/platform-pricing"},
                {content: obj?.productType, url: ""}
            ]);

            document.title = `${obj?.productType}`;
        }
    }, [platFormByIdApi]);

    return (
        <>
            <PlatformPricingForm crumbs={crumbs} title={"Update Platform Pricing"} btnText={"Update"}
                                 isEditable={hasPermission(["PLPRC-U"])}/>
        </>
    )
}
export default UpdatePlatformPricing;