import React, {useEffect, useState} from "react";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {useSearchCannedFileNamesQuery} from "./cannedFileNamesApi";
import {useGetAllTenantsQuery} from "../../user/customer/tenantApi";
import {SearchSelectWithList} from "../../../components/common/datatable/filter/SearchSelectWrapper";
import {SearchInputWrapper} from "../../../components/common/datatable/filter/SearchInputWrapper";
import IconButton from "../../../components/common/form/IconButton";
import {FaPlus} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";

const CannedFileNames = () => {

    const tenantList = useGetAllTenantsQuery("");
    const navigate = useNavigate()

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Canned File Names", url: ""},

    ])

    useEffect(() => {
        document.title = "Canned File Names";
    });

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("Canned File Name");

    const columns: DataTableColumn[] = [

        {
            label: "Name",
            accessor: "name",
            cell: (props: any) => {
                let path = `/canned-file-names/${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["CANFN-R", "CANFN-V"]);
            },
            filterOptions: [{
                filterComponent: <SearchInputWrapper type={"text"} placeholder={"Enter Name..."} name={"name"}
                                                     accessor={"name"}/>,
            }],
        },
        {
            label: "Sequence",
            accessor: "sequence",
        },
        {
            label: "Tenant", accessor: "tenantName",
            visibility: {
                disallowedCustomerLevels: ["CUSTOMER", "TENANT"]
            },
            canSort: false,
            filterOptions: [{
                filterComponent: tenantList?.data ? SearchSelectWithList(tenantList?.data, (tenant: any) => `${tenant.name} `, "id", "Select Tenant...", false, "tenantId") : [],
                overrideFilterAccessor: "tenantId"
            }],
        }
    ]

    return (

        <div className="user-container px-5 py-5">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useSearchCannedFileNamesQuery
                }}
                buttonOptions={{
                    addButton: {
                        button: <IconButton
                            icon={<FaPlus/>}
                            hasBackground={true}
                            onClick={() => {
                                navigate("/canned-file-names/create")
                            }}
                            title={"Create Canned File Name"}
                        />,
                        permissions: ["CANFN-C"]
                    },
                }}
                userSettingGroup={"Canned File Name"}
            ></DataTable>

        </div>

    )
}
export default CannedFileNames;