import React, {useEffect} from "react";
import {showToast} from "../../helpers/utils/Utils";
import {Routes as AppRoutes} from "../../routes";
import {useNavigate} from "react-router-dom";
import logo from "../../images/logo.svg";

const SuccessPage = () => {

    const navigate = useNavigate();

    useEffect(() => {
        showToast(`Successfully`, "success");
    }, []);

    return (
        <>
            <header
                className=" sm:block py-1 lg:flex md:block z-50 items-center fixed w-full header bg-surface-1 px-2 border-b-2 border-highlight-2">
                <div className="sm:flex justify-between lg:block md:flex flex items-center"><a
                    className="flex items-center space-x-2" href="/">
                    <img src={logo} alt="Company Logo" className="h-10"/></a>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512"
                         className="text-text-1 lg:hidden sm:block md:block" height="1em" width="1em"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"></path>
                    </svg>
                </div>
            </header>

            <div className="flex items-center justify-center min-h-screen px-4 py-2">
                <div className="add_user_outer flex justify-center mt-3">
                    <div className="adduser_content bg-surface-2 w-full p-8 rounded-md">
                        <div className="text-text-1 mb-4">
                            <h4 className="text-2xl font-semibold">Thank you for attesting to the truth of your
                                statement.</h4>
                            <h4 className={"mt-2"}> Your transaction has been successfully validated and no further
                                reporting is required to FinCen.</h4>
                        </div>

                        {/* Wrap the button in a flex container to center it */}
                        <div className="flex justify-center mt-6">
                            <button
                                className="inline-flex px-6 py-2 items-center rounded hover:bg-highlight-7 hover:text-text-1 bg-highlight-3 text-text-1 focus:outline-none focus:shadow-outline hover:transition-all hover:duration-200 hover:ease"
                                onClick={() => {
                                    navigate(AppRoutes.Reporting.path);
                                }}>
                                Back
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </>
    );
}

export default SuccessPage;
