import {strApi} from "../../../app/strApi";
import {
    getAuthorizationHeader, getFileDownloadHeaders,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../../helpers/utils/RtkQueryUtils";
import {getFileName, serialize} from "../../../helpers/utils/Utils";
import {downloadFile} from "../../../helpers/utils/FileUtils";

export const vendorPricingApi = strApi.injectEndpoints({
    endpoints: (build) => ({

        searchVendorPricing: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/vendor-pricings/search?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['vendorPricing'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        getVendorPricingById: build.query({
            query: (regionId) => {
                return {
                    url: `/vendor-pricings/${regionId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        createVendorPricing: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/vendor-pricings`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['vendorPricing'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Vendor Pricing");
            },
        }),

        updateVendorPricing: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/vendor-pricings/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['vendorPricing', 'vendorPricingById'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Vendor Pricing");
            },
        }),

        deleteVendorPricingById: build.mutation({
            query: (id) => {
                return {
                    url: `/vendor-pricings/${id}`,
                    method: 'DELETE',
                    headers: getAuthorizationHeader()
                };
            },
            invalidatesTags: ['vendorPricing'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Deleted", "Vendor Pricing");
            },
        }),

        exportVendorPricingData: build.query({
            query: (query) => {
                return {
                    url: `/vendor-pricings/export-csv`,
                    method: 'GET',
                    params: query,
                    headers: getFileDownloadHeaders(),
                    responseType: 'arraybuffer',
                    responseHandler: async (response: { blob: () => any; }) => {
                        const blob = await response.blob();
                        downloadFile(blob, getFileName("vendorPricing"))
                        return {success: true};
                    },
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
    })
})

export const {
    useSearchVendorPricingQuery,
    useUpdateVendorPricingMutation,
    useCreateVendorPricingMutation,
    useDeleteVendorPricingByIdMutation,
    useGetVendorPricingByIdQuery,
    useLazyExportVendorPricingDataQuery
} = vendorPricingApi