import CannedInvoiceLineForm from "./CannedInvoiceLineForm";
import {useState} from "react";

const CreateCannedInvoiceLine = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/"},
        {content: "Canned Invoice Lines", url: "/canned-invoice-lines"},
        {content: "Create Canned Invoice Line", url: "/canned-invoice-lines/create"},
    ]);


    return (
        <>
            <CannedInvoiceLineForm title={"Create Canned Invoice Lines"} btnText={"Create"}
                                   crumbs={crumbs}></CannedInvoiceLineForm>
        </>
    )
}
export default CreateCannedInvoiceLine;