import React, {FC, useEffect, useState} from "react";
import {IoIosArrowForward} from "react-icons/io";
import {isNullOrEmpty} from "../../../../helpers/utils/Utils";
import {PersonInfoForm} from "./PersonInfoForm";
import {FaTrash} from "react-icons/fa6";
import ConfirmationModal from "../../../../components/layout/modal/ConfirmationModal";
import ModalButton from "../../../../components/common/form/ModalButton";
import {EnumOption} from "../../../../enums/common";
import {getFullPersonName} from "../../../../helpers/utils/OrderDetailUtils";

interface Props {
    personList: any[];
    enums: { PersonType: EnumOption[] };
    onDeleteAction: (index: number) => void;
    submitAction: (list: any[]) => void;
    requirementsForInstrument: any[];
    item: string;
    setUnsavedPersonInfo: any
}

const PersonInfoList: FC<Props> = ({
                                       personList,
                                       enums,
                                       onDeleteAction,
                                       submitAction,
                                       requirementsForInstrument,
                                       item,
                                       setUnsavedPersonInfo
                                   }) => {

    const [showPackageDetail, setShowPackageDetail] = useState<boolean[]>(new Array(personList.length).fill(false));
    const [tempList, setTempList] = useState<any[]>(personList);
    const [originalList, setOriginalList] = useState<any[]>(personList);

    useEffect(() => {
        if (personList) {
            setTempList(personList);
            setOriginalList(personList);
        }
    }, [personList]);

    const toggleShowPackageDetail = (index: number) => {
        const updatedShowPackageDetail = [...showPackageDetail];
        updatedShowPackageDetail[index] = isNullOrEmpty(updatedShowPackageDetail[index]) ? true : !updatedShowPackageDetail[index];
        setShowPackageDetail(updatedShowPackageDetail);
    }

    const handleUndo = (index: number) => {
        // Create a new array with the updated item
        const updatedList = originalList.map((item, i) => (i === index ? originalList[i] : tempList[i]));

        // Update the state with the new array
        setTempList(updatedList);
        toggleShowPackageDetail(index);
    };


    const handleSave = (index: number, e: any) => {
        // Create a new array with the updated item
        const updatedList = [...tempList]
        updatedList[index] = e

        // Update the state with the new array
        setTempList(updatedList);
        setOriginalList(updatedList);
        submitAction(updatedList)
    }

    return (
        <>
            {
                tempList.map((curPerson: any, index: number) => (
                    <div className={`col-span-12 md:grid md:grid-cols-12 gap-3 ${index === 0 && "mt-3"}`} key={index}>
                        <div className="col-span-3"></div>
                        <div className="bg-surface-2 pt-2 col-span-9 rounded-t-md">
                            <div className="bg-highlight-2 justify-between flex px-2 py-1 cursor-pointer">
                                <div className="flex gap-1 items-center">
                                    <h3 className="text-text-1 font-semibold">{getFullPersonName(curPerson)}</h3>
                                </div>
                                <div className="flex gap-3">
                                    <IoIosArrowForward
                                        className={"text-text-1 text-lg cursor-pointer " + (showPackageDetail[index] ? "rotate-90" : "")}
                                        onClick={() => {
                                            toggleShowPackageDetail(index);
                                        }}
                                    />

                                    <ModalButton
                                        triggerButton={<FaTrash
                                            className={"cursor-pointer text-text-1 text-lg hover:text-text-hover-1"}
                                            title={"Delete Person Info"}/>}
                                        onOpen={() => {
                                        }}
                                        modal={
                                            <ConfirmationModal
                                                show={true}
                                                setShow={() => {
                                                }}
                                                title={"Delete Person Info"}
                                                bodyMessage={"Are you sure you want to delete this person info?"}
                                                onSubmit={() => {
                                                    onDeleteAction(index)
                                                }}
                                                isLoading={false}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                            {
                                showPackageDetail[index] &&
                                <div className="col-span-9">
                                    <PersonInfoForm
                                        enums={enums}
                                        submitBtnText="Save"
                                        handleSubmitAction={(e) => {
                                            handleSave(index, e)
                                        }}
                                        obj={curPerson}
                                        undoAction={() => {
                                            handleUndo(index)
                                        }}
                                        requirementsForInstrument={requirementsForInstrument}
                                        item={item}
                                        setUnsavedPersonInfo={setUnsavedPersonInfo}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                ))}
        </>
    )
}

export default PersonInfoList;
