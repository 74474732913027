import React, {useState} from "react";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import TeamForm from "./TeamForm";

const CreateTeam = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/"},
        {content: "Teams", url: "/access-control/teams"},
        {content: "Create Team", url: "/access-control/teams/create"},
    ]);


    return (
        <>
            <div className="px-4 py-2">
                <div className={"justify-between sm:block md:flex lg:flex"}>
                    <Breadcrumb crumbs={crumbs}/>
                </div>
                <div className="add_user_outer flex justify-center mt-3">
                    <div className="adduser_content bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">Add
                            Team</h3>
                        <TeamForm/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CreateTeam;