import React, {FC, FormEvent, ReactNode, useEffect, useState} from "react";


type FormProps = {
    fields: ReactNode;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
    id?: string;
};

const Form: FC<FormProps> = ({fields, onSubmit, id = "0"}) => {

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    // Will Show Prompt on refresh if there is any data inside form fields
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (unsavedChanges) {
                event.preventDefault();
                event.returnValue = "";
            }
        };
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [unsavedChanges]);

    return (
        <form
            action=""
            id={id}
            onSubmit={(e) => {
                setUnsavedChanges(false);
                onSubmit(e);
            }}
            onChange={() => {
                setUnsavedChanges(true);
            }}
            onSelect={() => {
                setUnsavedChanges(true);
            }}>
            {fields}
        </form>
    );
}
export default Form;