import {strApi} from "../../../app/strApi";
import {getAuthorizationHeader, handleQueryError} from "../../../helpers/utils/RtkQueryUtils";

export const rolesApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        getRolesByCustomerId: build.query({
            query: (customerId) => {
                return {
                    url: `/roles/customerId/${customerId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        findAllRoles: build.query({
            query: () => {
                return {
                    url: `/roles`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        createRole: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/roles`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['findAllRoles'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        getRoleById: build.query({
            query: (id) => {
                return {
                    url: `/roles/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ['roleById'],
            async onQueryStarted(_, {queryFulfilled, dispatch}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        updateRole: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/roles/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['findAllRoles', 'roleById'],
            async onQueryStarted(_, {queryFulfilled}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        getAllPermissions: build.query({
            query: () => {
                return {
                    url: `/roles/all-permissions`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
    })
});

export const {
    useGetRolesByCustomerIdQuery,
    useFindAllRolesQuery,
    useGetRoleByIdQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useGetAllPermissionsQuery
} = rolesApi;