import {combineSlices, configureStore} from "@reduxjs/toolkit";
import globalSlice from "./globalSlice/globalSlice";
import {resetAllReducers, resetReducers} from "./globalSlice/globalActions";
import {sliceNames} from "../helpers/constants";
import {
    resetAllReducers as resetReducer,
    resetReducerByName
} from "../helpers/utils/SliceUtils";
import {strApi} from "./strApi";
import {setupListeners} from "@reduxjs/toolkit/query";


const rootReducers = combineSlices({
    [sliceNames.GLOBAL]: globalSlice,
    [strApi.reducerPath]: strApi.reducer,
})

//reset reducer mechanism...
const rootReducer = (state: any, action: any) => {
    if (action.type === resetReducers.type) {
        const {reducerNames} = action.payload;
        return resetReducerByName({...state}, reducerNames);
    }
    if (action.type === resetAllReducers.type) {
        return resetReducer({...state});
    }

    return rootReducers(state, action);
};

const store = configureStore({
    reducer:  rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(strApi.middleware)
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export default store;