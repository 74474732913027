import Modal from "../../../../components/common/form/Modal";
import React, {FC, useState} from "react";
import {ModalProps} from "../../../../helpers/constants";
import {useSearchUsersQuery} from "../../users/usersApi";
import {DataTable} from "../../../../components/common/datatable/DataTable";
import {useAddNewLeaderIntoTeamMutation, useGetTeamByIdQuery} from "../teamsApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {handleRTKQuery} from "../../../../helpers/utils/RtkQueryUtils";
import {DataTableColumn} from "../../../../components/common/datatable/DataTableTypes";
import ButtonTray from "../../../../components/common/form/ButtonTray";

interface props extends ModalProps {
    teamId: string | undefined,
}

const AddLeader: FC<props> = ({teamId, show, setShow}) => {

    const teamByIdQuery = useGetTeamByIdQuery(teamId ?? skipToken);
    const [userId, setUserId] = useState<any>(null);
    const [addLeaderApi, addLeaderApiResponse] = useAddNewLeaderIntoTeamMutation();

    const handleRowSelection = (newSelectedRow: any, row: any) => {
        setUserId(row?.original?.id);
    };

    const columns: DataTableColumn[] = [
        {
            label: "Name",
            accessor: "username",
        },
        {
            label: "Email",
            accessor: "email"
        },
    ]

    const handleAddLeader = async () => {
        if (userId) {
            await handleRTKQuery(() => {
                addLeaderApi({teamId, userId});
            }, () => {
                setShow(false);
            });
        }
    };

    return (
        <>
            <Modal title={"Add Manager"} show={show} setShow={setShow} className={"w-3/5 mx-4"}
                   fields={
                       <>
                           <DataTable
                               columns={columns}
                               actions={{
                                   fetchAction: useSearchUsersQuery
                               }}
                               defaultFilter={{
                                   teamParam: "Is_MANAGER_SEARCH_PARAM",
                                   tenantId: teamByIdQuery?.data.tenantId,
                               }}
                               renderOptions={{
                                   skipUserSettingRender: true
                               }}
                               rowSelectionOptions={{
                                   enableRowSelection: true,
                                   onSelectionChange: handleRowSelection,
                                   enableMultiRowSelection: false,
                                   rowIdAccessor: 'id'
                               }}
                               appendFilterToUrl={false}
                           ></DataTable>

                           <ButtonTray
                               buttons={[
                                   {
                                       buttonProps: {
                                           btnText: "Cancel",
                                           onClick: () => setShow(false),
                                           type: "close",
                                       },
                                       buttonType: "button"
                                   },
                                   {
                                       buttonProps: {
                                           btnText: "Submit",
                                           type: "button",
                                           onClick: handleAddLeader,
                                           isLoading: (addLeaderApiResponse.isLoading),
                                       },
                                       buttonType: "button",

                                   }
                               ]}
                               align={"end"}
                               gap={2}
                           />

                       </>
                   }
            />

        </>
    )
}
export default AddLeader;