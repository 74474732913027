import {useEffect, useState} from "react";
import FollowUpTypeForm from "./FollowUpTypeForm";
import {useParams} from "react-router-dom";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";
import {useGetFollowUpTypeByIdQuery} from "./followUpTypesApi";

const UpdateFollowUpType = () => {

    let {typeId} = useParams();

    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getFollowUpTypeById, {typeId}, {
        redirectToErrorPage: true,
        skip: !typeId
    });

    const followUpById = useGetFollowUpTypeByIdQuery(typeId);
    const [crumbs, setCrumbs] = useState<any[]>([]);


    useEffect(() => {
        const followUpObj = followUpById?.data;
        if (followUpObj) {
            setCrumbs([
                {content: "Home", url: "/"},
                {content: "Follow Up Types", url: "/follow-up-types"},
                {content: followUpObj?.name, url: ""}
            ]);

            document.title = `${followUpObj?.name}`;
        }
    }, [followUpById?.data]);

    return (
        <>
            <FollowUpTypeForm crumbs={crumbs} title={"Update Follow Up Type"} btnText={"Update"}
                              isEditable={hasPermission(["FOLLUP-U"])}/>
        </>
    )
}
export default UpdateFollowUpType;