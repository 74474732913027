import {strApi} from "../../../app/strApi";
import {getAuthorizationHeader, getFileDownloadHeaders, handleQueryError} from "../../../helpers/utils/RtkQueryUtils";
import {getFileName, serialize} from "../../../helpers/utils/Utils";
import {downloadFile} from "../../../helpers/utils/FileUtils";

export const RevenueEventsApi = strApi.injectEndpoints({
    endpoints: (build) => ({

        searchRevenueEvents: build.query({
            query: (searchParameters) => {
                // Serialize the data to query parameters
                let paramData = serialize(searchParameters);
                return {
                    url: `/revenue-events?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['revenueEvents'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        exportRevenueEventData: build.query({
            query: (query) => {
                return {
                    url: `/revenue-events/export-csv`,
                    method: 'GET',
                    params: query,
                    headers: getFileDownloadHeaders(),
                    responseType: 'arraybuffer',
                    responseHandler: async (response: { blob: () => any; }) => {
                        const blob = await response.blob();
                        downloadFile(blob, getFileName("revenueEvent"))
                        return {success: true};
                    },
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
    })
})

export const {
    useSearchRevenueEventsQuery,
    useLazyExportRevenueEventDataQuery
} = RevenueEventsApi