// Helper function to check if all permissions are checked based on a condition
function checkPermissions(permissions: any[], curPermission: string, index: number): boolean {
    return permissions
        .filter((el: any) => el.name.split(' ')[index] === curPermission)
        .some((permission) => !permission.isChecked);
}

// Function for checking based on the second word (column)
export function allPermissionCheckedOnColumns(permissions: any[], curPermission: string): boolean {
    return checkPermissions(permissions, curPermission, 1);
}

// Function for checking based on the first word (group)
export function allPermissionChecked(permissions: any[], curPermission: string): boolean {
    return checkPermissions(permissions, curPermission, 0);
}


export function filterPermission(permissions:any[], curPermission: string, val : string) {
    return permissions.filter((el) => {
        return el.name === curPermission + val;
    })[0];
}
