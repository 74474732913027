import {strApi} from "../../app/strApi";
import {getAuthorizationHeader, handleQueryError} from "../../helpers/utils/RtkQueryUtils";

export const profileApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        findUserProfileInfo: build.query({
            query: () => ({
                url: `/users/profile`,
                method: "GET",
                headers: getAuthorizationHeader()
            }),
            async onQueryStarted(_, {queryFulfilled}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags: ["userProfileInfo"],
        }),
    })
});

export const {useFindUserProfileInfoQuery} = profileApi