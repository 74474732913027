import RoleForm from "./RoleForm";
import {useState} from "react";

const CreateRole = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/"},
        {content: "Users", url: "/access-control/roles"},
        {content: "Create Role", url: "/access-control/roles/create"},
    ]);

    return (
        <>
            <RoleForm crumbs={crumbs} title={"Create Role"} btnText={"Create"}/>
        </>
    )
}
export default CreateRole;