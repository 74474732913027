import React, {FC} from "react";
import {ModalProps} from "../../../../helpers/constants";
import Modal from "../../../../components/common/form/Modal";
import BranchForm from "../branch/BranchForm";

interface props extends ModalProps {
    customerId: string | undefined,
}

const AddBranchModal: FC<props> = ({customerId, show, setShow}) => {
    return (
        <>
            <Modal title={"Add Branch"} show={show} setShow={setShow} className={"w-3/5 mx-4"}
                   fields={
                       <BranchForm customerId={customerId}
                                   setShow={setShow}
                                   btnText={"Create"}
                                   cancelBtnText={"Close"}/>
                   }
            />

        </>
    )
}
export default AddBranchModal;