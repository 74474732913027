import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import CreatableSelect from 'react-select/creatable';
import {ActionMeta, MultiValue} from 'react-select';
import {chosenInputStyle, OptionType} from "../../../helpers/utils/StyleUtils";

interface props {
    name: string,
    placeholder: string,
    defaultOptions?: OptionType[] | null,
    onChange?: (e: ChangeEvent<HTMLInputElement> | { name: string; value: string | string[], files?: null } | any) => void;
    required?: boolean
    label: string,
    disabled?: boolean,
}

const MultiFreeFormField: FC<props> = ({
                                           name,
                                           placeholder,
                                           onChange,
                                           defaultOptions = null,
                                           required = false,
                                           label,
                                           disabled
                                       }) => {
    const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>([]);
    const [stateValue, setStateValue] = useState("")

    useEffect(() => {
        if (defaultOptions) {
            setSelectedOptions(defaultOptions);
        }
    }, [defaultOptions]);

    const handleChange = (newValue: MultiValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
        setSelectedOptions(newValue);
        const value = newValue.map(item => item.label);
        if (onChange) {
            onChange({name, value});
        }
    };
    const saveValue = () => {
        if (!stateValue) return;
        const updatedOptions = [...selectedOptions, {label: stateValue, value: stateValue}]
        setSelectedOptions(updatedOptions);
        const value = updatedOptions.map(item => item.label);
        if (onChange) {
            onChange({name, value});
        }
        setStateValue('');
    }
    const handleInputChange = (input: string) => {
        setStateValue(input);
    }

    return (

        <div className="col-span-12 md:grid md:grid-cols-12 gap-3 mt-3">
            <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                <h2 className="text-text-1">{required ? '*' + label : label}</h2>
            </div>
            <div className="col-span-9">
                <CreatableSelect
                    isMulti
                    inputValue={stateValue}
                    onMenuClose={saveValue}
                    onInputChange={handleInputChange}
                    value={selectedOptions}
                    onChange={handleChange}
                    styles={chosenInputStyle}
                    placeholder={placeholder}
                    isDisabled={disabled}
                />
            </div>
        </div>
    );
};

export default MultiFreeFormField;
