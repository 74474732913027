import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";
import {useGetSimplifileConfigByIdQuery} from "./simplifileConfigsApi";
import SimplifileConfigForm from "./SimplifileConfigForm";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";

const UpdateSimplifileConfig = () => {

    let {configId} = useParams();

    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getSimplifileConfigById, {configId}, {
        redirectToErrorPage: true,
        skip: !configId
    });

    const simplifileConfigByIdApi = useGetSimplifileConfigByIdQuery(configId);
    const [crumbs, setCrumbs] = useState<any[]>([]);

    useEffect(() => {
        let obj = simplifileConfigByIdApi?.data;
        if (obj) {
            setCrumbs([
                {content: "Home", url: "/home"},
                {content: "Simplifile Configs", url: "/simplifile-configs"},
                {content: obj?.submitterId, url: ""}
            ]);

            document.title = `${obj?.submitterId}`;
        }
    }, [simplifileConfigByIdApi]);

    return (
        <>
            <SimplifileConfigForm crumbs={crumbs} title={"Update Simplifile Token"} btnText={"Update"}
                                  isEditable={hasPermission(["SFCON-U"])}/>
        </>
    )
}

export default UpdateSimplifileConfig;