import React, {ChangeEvent, FC, useState} from "react";
import {ModalProps} from "../../../helpers/constants";
import Modal from "../../../components/common/form/Modal";
import SelectField from "../../../components/common/form/SelectField";
import {
    createOptionListForSelectTag,
    isNullOrEmpty,
    reorderProperties
} from "../../../helpers/utils/Utils";
import InputField from "../../../components/common/form/InputField";
import {useGetAllCannedFileNamesQuery} from "../../data/cannedFileNames/cannedFileNamesApi";
import {useAddDocumentMutation} from "../recordingDocumentApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useGetRecipientInstrumentsQuery, useGetRequirementsForInstrumentQuery} from "../simplifileApi";
import {PersonInfoForm} from "../wizard/recording/PersonInfoForm";
import {useGetEnumsQuery} from "../../../app/globalApi";
import PersonInfoList from "../wizard/recording/PersonInfoList";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetRecipientByCountyIdQuery} from "../../data/counties/countiesApi";
import {useGetProductByIdQuery} from "../productsApi";
import ButtonTray from "../../../components/common/form/ButtonTray";

interface AddDocumentModalProps extends ModalProps {
    recipientId: string
    fileId: string
    packageId: string,
    tenantId: string,
    productId?: string | null,
}


const AddDocumentToPackageModal: FC<AddDocumentModalProps> = ({
                                                                  show,
                                                                  setShow,
                                                                  recipientId,
                                                                  fileId,
                                                                  packageId,
                                                                  tenantId,
                                                                  productId
                                                              }) => {


    const [documentData, setDocumentData] = useState<any>({});
    const allCannedFileNamesApi = useGetAllCannedFileNamesQuery(tenantId ?? skipToken);
    const [grantorObj, setGrantorObj] = useState<any>({type: {"label": "Individual", "value": "INDIVIDUAL"}});
    const [granteeObj, setGranteeObj] = useState<any>({type: {"label": "Individual", "value": "INDIVIDUAL"}});

    const productQueryApi = useGetProductByIdQuery(productId ?? skipToken);

    const {
        data: recipientByCountyId
    } = useGetRecipientByCountyIdQuery(productQueryApi?.data?.countyId ? {
        countyId: productQueryApi?.data?.countyId,
        tenantId: productQueryApi?.data?.tenantId
    } : skipToken);

    const recipientInstrumentsApi = useGetRecipientInstrumentsQuery({
        recipientId: recipientId ?? recipientByCountyId?.recipientID,
        query: {tenantId: tenantId}
    });

    const [addDocument, addDocumentApiResult] = useAddDocumentMutation();
    const {data: enums} = useGetEnumsQuery("")
    const [unSavedPersonInfo, setUnsavedPersonInfo] = useState(false);

    const {data: requirementsForInstrument} = useGetRequirementsForInstrumentQuery(
        {
            recipientId: recipientId ?? recipientByCountyId?.recipientID,
            instrumentName: documentData?.documentType?.value,
            tenantId: tenantId,
        },
        {skip: !documentData?.documentType || !(recipientId ?? recipientByCountyId?.recipientID)}
    );

    const handlePackageChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: any, }) => {
        const {name, value} = "target" in e ? e.target : e;
        setDocumentData({
            ...documentData,
            [name]: value,
        })
    }

    const handleSubmit = async () => {
        await handleRTKQuery(
            async () => {
                return await addDocument({
                    name: documentData.fileName.value === "Custom" ? documentData.customFileName : documentData.fileName.value,
                    recordingDocumentDTO: {
                        packageId: packageId,
                        type: documentData.documentType.value,
                        fileId: fileId,
                        grantees: documentData?.grantees?.map((el: any) => reorderProperties(el)),
                        grantors: documentData?.grantors?.map((el: any) => reorderProperties(el)),
                    }
                }).unwrap()
            },
            () => {
                setDocumentData({})
                setShow(false)
            }
        )

    }

    return (
        <Modal
            title={"Add document to package"}
            show={show}
            setShow={setShow}
            className={"w-3/5 mx-4 "}
            fields={
                <>
                    <SelectField
                        label="Name"
                        value={documentData?.fileName || null}
                        placeholder="Select Name..."
                        options={createOptionListForSelectTag(allCannedFileNamesApi?.data, "name", "name")}
                        onChange={(selectedOption) => {
                            handlePackageChange({name: "fileName", value: selectedOption})
                        }}
                        required={true}
                    />
                    {
                        documentData?.fileName?.value === "Custom" &&
                        <InputField
                            label=""
                            value={documentData?.customFileName || ""}
                            type="text"
                            placeholder="Enter Document Name..."
                            onChange={handlePackageChange}
                            name={"customFileName"}
                        />
                    }
                    <SelectField
                        label="Type"
                        value={documentData?.documentType || null}
                        placeholder="Select Document Type..."
                        options={createOptionListForSelectTag(recipientInstrumentsApi?.data, "instrument", "instrument")}
                        onChange={(selectedOption) => {
                            handlePackageChange({name: "documentType", value: selectedOption})
                        }}
                        isLoading={recipientInstrumentsApi.isLoading}
                        required={true}
                    />

                    <div className="col-span-12 md:grid md:grid-cols-12 gap-3 mt-3">
                        <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                            <h2 className="text-text-1">Grantors</h2>
                        </div>
                        <div className="col-span-9">
                            <PersonInfoForm
                                enums={enums}
                                submitBtnText={"Add"}
                                handleSubmitAction={(e) => {
                                    setDocumentData({
                                        ...documentData,
                                        grantors: [...documentData.grantors ?? [], e]
                                    });

                                }}
                                obj={grantorObj}
                                requirementsForInstrument={[]}
                                item={"grantor"}
                                setUnsavedPersonInfo={setUnsavedPersonInfo}
                                undoAction={(e) => {
                                    setGrantorObj({type: {"label": "Individual", "value": "INDIVIDUAL"}})
                                }}
                            />
                        </div>
                    </div>

                    {
                        documentData?.grantors && documentData?.grantors.length > 0 &&
                        <PersonInfoList personList={documentData?.grantors}
                                        enums={enums}
                                        onDeleteAction={(index: number) => {
                                            setDocumentData((prevState: any) => ({
                                                ...prevState,
                                                ["grantors"]: prevState["grantors"].filter((_: any, i: number) => i !== index)
                                            }));
                                        }}
                                        submitAction={(items: any[]) => {
                                            setDocumentData((prevState: any) => ({
                                                ...prevState,
                                                ["grantors"]: items
                                            }));
                                        }}
                                        requirementsForInstrument={[]}
                                        item={"grantor"}
                                        setUnsavedPersonInfo={setUnsavedPersonInfo}
                        />
                    }

                    <div className="col-span-12 md:grid md:grid-cols-12 gap-3 mt-3">
                        <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                            <h2 className="text-text-1">Grantees</h2>
                        </div>
                        <div className="col-span-9">
                            <PersonInfoForm
                                enums={enums}
                                submitBtnText={"Add"}
                                handleSubmitAction={(e) => {
                                    setDocumentData({
                                        ...documentData,
                                        grantees: [...documentData.grantees ?? [], e]
                                    });
                                }}
                                obj={granteeObj}
                                requirementsForInstrument={requirementsForInstrument}
                                item={"grantee"}
                                setUnsavedPersonInfo={setUnsavedPersonInfo}
                                undoAction={(e) => {
                                    setGranteeObj({type: {"label": "Individual", "value": "INDIVIDUAL"}})
                                }}
                            />
                        </div>
                    </div>

                    {
                        documentData?.grantees && documentData?.grantees.length > 0 &&
                        <PersonInfoList personList={documentData?.grantees}
                                        enums={enums}
                                        onDeleteAction={(index: number) => {
                                            setDocumentData((prevState: any) => ({
                                                ...prevState,
                                                ["grantees"]: prevState["grantees"].filter((_: any, i: number) => i !== index)
                                            }));
                                        }}
                                        submitAction={(items: any[]) => {
                                            setDocumentData((prevState: any) => ({
                                                ...prevState,
                                                ["grantees"]: items
                                            }));
                                        }}
                                        requirementsForInstrument={requirementsForInstrument}
                                        item={"grantee"}
                                        setUnsavedPersonInfo={setUnsavedPersonInfo}
                        />
                    }

                    <ButtonTray
                        buttons={[
                            {
                                buttonProps: {
                                    btnText: "Cancel",
                                    onClick: () => setShow(false),
                                    type: "close",
                                    isDisabled: addDocumentApiResult.isLoading
                                },
                                buttonType: "button"
                            },
                            {
                                buttonProps: {
                                    btnText: "Add",
                                    onClick: handleSubmit,
                                    type: !unSavedPersonInfo ? "submit" : "button",
                                    title: unSavedPersonInfo ? "Unsaved Changes" : "",
                                    isDisabled: isNullOrEmpty(documentData.fileName) || isNullOrEmpty(documentData.documentType) || addDocumentApiResult.isLoading || unSavedPersonInfo
                                },
                                buttonType: "button",

                            }
                        ]}
                        align={"end"}
                        gap={2}
                    />

                </>
            }
        />
    );
};

export default AddDocumentToPackageModal;
