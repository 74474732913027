import React, {useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {queryParamsToObject} from "../DataTableUtils";
import moment from "moment/moment";

interface SelectWrapperProps {
    onChange?: Function;
    accessor: string;
    disabled?: boolean;
    defaultValue?: any;
}

export const SearchDatePickerWrapper: React.FC<SelectWrapperProps> = (props: SelectWrapperProps) => {
    const location = useLocation();
    const navigate: any = useNavigate();

    const {
        onChange,
        accessor,
        disabled = false,
        defaultValue = null
    } = props;

    let [values, setValues] = useState(defaultValue);

    const handleChange = (value: any) => {
        if (location.search && location.search !== "" && queryParamsToObject(location.search) instanceof Object) {
            // @ts-ignore
            delete queryParamsToObject(location.search)[accessor];
            navigate({
                pathname: location.pathname,
                search: location.search
            })
        }
        setValues(value);

        if (!onChange) return;
        onChange(value);
    };

    // useEffect(() => {
    //     if (location.search) {
    //         const queryParamsObject = queryParamsToObject(location.search);
    //
    //         // eslint-disable-next-line react-hooks/rules-of-hooks
    //         const formattedValue = useMemo(() => {
    //             if (queryParamsObject.hasOwnProperty(accessor)) {
    //                 // @ts-ignore
    //                 return moment(new Date(queryParamsObject[accessor]))
    //                     .format("yyyy-MM-DD")
    //                     .toUpperCase();
    //             }
    //             return null;
    //         }, [queryParamsObject, accessor]);
    //
    //         setValues(formattedValue);
    //     }
    // }, [location.search]);

    const queryParamsObject = useMemo(() => {
        if (location.search) {
            return queryParamsToObject(location.search);
        }
        return {};
    }, [location.search]);

    const formattedValue = useMemo(() => {
        if (queryParamsObject.hasOwnProperty(accessor)) {
            // @ts-ignore
            return moment(new Date(queryParamsObject[accessor]))
                .format("yyyy-MM-DD")
                .toUpperCase();
        }
        return null;
    }, [queryParamsObject, accessor]);

    useEffect(() => {
        setValues(formattedValue);
    }, [formattedValue]);


    return (
        <input
            type={"date"}
            disabled={disabled}
            className={`bg-surface-2 border border-surface-5 p-2 rounded-md w-44 ${disabled ? "disable_date_picker" : values ? "text-text-1" : "text-text-2"}`}
            onChange={(e) => handleChange(e.target.value)}
            value={values === null ? "" : values}
            max={new Date().toISOString().split("T")[0]}
        />
    );
}

