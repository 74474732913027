import InputField from "../../../../components/common/form/InputField";
import SelectField from "../../../../components/common/form/SelectField";
import React, {ChangeEvent, FC, useState} from "react";
import {createOptionListForSelectTag} from "../../../../helpers/utils/Utils";
import {useGetEnumsQuery} from "../../../../app/globalApi";

interface props {
    submitBtnText: string,
    obj?: any,
    handleSubmitAction: (partyObj: any) => void;
    undoAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    setUnsavedPersonInfo: any
}

const FincenMemberForm: FC<props> = ({submitBtnText, obj, handleSubmitAction, undoAction, setUnsavedPersonInfo}) => {

    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [partyObj, setPartyObj] = useState<any>(obj);
    const {data: enums} = useGetEnumsQuery("");
    const [isInitialChange, setIsInitialChange] = useState(true);


    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: any }) => {
        const {name, value} = "target" in e ? e.target : e;

        const updatePersonObj = () => {
            setPartyObj((prev: any) => ({
                ...prev,
                [name]: value,
            }));
        };

        if (isInitialChange) {
            setIsInitialChange(false);
            setTimeout(updatePersonObj, 20);
        } else {
            updatePersonObj();
        }

        setIsChanged(true);
        setUnsavedPersonInfo(true);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        handleSubmitAction(partyObj);
        if (submitBtnText === "Add") {
            setPartyObj({});
        }
        setIsChanged(false);
        setUnsavedPersonInfo(false);
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className={`col-span-12 md:grid md:grid-cols-12 gap-3 ${submitBtnText === "Add" && "mt-3"}`}>

                    {submitBtnText === "Add" &&
                        <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                            <h2 className="text-text-1">Members</h2>
                        </div>
                    }
                    <div className={submitBtnText === "Add" ? `col-span-9` : `col-span-12`}>
                        <div
                            className={`border border-surface-6 p-3 bg-surface-2 ${submitBtnText === "Add" ? "rounded-md" : "rounded-b-md"}`}>

                            <InputField
                                required={true}
                                label='Legal Name'
                                name={"name"}
                                value={partyObj?.name || ""}
                                onChange={handleOnChange}
                            />

                            <SelectField
                                required={true}
                                label='Contact Type'
                                value={partyObj?.type || ""}
                                placeholder={"Select Contact Type..."}
                                options={createOptionListForSelectTag(enums?.["MemberContactType"], "displayName", "constant")}
                                onChange={(e: any) => {
                                    handleOnChange({name: "type", value: e});
                                }}/>

                            <InputField
                                required={true}
                                label='Email'
                                type={"email"}
                                value={partyObj?.emailAddress || ""}
                                name={"emailAddress"}
                                onChange={handleOnChange}/>

                            <InputField
                                label='Phone'
                                value={partyObj?.phoneNumber || ""}
                                name={"phoneNumber"}
                                onChange={handleOnChange}
                            />

                            {isChanged && (
                                <div className="grid grid-cols-12 gap-2 mt-2">
                                    <div className="col-span-12 flex items-end justify-end">
                                        <button
                                            type="button"
                                            className="bg-surface-3 rounded-md text-text-1 text-xs px-2 py-1 mr-3 hover:bg-highlight-7"
                                            onClick={(e) => {
                                                if (submitBtnText === "Add") {
                                                    setPartyObj({});
                                                } else {
                                                    undoAction && undoAction(e);
                                                }
                                                setIsChanged(false);
                                                setUnsavedPersonInfo(false);
                                            }}
                                        >
                                            Undo
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-highlight-2 rounded-md text-text-1 text-xs px-2 py-1"
                                        >
                                            {submitBtnText}
                                        </button>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default FincenMemberForm;