import React, {FC} from "react";
import {formatValueAsDate} from "../../helpers/utils/DateUtils";

interface props {
    data: any
}

const AuditableInfo: FC<props> = ({data}) => {
    return (
        <>
            <div className="flex justify-end">
                <div className="text-text-2 text-sm"
                     title={`Modified by ${data?.modifiedBy} on ${formatValueAsDate(data?.modifiedDate)}`}>
                    Created by<span
                    className="italic"> {data?.createdBy} {formatValueAsDate(data?.creationDate)}</span>
                </div>
            </div>
        </>
    )
}

export default AuditableInfo;