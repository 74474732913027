import TenantPricingForm from "./TenantPricingForm";
import {useState} from "react";

const CreateTenantPricing = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/"},
        {content: "Tenant Pricing", url: "/accounting/tenant-pricing"},
        {content: "Create Tenant Pricing", url: "/accounting/tenant-pricing/create"},
    ]);

    return (
        <>
            <TenantPricingForm crumbs={crumbs}
                                 title={"Create Tenant Pricing"}
                                 btnText={"Create"}></TenantPricingForm>
        </>
    )
}
export default CreateTenantPricing;