import CountyForm from "./CountyForm";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";
import {useGetCountyByIdQuery} from "./countiesApi";

const UpdateCounty = () => {

    let {countyId} = useParams();
    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getCountyById, {countyId}, {
        redirectToErrorPage: true,
        skip: !countyId
    });
    const countyByIdApi = useGetCountyByIdQuery(countyId);
    const [crumbs, setCrumbs] = useState<any[]>([]);

    useEffect(() => {
        let countyObj = countyByIdApi?.data;
        if (countyObj) {
            setCrumbs([
                {content: "Home", url: "/home"},
                {content: "Counties", url: "/counties"},
                {content: countyObj?.name, url: ""}
            ]);

            document.title = `${countyObj?.name}`;
        }
    }, [countyByIdApi]);

    return (
        <>
            <CountyForm crumbs={crumbs} title={"Update County"} btnText={"Update"}
                        isEditable={hasPermission(["COUN-U"])}/>


        </>
    )
}
export default UpdateCounty;