import PartnerPricingForm from "./PartnerPricingForm";
import {useState} from "react";

const CreatePartnerPricing = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/"},
        {content: "Partner Pricing", url: "/accounting/partner-pricing"},
        {content: "Create Partner Pricing", url: "/accounting/partner-pricing/create"},
    ]);

    return (
        <>
            <PartnerPricingForm crumbs={crumbs}
                                title={"Create Partner Pricing"}
                                btnText={"Create"}/>
        </>
    )
}
export default CreatePartnerPricing;