import Breadcrumb, {Crumb} from "../../../components/common/Breadcrumbs";
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import Form from "../../../components/common/form/Form";
import InputField from "../../../components/common/form/InputField";
import {OptionType} from "../../../helpers/utils/StyleUtils";
import SelectField from "../../../components/common/form/SelectField";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import {useGetAllTenantsQuery} from "../../user/customer/tenantApi";
import {useNavigate, useParams} from "react-router-dom";
import {Routes as AppRoutes} from "../../../routes";

import {
    useCreateCannedFileNameMutation,
    useUpdateCannedFileNameMutation,
    useGetCannedFileNameByIdQuery
} from "./cannedFileNamesApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {skipToken} from "@reduxjs/toolkit/query";
import {jwtDecode} from "../../../helpers/utils/AccessControlUtils";
import AuditableInfoButton from "../../../components/common/form/AuditableInfoButton";
import ButtonTray from "../../../components/common/form/ButtonTray";

interface props {
    crumbs: Crumb[];
    title: string,
    btnText: string,
    isEditable?: boolean
}

const CannedFileNameForm: FC<props> = ({crumbs, title, btnText, isEditable = true}) => {

    const {cannedFileNameId} = useParams();
    const navigate = useNavigate();
    const [fileNameData, setFileNameData] = useState<any>({});
    const tenantApiResponse = useGetAllTenantsQuery("");
    const [createCannedFileName, createCannedFileNameApiResponse] = useCreateCannedFileNameMutation();
    const [updateCannedFileName, updateCannedFileNameApiResponse] = useUpdateCannedFileNameMutation();
    const cannedFileNameByIdApi = useGetCannedFileNameByIdQuery(cannedFileNameId ?? skipToken);

    useEffect(() => {
        let obj = cannedFileNameByIdApi?.data;
        if (obj) {
            setFileNameData({
                ...obj,
                tenantId: obj?.tenantId ? {
                    label: tenantApiResponse?.data.filter((el: any) => el.id === obj?.tenantId)?.[0].name,
                    value: obj?.tenantId
                } : null
            })
        }
    }, [cannedFileNameByIdApi?.data, tenantApiResponse?.data]);

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;
        setFileNameData({
            ...fileNameData,
            [name]: value,
        })
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const obj = {
            ...fileNameData,
            tenantId: fileNameData?.tenantId?.value
        };

        const createOrUpdateCannedFileName = async () => {
            if (btnText === "Create") {
                await createCannedFileName(obj).unwrap();
            } else {
                await updateCannedFileName({
                    id: cannedFileNameId,
                    requestBody: obj,
                }).unwrap();
            }
        };

        await handleRTKQuery(createOrUpdateCannedFileName, () => {
            setFileNameData({});
            navigate("/canned-file-names");
        });
    };

    return (
        <>

            <div className="px-4 py-2">
                <div className={"justify-between sm:block md:flex lg:flex"}>
                    <Breadcrumb crumbs={crumbs}/>
                    {cannedFileNameId && <AuditableInfoButton obj={cannedFileNameByIdApi?.data}/>}
                </div>

                <div className="flex justify-center mt-3">
                    <div className="bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">{title}</h3>
                        <Form onSubmit={handleSubmit} fields=
                            {
                                <>
                                    <div className="grid grid-cols-12">

                                        <InputField
                                            name={"sequence"}
                                            label="Sequence"
                                            onChange={handleOnChange}
                                            value={fileNameData?.sequence || ""}
                                            type="number"
                                            placeholder="Enter Sequence..."
                                            required={true}
                                        />

                                        <InputField
                                            name={"name"}
                                            label="Name"
                                            onChange={handleOnChange}
                                            value={fileNameData?.name || ""}
                                            type="text"
                                            placeholder="Enter Name..."
                                            required={true}
                                        />

                                        {jwtDecode()?.customerLevel === "SYS" && <SelectField
                                            placeholder='Select Tenant...'
                                            value={fileNameData?.tenantId || null}
                                            options={createOptionListForSelectTag(tenantApiResponse?.data, "name", "id")}
                                            onChange={(selectedOption) => {
                                                handleOnChange({name: "tenantId", value: selectedOption})
                                            }}
                                            isLoading={tenantApiResponse.isLoading}
                                            label={"Tenant"}
                                            required={true}
                                        />}

                                    </div>

                                    <ButtonTray
                                        buttons={[
                                            {
                                                buttonProps: {
                                                    btnText: "Cancel",
                                                    onClick: () => navigate(AppRoutes.CannedFileNames.path),
                                                    type: "close",
                                                },
                                                buttonType: "button"
                                            },
                                            {
                                                buttonProps: {
                                                    btnText: "Submit",
                                                    type: "submit",
                                                    isLoading: (createCannedFileNameApiResponse.isLoading || updateCannedFileNameApiResponse.isLoading),
                                                    isVisible: isEditable
                                                },
                                                buttonType: "button",

                                            }
                                        ]}
                                        align="end"
                                        gap={2}
                                    />

                                </>
                            }
                        />
                    </div>
                </div>

            </div>
        </>
    )
}
export default CannedFileNameForm;