import React, {FC, FormEvent, useEffect, useState} from "react";
import ProductDocuments from "./ProductDocuments";
import RecordingProductDetailInfo from "./RecordingProductDetailInfo";
import ProductNotes from "./ProductNotes";
import ProductHistory from "./ProductHistory";
import SkeletonLoader from "../../../components/common/SkeletonLoader";
import {FaPenToSquare} from "react-icons/fa6";
import UpdateProductStatusModal from "../modal/UpdateProductStatusModal";
import IconButton from "../../../components/common/form/IconButton";
import {FaFilePdf, FaTag} from "react-icons/fa";
import GenerateInvoiceModal from "../modal/GenerateInvoiceModal";
import FollowUpTypeModal from "../modal/FollowUpTypeModal";
import {hasPermission, jwtDecode} from "../../../helpers/utils/AccessControlUtils";
import {useGetProductByIdQuery, useUpdateAssignmentInfoMutation} from "../productsApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {formatValueAsDate} from "../../../helpers/utils/DateUtils";
import {useGetFollowUpTypeByIdQuery} from "../../tenantAdmin/followUpTypes/followUpTypesApi";
import ButtonWithMenu from "../../../components/common/form/ButtonWithMenu";
import AssignProductToUserModal from "../modal/AssignProductToUserModal";
import AssignProductToTeamModal from "../modal/AssignProductToTeamModal";
import ConfirmationModal from "../../../components/layout/modal/ConfirmationModal";
import {useCheckIsUserLeaderInTenantQuery, useCheckIsUserMemberInTenantQuery} from "../../user/users/usersApi";
import AssignProductToLeaderModal from "../modal/AssignProductToLeaderModal";
import AssignmentLogs from "./AssignmentLogs";
import ProductPricingDetail from "./ProductPricingDetail";
import ButtonTray from "../../../components/common/form/ButtonTray";
import FincenProductDetailInfo from "./FincenProductDetailInfo";

interface props {
    orderQuery: { data: any, isLoading: boolean, isError: boolean } | any,
}

const ProductTabSection: FC<props> = ({orderQuery}) => {

    const [selectedTab, setSelectedTab] = useState<any>(null);
    const [updateAssignmentInfoApi, updateAssignmentInfoApiResponse] = useUpdateAssignmentInfoMutation();

    useEffect(() => {
        const hash = window.location.hash.replace("#", "");
        if (hash) {
            setSelectedTab(hash);
        }
    }, []);

    // Update the URL hash when the selectedTab changes
    useEffect(() => {
        if (selectedTab) {
            window.location.hash = selectedTab;
        }
    }, [selectedTab]);

    const productData = useGetProductByIdQuery(selectedTab ?? skipToken);
    const followUpByIdApi = useGetFollowUpTypeByIdQuery(productData?.data?.followUp?.followUpTypeId ?? skipToken);
    const isUserLeader = useCheckIsUserLeaderInTenantQuery(productData?.data?.tenantId ?? skipToken);
    const isUserMember = useCheckIsUserMemberInTenantQuery(productData?.data?.tenantId ?? skipToken);

    const handleUnassignSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        return updateAssignmentInfoApi({
            productId: selectedTab,
            assigneeId: null,
            assigneeType: "UNASSIGNED",
            reason: "Manually unassigned"
        });
    }

    const ProductTabContainer = () => {
        return <div className="col-span-5 tabs flex mt-3">
            {orderQuery?.isLoading ?
                <SkeletonLoader className='w-32' count={1}/>
                : orderQuery?.data?.products?.map((product: any, i: number) => {
                    return (
                        <button onClick={(e) => {
                            e.preventDefault();
                            setSelectedTab(product?.id);
                        }}
                                className={`${selectedTab === product?.id ? "bg-highlight-2 text-text-1" : "hover:text-highlight-7 bg-surface-2 text-text-3"}
                                      px-3 py-1 -mb-px rounded-t focus:outline-none active`}
                        >{`${i + 1}. ${product?.type}`}
                        </button>)
                })}
        </div>
    }

    const getFollowUpTitle = () => {
        let obj = productData?.data?.followUp;

        if (obj) {
            return !obj ? 'Follow Up' : followUpByIdApi?.data?.name + ' : ' +
                formatValueAsDate(obj?.followUpAdded)
                + ' ' + obj?.followUpMessage;
        } else {
            return "Start Follow Up";
        }

    }


    const [buttonWithMenu, setButtonWithMenu] = useState<any[]>([]);
    const [childDropdownItems, setChildDropdownItems] = useState<any[]>([]);

    useEffect(() => {
        const result: any[] = [];
        const obj = productData?.data;

        if (obj) {
            if (isUserLeader?.data) {
                result.push(
                    <AssignProductToUserModal
                        show={true}
                        setShow={() => {
                        }}
                        tenantId={orderQuery?.data?.tenantId}
                        productId={selectedTab}
                    />
                );
            } else if (!isUserLeader?.data && obj?.assignmentInfo?.assigneeId === jwtDecode()?.sub) {
                result.push(
                    <AssignProductToLeaderModal
                        show={true}
                        setShow={() => {
                        }}
                        tenantId={orderQuery?.data?.tenantId}
                        productId={selectedTab}
                    />
                );
            }
        }

        setButtonWithMenu(result);
    }, [productData?.data, isUserLeader?.data, orderQuery?.data?.tenantId, selectedTab]);

    useEffect(() => {
        const result: any[] = [];

        if (isUserLeader?.data) {
            result.push({
                label: "Assign to Team",
                modal: (
                    <AssignProductToTeamModal
                        show={true}
                        setShow={() => {
                        }}
                        tenantId={orderQuery?.data?.tenantId}
                        productId={selectedTab}
                    />
                ),
                modalKey: "assignTeamModal",
            });
        }

        const obj = productData?.data;
        if (obj) {
            if (obj?.assignmentInfo?.assigneeId === jwtDecode()?.sub) {
                result.push({
                    label: "Unassign",
                    modal: (
                        <ConfirmationModal
                            show={true}
                            setShow={() => {
                            }}
                            title={"Unassign Product"}
                            bodyMessage={"Are you sure you want to unassign this product?"}
                            onSubmit={handleUnassignSubmit}
                            isLoading={updateAssignmentInfoApiResponse.isLoading}
                        />
                    ),
                    modalKey: "unassignModal",
                });
            }

            if (
                isUserMember?.data &&
                obj?.assignmentInfo?.assigneeId !== jwtDecode()?.sub
            ) {
                result.push({
                    label: "Assign to Me",
                    onClick: async () => {
                        try {
                            await updateAssignmentInfoApi({
                                productId: selectedTab,
                                assigneeId: jwtDecode()?.sub, // Specify the assigneeId as needed
                                assigneeType: "USER",
                                reason: "Self assigned",
                            });
                        } catch (error) {
                            // Handle error (e.g., show an error message)
                            console.error("Failed to update assignment:", error);
                        }
                    },
                });
            }
        }

        setChildDropdownItems(result);
    }, [
        productData?.data,
        isUserLeader?.data,
        isUserMember?.data,
        orderQuery?.data?.tenantId,
        selectedTab,
        updateAssignmentInfoApiResponse.isLoading,
    ]);

    return (
        <>
            <div className={`md:grid sm:block grid-cols-12 gap-3`}>
                <ProductTabContainer/>
                <div className={`col-span-7 flex justify-end gap-2 py-2`}>
                    <ButtonTray
                        buttons={[
                            {
                                buttonProps: {
                                    modal: <GenerateInvoiceModal
                                        show={true}
                                        setShow={() => {
                                        }}
                                        tenantId={orderQuery?.data?.tenantId}
                                        productId={selectedTab}
                                    />,
                                    triggerButton: <IconButton icon={<FaFilePdf/>}
                                                               label='Generate Invoice'
                                                               title='Generate Invoice'
                                                               onClick={() => ''}/>,
                                    isVisible: hasPermission(["FILE-C"])
                                },
                                buttonType: "modal"
                            },
                            {
                                buttonProps: {
                                    modal: <UpdateProductStatusModal
                                        show={true}
                                        setShow={() => {
                                        }}
                                        productId={selectedTab}
                                    />,
                                    triggerButton: <IconButton icon={<FaPenToSquare/>}
                                                               label='Status'
                                                               title='Update Status'
                                                               onClick={() => ''}/>,
                                    isVisible: hasPermission(["ORDR-U"])
                                },
                                buttonType: "modal",
                            },
                            {
                                buttonProps: {
                                    modal: <FollowUpTypeModal
                                        show={true}
                                        setShow={() => {
                                        }}
                                        productId={selectedTab}
                                    />,
                                    onOpen: () => {
                                    },
                                    triggerButton: <IconButton icon={<FaTag
                                        style={{color: productData?.data?.followUp && followUpByIdApi?.data?.hexColor}}/>}
                                                               label='Follow Up'
                                                               title={getFollowUpTitle()}
                                                               onClick={() => ''}/>,
                                    isVisible: hasPermission(["PROFOLLUP-V"])
                                },
                                buttonType: "modal",
                            },
                        ]}
                        align={"end"}
                        gap={3}
                    />

                    {buttonWithMenu.length > 0 &&
                        <ButtonWithMenu
                            btnText={"Assign"}
                            btnTrigger={buttonWithMenu[0]}
                            childDropdownItems={childDropdownItems}
                        />
                    }
                </div>
            </div>

            <div className="md:grid sm:block grid-cols-12 gap-3">
                <div className="col-span-5">
                    {
                        productData?.data?.type === 'RECORDING' &&
                        <RecordingProductDetailInfo orderQuery={orderQuery} productId={selectedTab}/>
                    }

                    {
                        productData?.data?.type === 'FINCEN' &&
                        <FincenProductDetailInfo orderQuery={orderQuery} productId={selectedTab}/>
                    }

                    {hasPermission(['PROPRI-V', 'PROPRI-R'], true) && <ProductPricingDetail productId={selectedTab}/>}
                </div>

                <div className="col-span-7 mb-5">
                    {hasPermission(["ONOT-V"]) && <ProductNotes orderId={orderQuery?.data?.id}/>}
                    {hasPermission(["OLOG-V"]) && <ProductHistory orderId={orderQuery?.data?.id}/>}
                    {hasPermission(["FILE-V"]) && <ProductDocuments productId={selectedTab} orderQuery={orderQuery}/>}
                    {hasPermission(["ASSLOG-V"]) && selectedTab && <AssignmentLogs productId={selectedTab}/>}
                </div>
            </div>

        </>
    )
}
export default ProductTabSection