import React, {FC, useEffect, useState} from "react";
import {useFindUserSettingsByGroupNameQuery, useUpdateUserSettingsMutation} from "../userSetting/userSettingsApi";
import {modifyPageSizeSetting} from "../userSetting/UserSettingUtils";
import {UserSetting} from "../userSetting/UserSettingTypes";
import {FaArrowRight, FaArrowLeft} from "react-icons/fa";

interface PaginationProps {
    displayText: string;
    pageCount: number;
    pageNumber: number;
    setPageNumber: (pageNumber: number) => void;
    groupName: string;
    setExternalPageSize: (pageSize: number) => void;
    pagesAfterEllipsis?: number;
}

const Pagination: FC<PaginationProps> = ({
                                             displayText,
                                             pageCount,
                                             pageNumber,
                                             setPageNumber,
                                             groupName,
                                             setExternalPageSize,
                                             pagesAfterEllipsis = 3,
                                         }) => {
    const userSettingsQuery = useFindUserSettingsByGroupNameQuery(groupName);
    const [updateUserSettings] = useUpdateUserSettingsMutation();
    const [pageSize, setPageSize] = useState(getPageSizeSettingValue(userSettingsQuery?.data));

    useEffect(() => {
        setExternalPageSize(parseInt(pageSize));
    }, [pageSize]);

    function getPageSizeSettingValue(settings: UserSetting[]) {
        return settings.find(s => s.group === groupName && s.key === 'pageSize')?.value || '15';
    }

    function changePage(e: React.MouseEvent<HTMLAnchorElement>) {
        const clickedPageNumber = Number(e.currentTarget.textContent) - 1;
        setPageNumber(clickedPageNumber);
    }

    function goToPreviousPage() {
        if (pageNumber > 0) {
            setPageNumber(pageNumber - 1);
        }
    }

    function goToNextPage() {
        if (pageNumber < pageCount - 1) {
            setPageNumber(pageNumber + 1);
        }
    }

    async function changePerPageSize(e: React.MouseEvent<HTMLAnchorElement>) {
        const clickedPageSize = Number(e.currentTarget.textContent);
        if (userSettingsQuery?.data) {
            await updateUserSettings(modifyPageSizeSetting(groupName, userSettingsQuery?.data, clickedPageSize));
            setPageSize(clickedPageSize.toString());
        }
    }

    function onLastPage() {
        return pageNumber + 1 === pageCount;
    }

    const buttonStyles = [
        'px-3 py-2.5 rounded-full text-text-5 shadow-custom'
    ];

    const activePageStyles = [
        'px-3 py-1.5 bg-highlight-3 text-text-1 hover:bg-highlight-7 rounded-full'
    ];

    const inactivePageStyles = [
        'px-3 py-1.5 hover:text-highlight-7 cursor-pointer text-text-5 rounded-full'
    ];

    return (
        <div className="flex items-center justify-between my-2">
            <div className={"space-x-2"}>
                <span className="text-text-2 pr-2">{pageCount > 0 ? displayText : ''}</span>

                <button
                    onClick={goToPreviousPage}
                    disabled={pageNumber === 0}
                    className={`${buttonStyles.join(' ')} ${pageNumber === 0 ? 'cursor-not-allowed' : 'hover:text-highlight-7'}`}
                >
                    <FaArrowLeft/>
                </button>

                {[...Array.from(Array(pageCount).keys())]
                    .slice(Math.max(0, pageNumber - pagesAfterEllipsis), Math.min(pageCount, pageNumber + pagesAfterEllipsis))
                    .map((num, i) => (
                        <a
                            key={i}
                            onClick={changePage}
                            className={`${num === pageNumber ? activePageStyles.join(' ') : inactivePageStyles.join(' ')}`}
                        >
                            {num + 1}
                        </a>
                    ))}

                <button
                    onClick={goToNextPage}
                    disabled={onLastPage()}
                    className={`${buttonStyles.join(' ')} ${onLastPage() ? 'cursor-not-allowed' : 'hover:text-highlight-7'}`}
                >
                    <FaArrowRight/>
                </button>
            </div>

            {userSettingsQuery?.data && displayText !== '' && (
                <div className="ml-4 flex items-center space-x-2">
                    {[15, 25, 50, 100, 150].map(size => (
                        <a key={size} onClick={changePerPageSize}
                           className={`px-3 py-2 rounded-full ${pageSize === size.toString() ? 'bg-highlight-3 text-text-1 hover:bg-highlight-7' : 'hover:text-highlight-7 cursor-pointer text-text-5'}`}>{size}</a>
                    ))}
                </div>
            )}

        </div>
    );

};

export default Pagination;
