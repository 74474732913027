import {FC, useEffect, useState} from "react"
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {formatValueAsDate} from "../../../helpers/utils/DateUtils";
import {getBooleanDisplayIcon} from "../../../helpers/utils/Utils";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import {SearchInputWrapper} from "../../../components/common/datatable/filter/SearchInputWrapper";
import {useLazyExportUserDataQuery, useSearchUsersQuery} from "./usersApi";
import IconButton from "../../../components/common/form/IconButton";
import {FaPlus} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";

const Users: FC = () => {

    const navigate = useNavigate()

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Users", url: ""},
    ])

    useEffect(() => {
        document.title = "Users";
    });

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("User");
    const [triggerExportUserDataQuery] = useLazyExportUserDataQuery();


    const columns: DataTableColumn[] = [

        {
            label: "Name",
            accessor: "username",
            cell: (props: any) => {
                let path = `/access-control/users/${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["USR-R", "USR-V"]);
            },
            filterOptions: [{
                filterComponent: <SearchInputWrapper
                    accessor={'username'}
                    placeholder={'Enter Name...'}
                    name={'username'}/>
            }]
        },
        {
            label: "Email",
            accessor: "email",
            filterOptions: [{
                filterComponent: <SearchInputWrapper
                    accessor={'email'}
                    placeholder={'Enter Email...'}
                    name={'email'}/>
            }]
        },
        {
            label: "Customer",
            accessor: "customer.customerName",
        },
        {
            label: "Roles",
            accessor: "roles",
            canSort: false,
            cell: (props: any) => {
                return props.row.original.roles?.join(', ');
            }
        },
        {
            label: "Created",
            accessor: "creationDate",
            cell: (props: any) => {
                return formatValueAsDate(props.row.original.creationDate);
            }
        },

        {
            label: "Status", accessor: "enabled",
            cell: (props: any) => getBooleanDisplayIcon(props.row.original.enabled, "Enabled", "Disabled"),
        },
    ]

    return (

        <div className="user-container px-5 py-5">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useSearchUsersQuery,
                    exportAction: triggerExportUserDataQuery
                }}
                userSettingGroup={"User"}
                buttonOptions={{
                    addButton:
                        {
                            button: <IconButton
                                icon={<FaPlus/>}
                                hasBackground={true}
                                onClick={() => {
                                    navigate("/access-control/users/create")
                                }}
                                title={"Create User"}
                            />,
                            permissions: ["USR-C"]
                        },
                }}
                basePermission={"USR"}
            ></DataTable>

        </div>

    )
}
export default Users