import {useNavigate, useParams} from "react-router-dom";
import {skipToken} from "@reduxjs/toolkit/query";
import {
    useCreatePartnerPricingMutation,
    useGetPartnerPricingByIdQuery,
    useUpdatePartnerPricingMutation
} from "./partnerPricingApi";
import Breadcrumb, {Crumb} from "../../../components/common/Breadcrumbs";
import AuditableInfoButton from "../../../components/common/form/AuditableInfoButton";
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import Form from "../../../components/common/form/Form";
import TextAreaField from "../../../components/common/form/TextAreaField";
import {jwtDecode} from "../../../helpers/utils/AccessControlUtils";
import SelectField from "../../../components/common/form/SelectField";
import {createOptionListForSelectTag, isNullOrEmpty} from "../../../helpers/utils/Utils";
import {useGetEnumsQuery} from "../../../app/globalApi";
import InputField from "../../../components/common/form/InputField";
import {useGetAllStatesQuery} from "../../data/states/statesApi";
import {useGetAllCountyByStateAbbrQuery} from "../../data/counties/countiesApi";
import {useSearchMetroAreasQuery} from "../../data/metroAreas/metroAreasApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {Routes as AppRoutes} from "../../../routes";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import {useGetAllTenantsQuery} from "../../user/customer/tenantApi";
import ButtonTray from "../../../components/common/form/ButtonTray";

interface props {
    crumbs: Crumb[],
    title: string,
    btnText: string,
    isEditable?: boolean
}

const PartnerPricingForm: FC<props> = ({crumbs, title, btnText, isEditable = true}) => {

    const navigate = useNavigate();
    let {partnerPricingId} = useParams();

    const [createPartner, createPartnerApiResponse] = useCreatePartnerPricingMutation();
    const [updatePartner, updatePartnerApiResponse] = useUpdatePartnerPricingMutation();
    const partnerByIdApi = useGetPartnerPricingByIdQuery(partnerPricingId ?? skipToken);
    const [pricingData, setPricingData] = useState<any>({});
    const stateApiResponse = useGetAllStatesQuery("");
    const tenantApiResponse = useGetAllTenantsQuery("");
    const {data: enums} = useGetEnumsQuery("");
    const {
        data: countyByStateAbbr,
        isLoading: countyByStateAbbrLoading,
        isFetching: countyByStateAbbrFetching
    } = useGetAllCountyByStateAbbrQuery(
        stateApiResponse?.data?.find((el: any) => el.id === pricingData?.state?.value)?.stateAbbreviation ?? skipToken
    );

    const {
        data: metroAreaByStateAbbr,
        isLoading: metroAreaByStateAbbrLoading,
        isFetching: metroAreaByStateAbbrFetching
    } = useSearchMetroAreasQuery({
        pageSize: 10000,
        stateName: stateApiResponse?.data?.find((el: any) => el.id === pricingData?.state?.value)?.stateName
    }, {
        skip: isNullOrEmpty(pricingData?.state?.value) || !stateApiResponse?.data // Skip if no stateId or statesApiResult data is available
    });

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | {
        name: string;
        value: any
    } | ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = "target" in e ? e.target : e;
        setPricingData({
            ...pricingData,
            [name]: value,
        })
    }

    useEffect(() => {
        let obj = partnerByIdApi?.data;
        if (obj) {
            setPricingData(
                {
                    ...obj,
                    state: obj?.state ? {
                        value: obj?.state?.id,
                        label: obj?.state?.stateName
                    } : null,
                    county: obj?.county ? {
                        value: obj?.county?.id,
                        label: obj?.county?.name
                    } : null,
                    metroArea: obj?.metroArea ? {
                        value: obj?.metroArea?.id,
                        label: obj?.metroArea?.name
                    } : null,
                    tenantId: (obj?.tenantId && jwtDecode()?.customerLevel === "SYS") ? {
                        label: tenantApiResponse?.data?.filter((el: any) => el.id === obj?.tenantId)?.[0].name,
                        value: obj?.tenantId,
                    } : jwtDecode()?.tenantId,
                    productType: obj?.productType ? {
                        value: obj?.productType,
                        label: getEnumDisplayNameByConstant(enums?.["ProductType"], obj?.productType)
                    } : null,
                    partner: obj?.partner ? {
                        value: obj?.partner,
                        label: getEnumDisplayNameByConstant(enums?.["OrderSource"], obj?.partner)
                    } : null,
                }
            )
        }

    }, [partnerByIdApi?.data]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const commonObj = {
            ...pricingData,
            productType: pricingData?.productType?.value,
            partner: pricingData?.partner?.value,
            state: pricingData?.state?.value ? stateApiResponse?.data.filter((el: any) => el.id === pricingData?.state?.value)[0] : null,
            county: pricingData?.county?.value ? countyByStateAbbr?.filter((el: any) => el.id === pricingData?.county?.value)[0] : null,
            metroArea: pricingData?.metroArea?.value ? metroAreaByStateAbbr.filter((el: any) => el.id === pricingData?.metroArea?.value)[0] : null,
            tenantId: jwtDecode()?.customerLevel === "SYS" ? pricingData?.tenantId?.value : jwtDecode()?.tenantId
        };

        const createOrUpdateUser = async () => {
            if (btnText === "Create") {
                await createPartner(commonObj).unwrap();
            } else {
                await updatePartner({
                    id: partnerPricingId,
                    requestBody: commonObj,
                }).unwrap();
            }
        };

        await handleRTKQuery(createOrUpdateUser, () => {
            setPricingData({});
            navigate(AppRoutes.PartnerPricing.path);
        });
    }

    return (
        <>
            <div className="px-4 py-2">

                <div className={"justify-between sm:block md:flex lg:flex"}>
                    <Breadcrumb crumbs={crumbs}/>
                    <AuditableInfoButton obj={partnerByIdApi?.data}/>
                </div>

                <div className="flex justify-center mt-3">
                    <div className="bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">{title}</h3>
                        <Form onSubmit={handleSubmit} fields={<>
                            <div className="grid grid-cols-12">

                                <TextAreaField
                                    name="description"
                                    label="Description"
                                    onChange={handleOnChange}
                                    value={pricingData.description || ""}
                                    placeholder="Enter Description..."
                                    rows={2}
                                />

                                <SelectField
                                    placeholder='Select Product Type...'
                                    value={pricingData?.productType || null}
                                    options={createOptionListForSelectTag(enums?.["ProductType"], "displayName", "constant")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "productType", value: selectedOption})
                                    }}
                                    label={"Product Type"}
                                    required={true}
                                />

                                <SelectField
                                    placeholder='Select Partner...'
                                    value={pricingData?.partner || null}
                                    options={createOptionListForSelectTag(enums?.["OrderSource"], "displayName", "constant")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "partner", value: selectedOption})
                                    }}
                                    label={"Partner"}
                                    required={true}
                                />

                                {jwtDecode()?.customerLevel === "SYS" && <SelectField
                                    placeholder='Select Tenant...'
                                    value={pricingData?.tenantId || null}
                                    options={createOptionListForSelectTag(tenantApiResponse?.data, "name", "id")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "tenantId", value: selectedOption})
                                    }}
                                    isLoading={tenantApiResponse.isLoading}
                                    label={"Tenant"}
                                />}


                                <SelectField
                                    placeholder='Select State...'
                                    value={pricingData?.state || null}
                                    options={createOptionListForSelectTag(stateApiResponse?.data, "stateName", "id")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "state", value: selectedOption})
                                    }}
                                    isLoading={stateApiResponse.isLoading}
                                    label={"State"}
                                />

                                <SelectField
                                    label="County"
                                    value={pricingData?.county}
                                    placeholder="Select County..."
                                    options={createOptionListForSelectTag(countyByStateAbbr, "name", "id")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "county", value: selectedOption})
                                    }}
                                    isLoading={countyByStateAbbrLoading || countyByStateAbbrFetching}
                                    isDisabled={pricingData?.metroArea}
                                />

                                <SelectField
                                    label="Metro Area"
                                    value={pricingData?.metroArea}
                                    placeholder="Select Metro Area..."
                                    options={createOptionListForSelectTag(metroAreaByStateAbbr, "name", "id")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "metroArea", value: selectedOption})
                                    }}
                                    isLoading={metroAreaByStateAbbrLoading || metroAreaByStateAbbrFetching}
                                    isDisabled={pricingData?.county}
                                />

                                <InputField
                                    name={"partnerCost"}
                                    label="Cost"
                                    onChange={handleOnChange}
                                    value={pricingData?.partnerCost || ""}
                                    type="number"
                                    placeholder="Enter Cost..."
                                    required={true}
                                />

                            </div>


                            <ButtonTray
                                buttons={[
                                    {
                                        buttonProps: {
                                            btnText: "Cancel",
                                            onClick: () => navigate(AppRoutes.PartnerPricing.path),
                                            type: "close"
                                        },
                                        buttonType: "button"
                                    },
                                    {
                                        buttonProps: {
                                            btnText: "Submit",
                                            type: "submit",
                                            isLoading: (createPartnerApiResponse.isLoading || updatePartnerApiResponse.isLoading),
                                            isVisible: isEditable
                                        },
                                        buttonType: "button"
                                    }
                                ]}
                                align="end"
                                gap={2}
                            />

                        </>}/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PartnerPricingForm;