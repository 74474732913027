import {useAssignProductToUserMutation, useGetTeamAndUserAssignmentsQuery} from "../../order/productsApi";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import {DataTable} from "../../../components/common/datatable/DataTable";
import React from "react";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import ModalButton from "../../../components/common/form/ModalButton";
import IconButton from "../../../components/common/form/IconButton";
import {FaTag} from "react-icons/fa";
import {formatValueAsDate} from "../../../helpers/utils/DateUtils";
import FollowUpTypeModal from "../../order/modal/FollowUpTypeModal";
import {useGetEnumsQuery} from "../../../app/globalApi";

import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {toast} from "react-toastify";
import success = toast.success;
import {FaClipboardList, FaPlus} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";

const MyWorkTab = () => {

    const navigate = useNavigate();

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("My Work");
    const {data: enums} = useGetEnumsQuery("");
    const [assignNextProductApi, assignNextProductApiResponse] = useAssignProductToUserMutation();

    const handleNextTask = async () => {
        await handleRTKQuery(
            async () => {
                return await assignNextProductApi("").unwrap();
            },
            (response: any) => {
                if (response != null)
                    success("Product successfully assigned!")
                else
                    success("No product found to assign!")
            }
        );
    }

    const columns = [
        {
            label: "Order No.",
            accessor: "orderNumber",
            overrideSortColumn: "order.orderNumber",
            cell: (props: any) => {
                let path = `/orders/${props.row.original.orderId}#${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["ORDR-R", "ORDR-V"]);
            }
        },
        {
            label: "Customer",
            accessor: "customerName",
            disableSortBy: true,
        },
        {
            label: "Branch",
            accessor: "branchName",
            disableSortBy: true,
        },
        {
            label: "County",
            accessor: "countyName",
            disableSortBy: true,
        },
        {
            label: "Type",
            accessor: "type",
            cell: (props: any) => getEnumDisplayNameByConstant(enums?.["ProductType"], props.value),

        },
        {
            label: "Status",
            accessor: "status",
            cell: (props: any) => {
                let followObj = props.row.original.followUp;
                let followUpTypeDTO = props.row.original.followUpTypeDTO;
                return (
                    <>
                        <div className={`flex gap-1 ${followObj ? 'ms-[-1rem]' : ''}`}>

                            {hasPermission(["PROFOLLUP-V"]) && followObj != null &&
                                <ModalButton
                                    triggerButton={
                                        <IconButton icon={
                                            <FaTag className={"text-sm"}
                                                   style={{color: followObj && followUpTypeDTO?.hexColor}}/>}
                                                    title={followUpTypeDTO?.name + " : " + formatValueAsDate(followObj?.followUpAdded) + ' ' + followObj?.followUpMessage}
                                                    onClick={() => ''}/>
                                    }
                                    modal={
                                        hasPermission(["PROFOLLUP-R"]) &&
                                        <FollowUpTypeModal
                                            show={true}
                                            setShow={() => {
                                            }}
                                            productId={props.row.original.id}
                                        />
                                    }
                                />
                            }
                            <span>{getEnumDisplayNameByConstant(enums?.["ProductStatus"], props.value)}</span>
                        </div>
                    </>
                )
            }
        },
        {
            label: "Assigned Date", accessor: "assignmentInfo.assignedDate", cell: (props: any) => {
                return formatValueAsDate(props.row.original.assignmentInfo.assignedDate);
            }
        }
    ]


    return (
        <>
            <div className={"flex justify-end"}>
                <div className="w-50% gap-1 text-text-1 flex justify-end add_icon_outer">
                    <IconButton icon={<FaClipboardList/>}
                                label={"Get next task"}
                                title={"Get the next task based on the rules defined by the teams you are in"}
                                onClick={handleNextTask}
                                isLoading={assignNextProductApiResponse.isLoading}
                    />
                    <IconButton
                        icon={<FaPlus/>}
                        hasBackground={true}
                        onClick={() => {
                            navigate("/orders/create")
                        }}
                        title={"Create Order"}
                    />
                </div>
            </div>

            <div className='-mt-4'>
                <DataTable
                    columns={columns}
                    actions={{
                        fetchAction: useGetTeamAndUserAssignmentsQuery
                    }}
                    defaultFilter={{
                        assigneeType: "USER",
                        gridType: "MY_WORK",
                    }}
                    userSettingGroup='My Work'
                    defaultSortOptions={{
                        accessor: 'assignmentInfo.assignedDate',
                        direction: 'desc'
                    }}
                    appendFilterToUrl={false}
                />
            </div>
        </>
    )
}
export default MyWorkTab;