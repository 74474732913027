import React from "react";
import {Routes as AppRoutes} from "../../routes";
import {useNavigate} from "react-router-dom";

const AttestModal = ({isOpen, onClose,body}) => {

    const navigate = useNavigate();

    if (!isOpen) return null;

    return (
        <div
            className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full overlay index model_overflow">
            <div
                className="border border-surface-4 relative bg-surface-2 rounded-lg shadow-xl w-6/12 text-center p-4 pt-0">

                <div className={"flex justify-between align-middle mt-3 border-b border-text-2 pb-3 mb-3"}>
                    <h2 className="cursor-pointer text-lg font-semibold text-text-2">FinCen Reporting Confirmation</h2>
                    <button
                        className="text-text-1 text-lg font-bold hover:text-red-600 focus:outline-none"
                        onClick={onClose}
                    >
                        &times;
                    </button>
                </div>

                {/* Modal content */}
                <p className="text-text-1 text-lg mb-6">
                    {body}
                </p>
                <button
                    className="inline-flex px-3 py-2 items-center rounded hover:bg-highlight-7 hover:text-text-1 bg-highlight-3 text-text-1 focus:outline-none focus:shadow-outline hover:transition-all hover:duration-200 hover:ease"
                    onClick={() => {
                        navigate(AppRoutes.SuccessPage.path);
                    }}>
                    Attest
                </button>
            </div>
        </div>
    );
}

export default AttestModal;