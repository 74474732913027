import React, {useEffect, useState} from "react";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {useGetAllTenantsQuery} from "../../user/customer/tenantApi";
import {SearchSelectWithList} from "../../../components/common/datatable/filter/SearchSelectWrapper";
import {SearchInputWrapper} from "../../../components/common/datatable/filter/SearchInputWrapper";
import IconButton from "../../../components/common/form/IconButton";
import {FaPlus} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {Routes as AppRoutes} from "../../../routes";
import {useSearchSimplifileConfigsQuery} from "./simplifileConfigsApi";

const SimplifileConfigs = () => {

    const navigate = useNavigate()

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Simplifile Configs", url: ""},
    ]);

    useEffect(() => {
        document.title = "Simplifile Configs";
    });

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("Simplifile Configs");
    const tenantQueryApi = useGetAllTenantsQuery("");

    const columns: DataTableColumn[] = [
        {
            label: "Submitter Id",
            accessor: "submitterId",
            cell: (props: any) => {
                let path = `/simplifile-configs/${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["SFCON-R", "SFCON-V"]);
            },
            filterOptions: [{
                filterComponent: <SearchInputWrapper
                    accessor={'submitterId'}
                    placeholder={'Enter Submitter Id...'}
                    name={'submitterId'}/>
            }]
        },
        {
            label: "Tenant",
            accessor: "tenantName", canSort: false,
            visibility: {
                disallowedCustomerLevels: ["CUSTOMER", "TENANT"]
            },
            filterOptions: [{
                filterComponent: tenantQueryApi?.data ? SearchSelectWithList(tenantQueryApi?.data, (tenant: any) => `${tenant.name}`, "id", "Select Tenant...", false, "tenantId") : [],
                overrideFilterAccessor: "tenantId"
            }]
        },
        {label: "Base Url", accessor: "baseUrl"},
        {label: "Token", accessor: "apiToken"},
    ]

    return (

        <div className="user-container px-5 py-5">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useSearchSimplifileConfigsQuery
                }}
                userSettingGroup={"Simplifile Config"}
                buttonOptions={{
                    addButton:
                        {
                            button: <IconButton
                                icon={<FaPlus/>}
                                hasBackground={true}
                                onClick={() => {
                                    navigate(AppRoutes.CreateSimplifileConfig.path)
                                }}
                                title={"Simplifile Config"}
                            />,
                            permissions: ["SFCON-C"]
                        },
                }}
            />

        </div>

    )
}

export default SimplifileConfigs;

