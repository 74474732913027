import React, {FC, useEffect, useState} from "react";
import {IoIosArrowForward} from "react-icons/io";
import ModalButton from "../../../../components/common/form/ModalButton";
import {FaTrash} from "react-icons/fa6";
import ConfirmationModal from "../../../../components/layout/modal/ConfirmationModal";
import {isNullOrEmpty} from "../../../../helpers/utils/Utils";
import {HelperInfoForm} from "./HelperInfoForm";

interface Props {
    helperList: any[],
    onDeleteAction: (index: number) => void;
    submitAction: (list: any[]) => void;

}

const HelperInfoList: FC<Props> = ({helperList, onDeleteAction, submitAction}) => {

    const [tempList, setTempList] = useState<any[]>(helperList);
    const [showHelperDetail, setShowHelperDetail] = useState<boolean[]>(new Array(helperList.length).fill(null));

    useEffect(() => {
        if (helperList) {
            setTempList(helperList);
        }
    }, [helperList]);


    const toggleShowHelperDetail = (index: number) => {
        const updatedShowHelperDetail = [...showHelperDetail];
        updatedShowHelperDetail[index] = isNullOrEmpty(updatedShowHelperDetail[index]) ? true : !updatedShowHelperDetail[index];
        setShowHelperDetail(updatedShowHelperDetail);
    }

    const handleSave = (index: number, e: any) => {
        // Create a new array with the updated item
        const updatedList = [...tempList]
        updatedList[index] = e

        // Update the state with the new array
        setTempList(updatedList);
        submitAction(updatedList);
    }

    return (
        <>
            {
                tempList.map((curHelper: any, index: number) => (
                    <div className={`col-span-12 md:grid gap-3`} key={index}>
                        <div className="col-span-3"></div>
                        <div className="bg-surface-2 col-span-9 rounded-t-md">
                            <div className="bg-highlight-2 justify-between flex px-2 py-1 cursor-pointer">
                                <div className="flex gap-1 items-center">
                                    <h3 className="text-text-1 font-semibold">{curHelper.label}</h3>
                                </div>
                                <div className="flex gap-3">
                                    <IoIosArrowForward
                                        className={"text-text-1 text-lg cursor-pointer " + ((showHelperDetail[index] === null ? curHelper?.required === "ALWAYS" : showHelperDetail[index]) ? "rotate-90" : "")}
                                        onClick={() => {
                                            toggleShowHelperDetail(index);
                                        }}
                                    />

                                    {curHelper?.required !== "ALWAYS" && <ModalButton
                                        triggerButton={<FaTrash
                                            className={"cursor-pointer text-text-1 text-lg hover:text-text-hover-1"}
                                            title={"Delete Document Helper"}/>}
                                        onOpen={() => {
                                        }}
                                        modal={
                                            <ConfirmationModal
                                                show={true}
                                                setShow={() => {
                                                }}
                                                title={"Delete Document Helper"}
                                                bodyMessage={"Are you sure you want to delete this document helper?"}
                                                onSubmit={() => {
                                                    onDeleteAction(index)
                                                }}
                                                isLoading={false}
                                            />
                                        }
                                    />}
                                </div>
                            </div>
                            {
                                (showHelperDetail[index] === null ? curHelper?.required === "ALWAYS" : showHelperDetail[index]) &&
                                <div className="col-span-9">
                                    <HelperInfoForm
                                        submitBtnText="Save"
                                        handleSubmitAction={(e) => {
                                            handleSave(index, e)
                                        }}
                                        obj={curHelper}
                                    />
                                </div>
                            }
                        </div>

                    </div>
                ))}

        </>
    )
}

export default HelperInfoList;