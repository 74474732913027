import Modal from "../../../components/common/form/Modal";
import {ModalProps} from "../../../helpers/constants";
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import Form from "../../../components/common/form/Form";
import Button from "../../../components/common/form/Button";
import TextAreaField from "../../../components/common/form/TextAreaField";
import {useSearchFollowUpTypesQuery} from "../../tenantAdmin/followUpTypes/followUpTypesApi";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import SelectField from "../../../components/common/form/SelectField";
import {useEndProductFollowUpMutation, useGetProductByIdQuery, useStartProductFollowUpMutation} from "../productsApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {skipToken} from "@reduxjs/toolkit/query";
import InputField from "../../../components/common/form/InputField";
import {formatValueAsDate} from "../../../helpers/utils/DateUtils";
import ConfirmationModal from "../../../components/layout/modal/ConfirmationModal";
import ModalButton from "../../../components/common/form/ModalButton";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";

interface props extends ModalProps {
    productId: string | null
}

const FollowUpTypeModal: FC<props> = ({show, setShow, productId}) => {

    const [followUpData, setFollowUpData] = useState<any>({});


    const productData = useGetProductByIdQuery(productId ?? skipToken);
    const [endFollowUpTypeApi, {isLoading: endFollowUpTypeApiResponse}] = useEndProductFollowUpMutation();
    const followUpTypesApi = useSearchFollowUpTypesQuery(productData?.data ?
        {
            pageSize: 1000,
            tenantId: productData?.data?.tenantId
        } :
        skipToken
    );

    useEffect(() => {
        let productFollowUp = productData?.data?.followUp;
        if (productFollowUp) {
            setFollowUpData({
                followUpMessage: productFollowUp?.followUpMessage,
                followUpAdded: productFollowUp?.followUpAdded,
                followUpBy: productFollowUp?.followUpBy,
                followUpTypeId: productFollowUp?.followUpTypeId ?
                    {
                        value: productFollowUp?.followUpTypeId,
                        label: followUpTypesApi?.data?.data.filter((el: any) => el.id === productFollowUp?.followUpTypeId)[0]?.name
                    } : null
            })
        }
    }, [productData?.data,followUpTypesApi?.data]);

    const [startProductFollowUpApi, startProductFollowUpApiResponse] = useStartProductFollowUpMutation();

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await handleRTKQuery(
            async () => {
                let obj = {
                    followUpMessage: followUpData?.followUpMessage,
                    followUpTypeId: followUpData?.followUpTypeId.value
                }
                return await startProductFollowUpApi({productId: productId, followUpObj: obj}).unwrap();
            },
            () => {
                setShow(false);
                setFollowUpData({});
            }
        );
    }

    const handleEndProductFollowUpSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (productId) {
            return endFollowUpTypeApi(productId);
        }
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | {
        name: string;
        value: any
    } | ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = "target" in e ? e.target : e;
        setFollowUpData({...followUpData, [name]: value});
    };

    return (
        <>
            <Modal
                title="Follow Up Detail"
                show={show}
                setShow={setShow}
                className="w-3/5 mx-4"
                fields={
                    <Form onSubmit={handleSubmit} fields={
                        <>

                            <SelectField
                                label="Type"
                                value={followUpData?.followUpTypeId || null}
                                placeholder="Select Follow Up Type..."
                                options={createOptionListForSelectTag(followUpTypesApi?.data?.data, "name", "id")}
                                onChange={(selectedOption) => {
                                    handleOnChange({name: "followUpTypeId", value: selectedOption})
                                }}
                                isLoading={followUpTypesApi.isLoading}
                                required={true}
                            />

                            <TextAreaField
                                name="followUpMessage"
                                label="Message"
                                onChange={handleOnChange}
                                value={followUpData.followUpMessage || ""}
                                placeholder="Enter Message..."
                                rows={3}
                            />

                            {productData?.data?.followUp &&
                                <>
                                    <InputField
                                        label="Modified By"
                                        name={"followUpBy"}
                                        value={followUpData?.followUpBy || ""}
                                        type="text"
                                        disabled={true}
                                    />

                                    <InputField
                                        label="Modified Date"
                                        name={"followUpBy"}
                                        value={formatValueAsDate(followUpData?.followUpAdded) || ""}
                                        type="text"
                                        disabled={true}
                                    />
                                </>
                            }

                            <div className="flex justify-end gap-1 border-t border-surface-3 pt-4 mt-4">
                                <Button btnText="Close" isLoading={false} type="close" onClick={() => setShow(false)}/>
                                {
                                    (productData?.data?.followUp ? hasPermission(["PROFOLLUP-U"]) : hasPermission(["PROFOLLUP-C"])) &&
                                    <Button btnText={productData?.data?.followUp ? "Update" : "Submit"}
                                            isLoading={startProductFollowUpApiResponse.isLoading}
                                            type="submit"
                                            isDisabled={false}/>
                                }

                                {hasPermission(["PROFOLLUP-D"]) && productData?.data?.followUp && <ModalButton
                                    triggerButton={
                                        <Button btnText="End Follow Up" isLoading={false} type="confirm"
                                                onClick={() => setShow(false)}/>
                                    }
                                    onOpen={() => {
                                    }}
                                    modal={
                                        <ConfirmationModal
                                            show={true}
                                            setShow={() => {
                                            }}
                                            title={"End Follow Up"}
                                            bodyMessage={"Are you sure you want to end this follow up ?"}
                                            onSubmit={handleEndProductFollowUpSubmit}
                                            isLoading={endFollowUpTypeApiResponse}
                                        />
                                    }
                                />}
                            </div>

                        </>
                    }/>
                }
            />

        </>
    )
}

export default FollowUpTypeModal;