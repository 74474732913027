import React, {useState} from "react";
import AttestModal from "./AttestModal";
import {useNavigate} from "react-router-dom";
import {Routes as AppRoutes} from "../../routes";
import logo from "../../images/logo.svg";

const FinCenReportingPage = () => {

    const [isModalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    return (
        <>
            <header
                className=" sm:block py-1 lg:flex md:block z-50 items-center fixed w-full header bg-surface-1 px-2 border-b-2 border-highlight-2">
                <div className="sm:flex justify-between lg:block md:flex flex items-center"><a
                    className="flex items-center space-x-2" href="/">
                    <img src={logo} alt="Company Logo" className="h-10"/></a>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512"
                         className="text-text-1 lg:hidden sm:block md:block" height="1em" width="1em"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"></path>
                    </svg>
                </div>
            </header>

            <div className="flex items-center justify-center min-h-[calc(100vh-48px)] px-4 py-2">
                <div className="add_user_outer flex justify-center mt-3">
                    <div className="adduser_content bg-surface-2 w-full p-8 rounded-md">
                        {/* Added content */}
                        <div className="text-text-1 mb-4">
                            <h2 className="text-2xl font-semibold">Is this transaction any of the following?</h2>
                            <div className="mt-4">
                                <ul className="mt-3 space-y-2">
                                    <li>• Grant, revocation, transfer or movement of an easement</li>
                                    <li>• Transfer resulting from probate or death of the owner</li>
                                    <li>• Transfer incident to divorce</li>
                                    <li>• Transfer to a bankruptcy estate</li>
                                    <li>• Transfer where there is no reporting person</li>
                                </ul>
                            </div>
                        </div>

                        <div className="flex justify-end space-x-4 mt-8">
                            <button
                                className="inline-flex px-3 py-2 items-center rounded hover:bg-highlight-7 hover:text-text-1 bg-surface-4 text-text-2 focus:outline-none focus:shadow-outline hover:transition-all hover:duration-200 hover:ease"
                                onClick={() => {
                                    navigate(AppRoutes.BusinessesTransactionPage.path)
                                }}
                            >No
                            </button>
                            <button
                                className="inline-flex px-3 py-2 items-center rounded hover:bg-highlight-7 hover:text-text-1 bg-highlight-3 text-text-1 focus:outline-none focus:shadow-outline hover:transition-all hover:duration-200 hover:ease"
                                onClick={handleOpenModal}>Yes
                            </button>
                        </div>

                    </div>


                </div>

                <AttestModal isOpen={isModalOpen} onClose={handleCloseModal} body={"This transaction does not require reporting anything to FinCen. Please attest to the truth of your\n" +
                    "                    statement by clicking the button below."}/>

            </div>
        </>
    )
}

export default FinCenReportingPage;
