import {showToast} from "../../helpers/utils/Utils";
import {strApi} from "../../app/strApi";

const loginPageApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        loginUser: build.mutation({
            query: (request) => ({
                url: "/authenticate",
                method: "POST",
                body: request,
            }),
            async onQueryStarted(_, {queryFulfilled}) {
                queryFulfilled.then(res => {
                    // TODO: This should be in the Redux store, along with roles, username, customerLevel, etc
                    localStorage.setItem("authToken", res.data.token)
                }).catch(error => {
                    showToast(error?.error?.data?.message, "error");
                })
            }
        }),
    })
})
export const {useLoginUserMutation} = loginPageApi;