import React, {ChangeEvent, FC} from "react";
import NumberFormat from "react-number-format";

interface FormFieldProps {
    label: string;
    value?: string;
    placeholder?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    required?: boolean;
    name?: string;
}

const NumberFormatField: FC<FormFieldProps> = ({
                                                   label,
                                                   value,
                                                   placeholder = "",
                                                   required = false,
                                                   onChange,
                                                   className,
                                                   name,
                                               }) => {
    return (
        <>
            <div className={`col-span-12 md:grid md:grid-cols-12 gap-3`}>
                <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                    <div className="text-text-1">{required ? `*${label}` : label}</div>
                </div>
                <div className="col-span-9">
                    <NumberFormat
                        name="fax"
                        className="placeholder:text-text-2 rounded-md border border-surface-5 text-text-1 form_control p-2  w-full  mt-3 bg-surface-2"
                        placeholder="Enter Fax..."
                        format="(###) ###-####"
                        mask="_"
                        value={value}
                        onChange={onChange}
                    />
                </div>
            </div>

        </>
    )
}

export default NumberFormatField;