import Modal from "../form/Modal";
import {ModalProps} from "../../../helpers/constants";
import React, {ChangeEvent, FC, FormEvent, useState} from "react";
import Form from "../form/Form";
import InputField from "../form/InputField";
import {validatePassword} from "../../../helpers/utils/Utils";
import {
    useResetPasswordFromProfileMutation,
    useResetPasswordFromWebMutation
} from "../../../features/user/users/usersApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {useParams} from "react-router-dom";
import ButtonTray from "../form/ButtonTray";

interface props extends ModalProps {
    page: string
}

const ResetPassword: FC<props> = ({show, setShow, page}) => {

    let {userId} = useParams();

    const [passwordObj, setPasswordObj] = useState<{
        newPassword: string,
        confirmPassword: string,
        oldPassword: string
    }>({
        newPassword: "",
        confirmPassword: "",
        oldPassword: ""
    });

    const [errors, setErrors] = useState<any>({});
    const [resetPasswordApi, resetPasswordApiResponse] = useResetPasswordFromWebMutation();
    const [resetPasswordProfileApi, resetPasswordProfileApiResponse] = useResetPasswordFromProfileMutation();

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let obj = {
            id: userId,
            ...passwordObj
        }

        if (page === "User"){
            await handleRTKQuery(
                async () => {
                    return await resetPasswordApi(obj).unwrap();
                },
                () => {
                    setPasswordObj({
                        newPassword: "",
                        confirmPassword: "",
                        oldPassword: ""
                    });
                    setShow(false);
                }
            )
        }else {
            await handleRTKQuery(
                async () => {
                    return await resetPasswordProfileApi(obj).unwrap();
                },
                () => {
                    setPasswordObj({
                        newPassword: "",
                        confirmPassword: "",
                        oldPassword: ""
                    });
                    setShow(false);
                }
            )
        }

    };

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: string }) => {
        const {name, value} = "target" in e ? e.target : e;

        setPasswordObj({
            ...passwordObj,
            [name]: value,
        });
    };

    return (
        <>
            <Modal
                title={"Reset Password"}
                show={show}
                setShow={setShow}
                className="w-3/5 mx-4"
                fields={
                    <Form onSubmit={handleSubmit}
                          fields={
                              <>

                                  {page === "Profile" && <InputField
                                      name={"oldPassword"}
                                      label="Old Password"
                                      onChange={(e: any) => {
                                          handleOnChange(e);
                                      }}
                                      value={passwordObj.oldPassword}
                                      type="password"
                                      placeholder="Enter Old Password..."
                                      required={true}
                                  />}

                                  <InputField
                                      name={"newPassword"}
                                      label="New Password"
                                      onChange={(e: any) => {
                                          handleOnChange(e);
                                          setErrors(validatePassword(e.target.value, passwordObj.confirmPassword));
                                      }}
                                      value={passwordObj.newPassword}
                                      type="password"
                                      placeholder="Enter New Password..."
                                      required={true}
                                      error={Object.keys(errors).length > 0 && !errors.isValidSubmission && (
                                          <ul className="text-error-1">
                                              {errors.uppercaseChar && <li>{errors.uppercaseChar}</li>}
                                              {errors.lowercaseChar && <li>{errors.lowercaseChar}</li>}
                                              {errors.number && <li>{errors.number}</li>}
                                              {errors.specialChar && <li>{errors.specialChar}</li>}
                                              {errors.length && <li>{errors.length}</li>}
                                          </ul>
                                      )}
                                  />

                                  <InputField
                                      name={"confirmPassword"}
                                      label="Confirm Password"
                                      onChange={(e: any) => {
                                          handleOnChange(e);
                                          setErrors(validatePassword(passwordObj.newPassword, e.target.value));
                                      }}
                                      value={passwordObj.confirmPassword}
                                      type="password"
                                      placeholder="Enter Confirm Password..."
                                      required={true}
                                      error={errors.match && (
                                          <div className="text-error-1">{errors.match}</div>
                                      )}
                                  />

                                  <ButtonTray
                                      buttons={[
                                          {
                                              buttonProps: {
                                                  btnText: "Cancel",
                                                  onClick: () => setShow(false),
                                                  type: "close",
                                              },
                                              buttonType: "button"
                                          },
                                          {
                                              buttonProps: {
                                                  btnText: "Submit",
                                                  type: "submit",
                                                  isLoading: (resetPasswordApiResponse.isLoading || resetPasswordProfileApiResponse.isLoading),
                                                  isDisabled: !errors?.isValidSubmission
                                              },
                                              buttonType: "button",

                                          }
                                      ]}
                                      align="end"
                                      gap={2}
                                  />

                              </>
                          }
                    />
                }
            />
        </>
    );
};

export default ResetPassword;
