import React, {ChangeEvent, FC, useState} from "react";
import {CreateOrderTabProps} from "../OrderDetailsTab";
import ButtonTray from "../../../../components/common/form/ButtonTray";
import {createOptionListForSelectTag, isNullOrEmpty} from "../../../../helpers/utils/Utils";
import SelectField from "../../../../components/common/form/SelectField";
import InputField from "../../../../components/common/form/InputField";
import {useGetAllStatesQuery} from "../../../data/states/statesApi";
import {useGetAllCountyByStateIdQuery} from "../../../data/counties/countiesApi";
import {skipToken} from "@reduxjs/toolkit/query";
import FincenPartySection from "./FincenPartySection";

interface props extends CreateOrderTabProps {
    buyer: any,
    setBuyer: any,
    seller: any,
    setSeller: any
}

const FincenDetailTab: FC<props> = ({
                                        createOrderData,
                                        setCreateOrderData,
                                        setTab,
                                        buyer,
                                        setBuyer,
                                        seller,
                                        setSeller
                                    }) => {

    const [unSavedPersonInfo, setUnsavedPersonInfo] = useState(false);
    const allStatesApiResult = useGetAllStatesQuery("");
    const {
        data: countyByStateId,
        isLoading: countyByStateIdLoading,
        isFetching: countyByStateIdFetching
    } = useGetAllCountyByStateIdQuery(createOrderData?.state?.value ?? skipToken);

    const isNextButtonEnabled = () => {
        return !(!createOrderData?.state ||
            !createOrderData?.countyId ||
            !createOrderData?.situsAddress ||
            !buyer.name ||
            buyer.contacts.length === 0 ||
            !seller.name ||
            seller.contacts.length === 0 || unSavedPersonInfo);
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: any }) => {
        const {name, value} = "target" in e ? e.target : e;

        setCreateOrderData({
            ...createOrderData,
            [name]: value,
            // If state is de-selected it should empty the countyName field too
            ...(name === "state" ? {"countyId": null} : {})
        })
    }

    return (
        <>
            <div className="col-span-12 mt-4 p-4 rounded-sm border border-surface-6 relative">
                <label className="absolute bg-surface-2 -top-3.5 pr-1 text-text-1">Package Info</label>

                <SelectField
                    label="State"
                    value={createOrderData?.state}
                    placeholder="Select State..."
                    options={createOptionListForSelectTag(allStatesApiResult?.data, "stateName", "id")}
                    onChange={(selectedOption) => {
                        handleOnChange({name: "state", value: selectedOption})
                    }}
                    required={true}
                />

                <SelectField
                    label="County"
                    value={createOrderData?.countyId}
                    placeholder="Select County..."
                    options={createOptionListForSelectTag(countyByStateId, "name", "id")}
                    onChange={(selectedOption) => {
                        handleOnChange({name: "countyId", value: selectedOption})
                    }}
                    required={true}
                    isLoading={isNullOrEmpty(createOrderData?.state) || countyByStateIdLoading || countyByStateIdFetching}
                />

                <InputField required={true} label='Situs Address'
                            value={createOrderData?.situsAddress}
                            name={"situsAddress"}
                            onChange={handleOnChange}
                />

                <InputField label='Legal Description'
                            name={"legalDescription"}
                            value={createOrderData?.legalDescription}
                            onChange={handleOnChange}
                />

                <InputField
                    label="Expected Closing Date"
                    value={createOrderData?.expectedClosingDate}
                    type="date"
                    onChange={handleOnChange}
                    name={"expectedClosingDate"}
                />

                <InputField label='Expected Sale Price'
                            value={createOrderData?.expectedSalesPrice}
                            type='number'
                            name={"expectedSalesPrice"}
                            onChange={handleOnChange}
                />

            </div>

            <FincenPartySection title={"Buyers"}
                                item={buyer}
                                setItem={setBuyer}
                                setUnsavedPersonInfo={setUnsavedPersonInfo}/>

            <FincenPartySection title={"Sellers"}
                                item={seller}
                                setItem={setSeller}
                                setUnsavedPersonInfo={setUnsavedPersonInfo}/>

            <ButtonTray
                buttons={[
                    {
                        buttonProps: {
                            btnText: "Previous",
                            onClick: () => setTab(1),
                            type: "close",
                        },
                        buttonType: "button"
                    },
                    {
                        buttonProps: {
                            btnText: "Next",
                            type: (isNextButtonEnabled()) ? "submit" : "button",
                            onClick: () => {

                                setCreateOrderData((prevData: any) => {
                                    const updatedData = {...prevData};
                                    if (!updatedData.parties) {
                                        updatedData.parties = [buyer, seller]; // Add timestamp if it doesn't exist
                                    } else {
                                        updatedData.parties[0] = buyer;
                                        updatedData.parties[1] = seller;
                                    }
                                    return updatedData;
                                });

                                setTab(3);
                            },
                            isDisabled: (!isNextButtonEnabled()),
                            title: (!isNextButtonEnabled()) ? "Unsaved Changes or Required fields missing" : ""
                        },
                        buttonType: "button",

                    }
                ]}
                align={"end"}
                gap={2}
            />

        </>
    )
}

export default FincenDetailTab;
