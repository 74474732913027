import CountyForm from "./CountyForm";
import {useState} from "react";

const CreateCounty = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/"},
        {content: "Counties", url: "/counties"},
        {content: "Create County", url: "/counties/create"},
    ]);

    return (
        <>
            <CountyForm crumbs={crumbs} title={"Create County"} btnText={"Create"}/>
        </>
    )
}
export default CreateCounty;