import {IoMdArrowDropdown} from "react-icons/io";
import React, {FC, ReactNode, useState} from "react";

interface props {
    btnText: string,
    btnTrigger: ReactNode,
    childDropdownItems: { label: string, modal?: ReactNode, modalKey?: string, onClick?: () => void; }[]
}

const ButtonWithMenu: FC<props> = ({btnText, btnTrigger, childDropdownItems}) => {

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [modalVisibility, setModalVisibility] = useState<{ [key: string]: boolean }>({
        btnTrigger: false, // Initial state for btnTrigger modal visibility
    });
    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const openModal = (modalKey: string) => {
        setModalVisibility({...modalVisibility, [modalKey]: true});
        setMenuOpen(false); // Close the dropdown when modal is triggered
    };

    const closeModal = (modalKey: string) => {
        setModalVisibility({...modalVisibility, [modalKey]: false});
    };

    return (
        <>
            <div className={"relative"}>
                <div
                    className={"cursor-pointer rounded flex items-center text-sm px-1.5 py-1 gap-x-1.5 text-text-1 bg-highlight-3 hover:bg-highlight-7"}
                    onClick={toggleMenu}>
                    <button
                        className={childDropdownItems.length > 0 ? "pr-2 border-r border-surface-3" : ''}
                        onClick={() => openModal("btnTrigger")}>
                        {btnText}
                    </button>
                    <div className={"relative group"}>
                        {childDropdownItems.length > 0 &&
                            <>
                                <IoMdArrowDropdown onClick={toggleMenu}/>
                                <div className={"absolute hidden group-hover:block right-0 pt-2"}>
                                    <div
                                        className={"bg-surface-2 border border-highlight-7 text-text-1 right-2 mt-2 rounded-md w-32 "}>
                                        {childDropdownItems.map((item, index) => (
                                            <button
                                                key={index}
                                                onClick={() => {
                                                    if (item.modalKey) {
                                                        openModal(item.modalKey);  // Open the modal if modalKey exists
                                                    } else if (item.onClick) {
                                                        item.onClick();  // Execute the onClick function if it exists
                                                    }
                                                }}
                                                className="text-xs px-2 py-2 hover:text-highlight-7 cursor-pointer"
                                            >{item.label}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>


                {modalVisibility["btnTrigger"] && (
                    <div>
                        {React.cloneElement(btnTrigger as React.ReactElement, {
                            show: modalVisibility["btnTrigger"],
                            setShow: () => closeModal("btnTrigger"),
                        })}
                    </div>
                )}

                {/* Render each modal conditionally based on visibility state */}
                {childDropdownItems.map((item) =>
                    item.modalKey && modalVisibility[item.modalKey] ? (
                        <div key={item.modalKey}>
                            {React.cloneElement(item.modal as React.ReactElement, {
                                show: modalVisibility[item.modalKey],
                                setShow: () => item.modalKey && closeModal(item.modalKey),
                            })}
                        </div>
                    ) : null
                )}

            </div>
        </>
    )
}
export default ButtonWithMenu;