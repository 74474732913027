import {strApi} from "../../../app/strApi";
import {
    getAuthorizationHeader,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../../helpers/utils/RtkQueryUtils";


export const userSettingsApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        findUserSettingsByGroupName: build.query({
            query: (groupName) => ({
                url: `/user-settings/${groupName}`,
                method: "GET",
                headers: getAuthorizationHeader()
            }),
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
            providesTags: ["userSettings"],
        }),
        updateUserSettings: build.mutation({
            invalidatesTags: ["userSettings"],
            query: (requestBody) => ({
                url: `/user-settings`,
                method: "PUT",
                headers: getAuthorizationHeader(),
                body: requestBody
            }),
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled,"Updated","User Setting")
            }
        }),
    }),
})

export const {
    useFindUserSettingsByGroupNameQuery,
    useUpdateUserSettingsMutation
} = userSettingsApi;