import {FC, useEffect, useState} from "react"
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import {SearchInputWrapper} from "../../../components/common/datatable/filter/SearchInputWrapper";
import {SearchSelectWithList} from "../../../components/common/datatable/filter/SearchSelectWrapper";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {useLazyExportCountyDataQuery, useSearchCountiesQuery} from "./countiesApi";
import {useGetAllStatesQuery} from "../states/statesApi";
import IconButton from "../../../components/common/form/IconButton";
import {FaPlus} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";


const Counties: FC = () => {

    const navigate = useNavigate()

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Counties", url: ""},

    ])

    useEffect(() => {
        document.title = "Counties";
    });

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("County");
    const stateData = useGetAllStatesQuery("");
    const [triggerExportCountyDataQuery] = useLazyExportCountyDataQuery();

    const columns: DataTableColumn[] = [
        {
            label: "Name",
            accessor: "name",
            cell: (props: any) => {
                let path = `/counties/${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["COUN-R", "COUN-V"]);
            },
            filterOptions: [{
                filterComponent: <SearchInputWrapper type={"text"} placeholder={"Enter Name..."} name={"name"}
                                                     accessor={"name"}/>,
                overrideFilterLabel: "Name"
            }]
        },

        {
            label: "State", accessor: "state.stateName",
            filterOptions: [{
                filterComponent: SearchSelectWithList(stateData?.data, "stateName", "stateAbbreviation", "Select State...", false, "stateAbbreviation"),
                overrideFilterLabel: "State",
                overrideFilterAccessor: "stateAbbreviation"
            }]
        },
        {
            label: "FIPS Code", accessor: "fullFipsCode",
            filterOptions: [{
                filterComponent: <SearchInputWrapper type={"text"} placeholder={"Enter FIPS Code..."}
                                                     name={"fullFipsCode"} accessor={"fullFipsCode"}/>,
                overrideFilterLabel: "FIPS Code",
                overrideFilterAccessor: "fullFipsCode"
            }]
        },
    ]

    return (

        <div className="user-container px-4 py-2">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useSearchCountiesQuery,
                    exportAction: triggerExportCountyDataQuery
                }}
                userSettingGroup={"County"}
                defaultSortOptions={{
                    accessor: "name",
                    direction: "asc"
                }}
                buttonOptions={{
                    addButton:
                        {
                            button: <IconButton
                                icon={<FaPlus/>}
                                hasBackground={true}
                                onClick={() => {
                                    navigate("/counties/create")
                                }}
                                title={"Create County"}
                            />,
                            permissions: ["COUN-C"]
                        },
                }}
                basePermission={"COUN"}
            />

        </div>

    )
}
export default Counties