import VendorPricingForm from "./VendorPricingForm";
import {useState} from "react";

const CreateVendorPricing = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/"},
        {content: "Vendor Pricing", url: "/accounting/vendor-pricing"},
        {content: "Create Vendor Pricing", url: "/accounting/vendor-pricing/create"},
    ]);

    return (
        <>
            <VendorPricingForm crumbs={crumbs}
                               title={"Create Vendor Pricing"}
                               btnText={"Create"}></VendorPricingForm>
        </>
    )
}
export default CreateVendorPricing;