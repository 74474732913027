import {useState} from "react";
import MetroAreaForm from "./MetroAreaForm";

const CreateMetroArea = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/"},
        {content: "Metro Areas", url: "/metro-areas"},
        {content: "Create Metro Areas", url: "/metro-areas/create"},
    ]);

    return (
        <>
            <MetroAreaForm crumbs={crumbs} title={"Create Metro Area"} btnText={"Create"}/>
        </>
    )
}
export default CreateMetroArea;