import {useEffect, useState} from "react";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {
    createClickableLink,
    SearchSelectWithIndeterminateBoolean
} from "../../../components/common/datatable/DataTableUtils";
import {SearchInputWrapper} from "../../../components/common/datatable/filter/SearchInputWrapper";
import {SearchSelectWithEnum} from "../../../components/common/datatable/filter/SearchSelectWrapper";
import {getBooleanDisplayIcon} from "../../../helpers/utils/Utils";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {useSearchCannedNotesQuery} from "./cannedNotesApi";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";

const CannedNotes = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Canned Notes", url: ""},

    ]);

    useEffect(() => {
        document.title = "Canned Notes";
    });

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("Canned Note");
    const {data: enums} = useGetEnumsQuery("");

    const columns: DataTableColumn[] = [
        {
            label: "Note No.", accessor: "noteNumber", 
            cell: (props: any) => {
                let path = "/canned-notes/" + props.row.original.id;
                return createClickableLink(path, props.value, userSettings, false, "", ["CANNOTE-R","CANNOTE-V"]);
            },
            filterOptions: [{
                filterComponent: <SearchInputWrapper type={"number"} placeholder={"Enter Note No..."}
                                                     name={"noteNumber"}
                                                     accessor={"noteNumber"}/>,
            }]
        },


        {
            label: "Message", accessor: "comment",
            filterOptions: [{
                filterComponent: <SearchInputWrapper type={"text"} placeholder={"Enter Message..."}
                                                     name={"message"} accessor={"message"}/>,
                overrideFilterAccessor: "message"
            }]
        },
        {
            label: "Type", accessor: "type",
            cell: (props: any) => {
                return <>
                    <span>{getEnumDisplayNameByConstant(enums?.["CertificateNoteType"], props.value)}</span>
                </>
            },
            filterOptions: [{
                filterComponent: SearchSelectWithEnum(enums?.["CertificateNoteType"], "Select Type...", false, "type")
            }],
        },
        {
            label: "Bold", accessor: "bold",
            cell: (props: any) => getBooleanDisplayIcon(props.row.original.bold, "Enabled", "Disabled"),
            filterOptions: [{
                filterComponent: SearchSelectWithIndeterminateBoolean("Select Bold...", "bold"),
            }]
        },
        {
            label: "Red Color", accessor: "redColor",
            cell: (props: any) => getBooleanDisplayIcon(props.row.original.redColor, "Enabled", "Disabled"),
            filterOptions: [{
                filterComponent: SearchSelectWithIndeterminateBoolean("Select Red Color...", "redColor"),
            }]
        },
        {
            label: "Yellow Highlight", accessor: "yellowHighlight",
            cell: (props: any) => getBooleanDisplayIcon(props.row.original.yellowHighlight, "Enabled", "Disabled"),
            filterOptions: [{
                filterComponent: SearchSelectWithIndeterminateBoolean("Select Yellow Highlight...", "yellowHighlight"),
            }]
        }

    ]

    return (
        <>
            <div className="user-container px-5 py-5">
                <div className="flex justify-between">
                    <Breadcrumb crumbs={crumbs}/>
                </div>

                <DataTable
                    columns={columns}
                    actions={{
                        fetchAction: useSearchCannedNotesQuery
                    }}
                    userSettingGroup={"Canned Notes"}
                ></DataTable>

            </div>
        </>
    )
}
export default CannedNotes;