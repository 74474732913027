import {useEffect, useState} from "react";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import {SearchInputWrapper} from "../../../components/common/datatable/filter/SearchInputWrapper";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {useSearchFollowUpTypesQuery} from "./followUpTypesApi";
import {FaPlus} from "react-icons/fa6";
import IconButton from "../../../components/common/form/IconButton";
import {useNavigate} from "react-router-dom";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";

const FollowUpTypes = () => {

    const navigate = useNavigate()

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Follow Up Types", url: ""},
    ]);

    useEffect(() => {
        document.title = "Follow Up Types";
    });

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("Follow Up Type");

    const columns: DataTableColumn[] = [
        {
            label: "Name", accessor: "name",
            cell: (props: any) => {
                let path = "/follow-up-types/" + props.row.original.id;
                return createClickableLink(path, props.row.original.name, userSettings, false, "", ["FOLLUP-R", "FOLLUP-V"]);
            },
            filterOptions: [{
                filterComponent: <SearchInputWrapper type={"text"} placeholder={"Enter Name..."} name={"name"}
                                                     accessor={"name"}/>,
            }],
        },

        {
            label: "Color", accessor: "hexColor", canSort: false,
            cell: (props: any) => {
                return <>
                    <div className="h-8 w-8" style={{background: props.row.original.hexColor}}/>
                </>
            }
        },
        {
            label: "Tenant",
            accessor: "tenantName",
            canSort: false,
            visibility: {
                disallowedCustomerLevels: ["CUSTOMER", "TENANT"]
            },
            cell: (props: any) => {
                return <>
                    <span>{props.value}</span>
                </>
            },
        }
    ]

    return (
        <div className="user-container px-5 py-5">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useSearchFollowUpTypesQuery
                }}
                userSettingGroup={"Follow Up Type"}
                buttonOptions={{
                    addButton:
                        {
                            button: <IconButton
                                icon={<FaPlus/>}
                                hasBackground={true}
                                onClick={() => {
                                    navigate("/follow-up-types/create")
                                }}
                                title={"Create Follow up type"}
                            />,
                            permissions: ["FOLLUP-C"]
                        },

                }}
            />

        </div>
    )
}

export default FollowUpTypes;