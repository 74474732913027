import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import VendorPricingForm from "./VendorPricingForm";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";
import {useGetVendorPricingByIdQuery} from "./vendorPricingApi";

const UpdateVendorPricing = () => {

    let {vendorPricingId} = useParams();
    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getVendorPricingById, {vendorPricingId}, {
        redirectToErrorPage: true,
        skip: !vendorPricingId
    });

    const vendorByIdApi = useGetVendorPricingByIdQuery(vendorPricingId);
    const [crumbs, setCrumbs] = useState<any[]>([]);

    useEffect(() => {
        let obj = vendorByIdApi?.data;
        if (obj) {
            setCrumbs([
                {content: "Home", url: "/"},
                {content: "Vendor Pricing", url: "/accounting/vendor-pricing"},
                {content: obj?.productType, url: ""}
            ]);

            document.title = `${obj?.productType}`;
        }
    }, [vendorByIdApi]);

    return (
        <>
            <VendorPricingForm crumbs={crumbs} title={"Update Vendor Pricing"} btnText={"Update"}
                               isEditable={hasPermission(["VPRC-U"])}/>
        </>
    )
}
export default UpdateVendorPricing;