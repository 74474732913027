import React, {ChangeEvent, FC, FormEvent, useRef, useState} from "react";
import {ModalProps} from "../../../helpers/constants";
import Modal from "../../../components/common/form/Modal";
import Form from "../../../components/common/form/Form";
import {createOptionListForSelectTag, showToast} from "../../../helpers/utils/Utils";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {useUploadFileMutation} from "../orderFilesApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import SelectField from "../../../components/common/form/SelectField";
import ButtonTray from "../../../components/common/form/ButtonTray";

interface props extends ModalProps {
    productId: any,
    orderId: any
}

const UploadDocumentModal: FC<props> = ({productId, orderId, show, setShow}) => {

    const {data: enums} = useGetEnumsQuery("")

    const [selectType, setSelectType] = useState<any>({})
    const [file, setFile] = useState<any>(null)
    const fileInputRef = useRef<any>(null);

    const [uploadDocument, {isLoading: isUploadedLoading}] = useUploadFileMutation();

    const handleChange = (selectedOption: any) => {
        setSelectType(selectedOption)
    };

    const selectedFile = (e: ChangeEvent<HTMLInputElement>) => {
        setFile(e.target.files?.[0]);
    }

    const handleDocumentUploadSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        await handleRTKQuery(
            async () => {
                return await uploadDocument({
                    file: file,
                    orderFileDTO: {
                        orderId: orderId,
                        productId: productId,
                        fileType: selectType?.value,
                        visibility: true,
                        fileSource: "WEB",
                        fileName: file.name,
                        fileSize: file.size
                    }
                }).unwrap()
            },
            () => {
                setShow(false);
                showToast(`Uploaded a file successfully!`, 'success');
            }
        )
    }

    return (
        <>
            <Modal title={"Upload Document"} show={show} setShow={setShow} className={"w-3/5 mx-4"}
                // @ts-ignore
                   fields={<Form onSubmit={handleDocumentUploadSubmit} fields={<>

                       <SelectField
                           placeholder='Select File Type...'
                           options={createOptionListForSelectTag(enums?.OrderFileType, "displayName", "constant")}
                           value={Object.keys(selectType).length === 0 ? null : selectType}
                           onChange={handleChange}
                           required={true}
                           isLoading={isUploadedLoading}
                           label={"File Type"}
                       />

                       <div className={`col-span-12 md:grid md:grid-cols-12 gap-3 mt-3`}>
                           <div className="col-span-3 sm:mt-2 lg-mt-0 mt-2 flex items-center">
                               <h2 className="text-text-1">*File</h2>
                           </div>

                           <div className="col-span-9">
                               <input
                                   className={"w-full bg-surface-2 rounded-md border border-surface-4 text-text-1 form_control p-2"}
                                   name={"file"}
                                   ref={fileInputRef}
                                   type="file"
                                   onChange={selectedFile}
                                   accept={".pdf"} required={true}
                               />
                           </div>
                       </div>


                       <ButtonTray
                           buttons={[
                               {
                                   buttonProps: {
                                       btnText: "Cancel",
                                       onClick: () => setShow(false),
                                       type: "close",
                                   },
                                   buttonType: "button"
                               },
                               {
                                   buttonProps: {
                                       btnText: "Submit",
                                       type: "submit",
                                       isLoading: isUploadedLoading,
                                   },
                                   buttonType: "button",

                               }
                           ]}
                           align="end"
                           gap={2}
                       />

                   </>
                   }/>}
            />
        </>
    )
}

export default UploadDocumentModal;