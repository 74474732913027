import SkeletonLoader from "../../components/common/SkeletonLoader";
import React from "react";

export const DetailRow = ({
                              label = "",
                              value = "",
                              isValueSpan = false,
                              className = "",
                              isLoading = false,
                              isError = false,
                              title = ""
                          }) => (
    <div className={`col-span-12 grid grid-cols-12 mt-3.5`}>
        <div className="col-span-5">
            <div className="text-text-3 font-semibold">{label}</div>
        </div>
        <div className="col-span-7">
            {
                (isLoading || isError) ? <SkeletonLoader count={1}/> :
                    isValueSpan ?
                        <span className={`bg-surface-3 text-text-1 text-xs p-1 rounded-md ${className}`}
                              title={title}>{value}
                        </span>
                        :
                        <div className={`text-text-1 ${className}`} title={title}>{value || "-"}</div>
            }
        </div>
    </div>
);

export const Separator = () => {
    return <div className="flex gap-2 items-center justify-between border-b border-surface-3 pb-2"/>
}

export const getFullPersonName = (person: {
    firstName: string,
    middleName: string,
    lastName: string,
    nameUnparsed: string
}) => {
    return [person.firstName, person.middleName, person.lastName, person.nameUnparsed].join(' ');
}