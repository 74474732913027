import React, {useState} from 'react';
import "./sideBar.modules.css";
import {IoMdArrowDropdown, IoMdArrowDropright} from 'react-icons/io';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {clearGlobalError} from "../../../app/globalSlice/globalSlice";

interface NavItemProps {
    route: string;
    icon: React.ReactElement;
    label: string;
    dropdownOptions?: NavDropdownOptions;
}

interface NavDropdownOptions {
    childDropdownItems: { label: string, route: string, hasPermission: boolean }[];
    isDropdownOpen: boolean;
    onDropdownToggle: () => void;
}

const NavItem: React.FC<NavItemProps> = ({
                                             route,
                                             icon,
                                             label,
                                             dropdownOptions
                                         }) => {

    const isCollapsed = useSelector((state: any) => state.global.isCollapsed);
    const location = useLocation();
    const navigate = useNavigate();
    const splitLocation = location.pathname.split("/");
    const dispatch = useDispatch();

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const handleParentClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (dropdownOptions?.childDropdownItems) {
            e.preventDefault();
            if (dropdownOptions?.onDropdownToggle) {
                dropdownOptions?.onDropdownToggle();
            }
        }
    };

    const handleNavigation = (route: string) => {
        dispatch(clearGlobalError());
        navigate(route);
        setIsTooltipOpen(false); // Close the tooltip when a link is clicked
    };

    const handleMouseEnter = () => {
        if (isCollapsed && dropdownOptions?.childDropdownItems) {
            setIsTooltipOpen(true);
        }
    };

    const handleMouseLeave = () => {
        if (isCollapsed && dropdownOptions?.childDropdownItems) {
            setIsTooltipOpen(false);
        }
    };

    return (
        <li className={`relative group`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {dropdownOptions?.childDropdownItems ? (
                <button
                    onClick={handleParentClick}
                    className={`${splitLocation[1] === route.slice(1) && "text-highlight-7"} flex items-center px-4 py-2 text-text-1 hover:text-highlight-7 group w-full ${isCollapsed ? "justify-center" : "justify-between"}`}>
                    <div className='flex items-center'>
                        {icon}
                        <span className={`font-normal ${isCollapsed ? 'hidden' : 'block'} ms-4 text-sm`}>{label}</span>
                        {isCollapsed && isTooltipOpen && (
                            <div className={"absolute left-full top-1 bg-[transparent]"}>
                                <div
                                    className="flex-col tooltip text-base font-normal border border-highlight-7 text-left ml-2 flex bg-surface-2 text-text-1 w-44 rounded px-3">
                                    {dropdownOptions?.childDropdownItems?.filter((el: any) => el.hasPermission).map((item, index) => (
                                        <Link
                                            key={index}
                                            to={item.route}
                                            onClick={() => handleNavigation(item.route)}
                                            className='leading-8 text-sm hover:text-highlight-7 block'>
                                            {item.label}
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    {!isCollapsed && (dropdownOptions?.isDropdownOpen ? <IoMdArrowDropdown/> : <IoMdArrowDropright/>)}
                </button>
            ) : (
                <Link
                    to={route}
                    className={`${splitLocation[1] === route.slice(1) && "text-highlight-7"} flex items-center px-4 py-2 text-text-1 hover:text-highlight-7 group w-full ${isCollapsed ? "justify-center" : "justify-between"}`}
                    target="_self"
                    rel="noopener noreferrer"
                    title={label}
                    onClick={() => handleNavigation(route)}
                >
                    <div className='flex items-center'>
                        {icon}
                        <span className={`font-normal ${isCollapsed ? 'hidden' : 'block'} ms-4 text-sm`}>{label}</span>
                    </div>
                </Link>
            )}

            {dropdownOptions?.isDropdownOpen && !isCollapsed && dropdownOptions?.childDropdownItems && (
                <ul className='pl-7'>
                    {dropdownOptions?.childDropdownItems?.filter((el: any) => el.hasPermission).map((item, index) => (
                        <li key={index}
                            className='dropDownList relative text-sm font-normal hover:text-highlight-7 cursor-pointer'>
                            <Link
                                to={item.route}
                                className="flex items-center py-2 text-sm"
                                onClick={() => handleNavigation(item.route)}>
                                {item.label}
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    );
};

export default NavItem;
