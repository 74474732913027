import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {sliceNames} from "../../helpers/constants";

export const globalSliceReducerInitialState = {
    isCollapsed: true,
    error: null,
    redirectPath: "/home",
}

const globalSlice = createSlice({
    name: sliceNames.GLOBAL,
    initialState: globalSliceReducerInitialState,
    reducers: {
        setShowSidebar: (state, action: PayloadAction<boolean>) => {
            state.isCollapsed = action.payload;
        },
        setGlobalError(state, action) {
            state.error = action.payload;
        },
        clearGlobalError(state) {
            state.error = null;
        },
        setRedirectPath: (state, action) => {
            state.redirectPath = action.payload;
        },
        clearRedirectPath: (state) => {
            state.redirectPath = "/home";
        }
    }
})
// export const {setShowSidebar} = globalSlice.actions
export const {
    setShowSidebar,
    setGlobalError,
    clearGlobalError,
    setRedirectPath,
    clearRedirectPath
} = globalSlice.actions;
export default globalSlice.reducer;