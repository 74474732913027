import {ModalProps} from "../../../../helpers/constants";
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import Modal from "../../../../components/common/form/Modal";
import Form from "../../../../components/common/form/Form";
import InputField from "../../../../components/common/form/InputField";
import SelectField from "../../../../components/common/form/SelectField";
import {useGetAllStatesQuery} from "../../../data/states/statesApi";
import {handleRTKQuery} from "../../../../helpers/utils/RtkQueryUtils";
import {
    useCreateBranchContactMutation,
    useGetBranchContactByIdQuery,
    useUpdateBranchContactMutation
} from "../branchContactApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {createOptionListForSelectTag} from "../../../../helpers/utils/Utils";
import {useGetEnumsQuery} from "../../../../app/globalApi";
import NumberFormatField from "../../../../components/common/form/NumberFormatField";
import TextAreaField from "../../../../components/common/form/TextAreaField";
import {getEnumDisplayNameByConstant} from "../../../../helpers/utils/EnumUtils";
import {useParams} from "react-router-dom";
import {useGetCustomerByIdQuery} from "../customerApi";
import ButtonTray from "../../../../components/common/form/ButtonTray";

interface Props extends ModalProps {
    title: string;
    branchContactId?: string;
    btnText: string;
    isEditable?: boolean
}

const AddUpdateBranchContactModal: FC<Props> = ({
                                                    title,
                                                    branchContactId,
                                                    show,
                                                    setShow,
                                                    btnText,
                                                    isEditable = true
                                                }) => {

    let {branchId} = useParams();
    let {customerId} = useParams();

    const statesApiResult = useGetAllStatesQuery("");
    const [branchContactData, setBranchContactData] = useState<any>({});
    const [createBranchContact, createBranchContactApiResponse] = useCreateBranchContactMutation();
    const [updateBranchContact, updateBranchContactApiResponse] = useUpdateBranchContactMutation();
    const branchContactByIdResponse = useGetBranchContactByIdQuery(branchContactId ?? skipToken);
    const {data: enums} = useGetEnumsQuery("");
    const [render, setRender] = useState<any>("");
    const customerQuery = useGetCustomerByIdQuery(customerId ?? skipToken);

    useEffect(() => {
        let branchContactObj = branchContactByIdResponse?.data;
        if (branchContactObj) {
            let contactDto = branchContactObj.contact;
            let addressDto = contactDto?.address;

            setBranchContactData({
                id: contactDto?.id,
                name: contactDto?.name,
                mailingAddress: addressDto?.mailingAddress,
                phone: contactDto?.phone,
                email: contactDto?.email,
                website: contactDto?.website,
                fax: contactDto?.fax,
                zipCode: addressDto?.zipCode,
                state: addressDto?.state ? {
                    label: statesApiResult?.data?.filter((el: any) => el.stateAbbreviation === addressDto?.state)[0]?.stateName,
                    value: addressDto?.stateAbbreviation
                } : null,
                city: addressDto?.city,
                preferredContactMethod: contactDto?.preferredContactMethod ? {
                    value: contactDto?.preferredContactMethod,
                    label: getEnumDisplayNameByConstant(
                        enums?.["ContactMethod"],
                        contactDto?.preferredContactMethod
                    ),
                } : null,
                type: branchContactObj.type ? {
                    value: branchContactObj.type,
                    label: getEnumDisplayNameByConstant(
                        enums?.["ContactType"],
                        branchContactObj.type
                    ),
                } : null,
                note: contactDto?.note,
                isPrimary: contactDto?.isPrimary,
                shouldNotify: branchContactObj.shouldNotify,
                tenantId: branchContactObj.tenantId
            });
        }
    }, [branchContactByIdResponse?.data]);


    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const address = {
            state: branchContactData.state?.value,
            city: branchContactData.city,
            mailingAddress: branchContactData.mailingAddress,
            zipCode: branchContactData.zipCode,
        };
        const contact = {
            ...branchContactData,
            address: address,
            preferredContactMethod: branchContactData.preferredContactMethod?.value,
        };
        const branchContactDto = {
            contact: contact,
            type: branchContactData.type?.value,
            branchId: branchId,
            shouldNotify: branchContactData.shouldNotify,
            tenantId: customerQuery?.data?.tenantId
        }

        const createOrUpdate = async () => {
            if (btnText === "Create") {
                await createBranchContact({branchId: branchId, requestBody: branchContactDto}).unwrap();
            } else {
                await updateBranchContact({
                    contactId: branchContactId,
                    requestBody: branchContactDto,
                }).unwrap();
            }
        };

        await handleRTKQuery(createOrUpdate, () => {
            setBranchContactData({});
            setShow(false);
        });
    };

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | {
        name: string;
        value: any
    } | ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = "target" in e ? e.target : e;
        setBranchContactData({...branchContactData, [name]: value});
    };

    const handleToggle = (property: string) => () => {
        setBranchContactData((prevData: any) => ({
            ...prevData,
            [property]: !prevData[property]
        }));
        setRender(new Date().getMilliseconds());
    };

    return (
        <Modal title={title} show={show} setShow={setShow} className="w-3/5 mx-4"
               fields={
                   <Form onSubmit={handleSubmit} fields={
                       <>
                           <InputField
                               name="name"
                               label="Name"
                               onChange={handleOnChange}
                               value={branchContactData.name || ""}
                               type="text"
                               placeholder="Enter Contact Person Name..."
                               required
                           />
                           <InputField
                               name="mailingAddress"
                               label="Mailing Address"
                               onChange={handleOnChange}
                               value={branchContactData.mailingAddress || ""}
                               type="text"
                               placeholder="Enter Mailing Address..."
                           />

                           <NumberFormatField
                               name="phone"
                               className="form-control"
                               placeholder="Enter Phone..."
                               value={branchContactData.phone || ""}
                               onChange={(e: any) => handleOnChange({name: "phone", value: e.target.value})}
                               label={"Phone"}
                           />

                           <InputField
                               name="email"
                               label="Email"
                               onChange={handleOnChange}
                               value={branchContactData.email || ""}
                               type="email"
                               placeholder="Enter Email..."
                           />

                           <InputField
                               name="website"
                               label="Website"
                               onChange={handleOnChange}
                               value={branchContactData.website || ""}
                               type="text"
                               placeholder="Enter Website..."
                           />

                           <NumberFormatField
                               name="fax"
                               className="form-control"
                               placeholder="Enter Fax..."
                               value={branchContactData.fax || ""}
                               onChange={(e: any) => handleOnChange({name: "fax", value: e.target.value})}
                               label={"Fax"}
                           />

                           <InputField
                               name="zipCode"
                               label="Zip Code"
                               onChange={handleOnChange}
                               value={branchContactData.zipCode || ""}
                               type="text"
                               placeholder="Enter Zip Code..."
                           />

                           <SelectField
                               label="State"
                               options={createOptionListForSelectTag(statesApiResult?.data, "stateName", "stateAbbreviation")}
                               value={branchContactData.state || null}
                               placeholder="Select State..."
                               onChange={(selectedOption) => handleOnChange({name: "state", value: selectedOption})}
                           />

                           <InputField
                               name="city"
                               label="City"
                               onChange={handleOnChange}
                               value={branchContactData.city || ""}
                               type="text"
                               placeholder="Enter City..."
                           />
                           <SelectField
                               label="Contact Method"
                               options={createOptionListForSelectTag(enums?.["ContactMethod"], "displayName", "constant")}
                               value={branchContactData.preferredContactMethod || null}
                               placeholder="Select Contact Method..."
                               onChange={(selectedOption) => handleOnChange({
                                   name: "preferredContactMethod",
                                   value: selectedOption
                               })}
                           />
                           <SelectField
                               label="Contact Type"
                               options={createOptionListForSelectTag(enums?.["ContactType"], "displayName", "constant")}
                               value={branchContactData.type || null}
                               placeholder="Select Contact Type..."
                               onChange={(selectedOption) => handleOnChange({
                                   name: "type",
                                   value: selectedOption
                               })}
                           />

                           <TextAreaField
                               name="note"
                               label="Note"
                               onChange={handleOnChange}
                               value={branchContactData.note || ""}
                               placeholder="Enter Note..."
                               rows={2}
                           />

                           <InputField
                               name="shouldNotify"
                               label="Enable Email Notifications"
                               onChange={handleToggle("shouldNotify")}
                               checked={branchContactData.shouldNotify || ""}
                               type="checkbox"
                           />

                           <InputField
                               name="isPrimary"
                               label="Is Primary"
                               onChange={handleToggle("isPrimary")}
                               checked={branchContactData.isPrimary || ""}
                               type="checkbox"
                           />

                           <ButtonTray
                               buttons={[
                                   {
                                       buttonProps: {
                                           btnText: "Cancel",
                                           onClick: () => setShow(false),
                                           type: "close",
                                       },
                                       buttonType: "button"
                                   },
                                   {
                                       buttonProps: {
                                           btnText: "Submit",
                                           type: "submit",
                                           isLoading: (createBranchContactApiResponse.isLoading || updateBranchContactApiResponse.isLoading),
                                           isVisible: isEditable
                                       },
                                       buttonType: "button",

                                   }
                               ]}
                               align="end"
                               gap={2}
                           />

                       </>
                   }/>
               }/>
    );
};

export default AddUpdateBranchContactModal;
