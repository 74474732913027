import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {queryParamsToObject} from "../DataTableUtils";

interface InputWrapperProps {
    name?: string;
    id?: string;
    type?: string;
    placeholder?: string;
    disabled?: boolean;
    reset?: Function;
    onChange?: Function;
    onKeyDown?: Function;
    className?: string;
    accessor: string;
    defaultValue?: any;

    label?: string;
}

export const SearchInputWrapper: React.FC<InputWrapperProps> = (props: InputWrapperProps) => {

    const location: any = useLocation();

    const {
        name,
        id,
        placeholder,
        type,
        disabled,
        onChange,
        onKeyDown,
        accessor,
        defaultValue = null,
        label = null
    } = props;

    let [values, setValues] = useState(defaultValue);

    useEffect(() => {
        setValues(defaultValue !== null ? defaultValue : values);
    }, [defaultValue]);

    useEffect(() => {
        if (type === 'checkBox') {
            setValues(values === null ? defaultValue : values);
        }
    }, [values]);

    const handleChange = (value: any) => {
        if (type === 'checkBox') {
            setValues(value.target.checked);
        } else {
            setValues(value.target.value);
        }

        if (!onChange) return;
        onChange(value);
    };

    const handleKeyDown = (value: any) => {
        if (!onKeyDown) return;
        onKeyDown(value);
    };

    useEffect(() => {
        if (location.search) {
            const queryParamsObject = queryParamsToObject(location.search);

            if (queryParamsObject.hasOwnProperty(accessor)) {
                // @ts-ignore
                setValues(queryParamsObject[accessor]);
            } else {
                setValues(null);
            }
        }
    }, [location.search]);

    return type !== "checkBox" ?
        <input id={id} type={type} name={name} value={values === null ? "" : values}
               placeholder={placeholder}
               className={"bg-surface-2 border border-surface-5 form_control p-2 placeholder:text-text-2 rounded-md text-text-1 w-full"}
               onChange={handleChange}
               onKeyDown={handleKeyDown}
               disabled={disabled}/>
        :
        <>
            <input className={"h-8 w-5"} id={id} type={type} value={values} name={name}
                   checked={values}
                   placeholder={placeholder}
                   onChange={handleChange}
                   onKeyDown={handleKeyDown}
                   disabled={disabled}/>
            <label className={"ml-2"}>
                {label}
            </label>
        </>
};