// @ts-ignore
import logo from "../../images/logo.png";
import Form from "../../components/common/form/Form";
import React, {FormEvent, useState} from "react";
import Button from "../../components/common/form/Button";
import InputField from "../../components/common/form/InputField";
import {showToast, validatePassword} from "../../helpers/utils/Utils";
import {useLocation, useNavigate} from "react-router-dom";
import {useResetPasswordMutation} from "../user/users/usersApi";
import {handleRTKQuery} from "../../helpers/utils/RtkQueryUtils";
import {Routes as AppRoutes} from "../../routes";

const ResetPassword = () => {

    const search = useLocation().search;
    const navigate = useNavigate();

    const id = new URLSearchParams(search).get('id');
    const token = new URLSearchParams(search).get('token');

    const [newPassword, setNewPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [errors, setErrors] = useState<any>({});
    const [resetPasswordApi, resetPasswordApiResponse] = useResetPasswordMutation()

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let obj = {
            id: id,
            token: token,
            newPassword: newPassword
        }
        await handleRTKQuery(
            async () => {
                return await resetPasswordApi(obj).unwrap();
            },
            () => {
                setNewPassword("");
                setConfirmPassword("");
                navigate(AppRoutes.Login.path);
                showToast("Password Reset Successfully!")
            }
        )
    }

    return (
        <>
            <section className="login_form flex items-center justify-center ">
                <div className="container mx-auto px-4">
                    <div className="flex justify-center">
                        <div className="w-full max-w-xl lg:max-w-lg md:max-w-md">
                            <div className="log_in relative z-10">
                                <Form onSubmit={handleSubmit} fields={
                                    <>
                                        <div className="text-center mb-4">
                                            <img loading="lazy" src={logo} alt=""/>
                                        </div>

                                        <div className="form-group mb-4">
                                            <InputField name="newPassword"
                                                        type="password"
                                                        required={true}
                                                        onChange={(e: any) => {
                                                            setNewPassword(e.target.value);
                                                            setErrors(validatePassword(e.target.value, confirmPassword));
                                                        }}
                                                        value={newPassword}
                                                        className="form-control"
                                                        placeholder="New Password"
                                                        hideLabel={true}
                                                        error={Object.keys(errors).length > 0 && !errors.isValidSubmission && (
                                                            <ul className="text-error-1">
                                                                {errors.uppercaseChar &&
                                                                    <li>{errors.uppercaseChar}</li>}
                                                                {errors.lowercaseChar &&
                                                                    <li>{errors.lowercaseChar}</li>}
                                                                {errors.number && <li>{errors.number}</li>}
                                                                {errors.specialChar && <li>{errors.specialChar}</li>}
                                                                {errors.length && <li>{errors.length}</li>}
                                                            </ul>
                                                        )}
                                            />

                                            <InputField name="confirmPassword"
                                                        type="password"
                                                        required={true}
                                                        onChange={(e: any) => {
                                                            setConfirmPassword(e.target.value);
                                                            setErrors(validatePassword(newPassword, e.target.value));

                                                        }}
                                                        value={confirmPassword}
                                                        className="form-control"
                                                        placeholder="Confirm Password"
                                                        hideLabel={true}
                                                        error={errors.match && (
                                                            <div className="text-error-1">{errors.match}</div>
                                                        )}
                                            />
                                        </div>

                                        <div className="form-group mb-4">
                                            <Button btnText={"Reset Password"}
                                                    isLoading={resetPasswordApiResponse.isLoading}
                                                    type={"submit"}
                                                    isDisabled={!errors?.isValidSubmission}></Button>
                                        </div>

                                    </>
                                }/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ResetPassword;