import Modal from "../../../../components/common/form/Modal";
import React, {FC, useState} from "react";
import {ModalProps} from "../../../../helpers/constants";
import {useSearchUsersQuery} from "../../users/usersApi";
import {DataTable} from "../../../../components/common/datatable/DataTable";
import {useAddNewMemberIntoTeamMutation, useGetTeamByIdQuery} from "../teamsApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {handleRTKQuery} from "../../../../helpers/utils/RtkQueryUtils";
import {DataTableColumn} from "../../../../components/common/datatable/DataTableTypes";
import ButtonTray from "../../../../components/common/form/ButtonTray";

interface props extends ModalProps {
    teamId: string | undefined
}

const AddMember: FC<props> = ({teamId, show, setShow}) => {

    const teamByIdQuery = useGetTeamByIdQuery(teamId ?? skipToken);
    const [userId, setUserId] = useState<any>(null);
    const [addMemberApi, addMemberApiResponse] = useAddNewMemberIntoTeamMutation();

    const handleRowSelection = (newSelectedRow: any, row: any) => {
        setUserId(row?.original?.id);
    };

    const columns: DataTableColumn[] = [
        {
            label: "Name",
            accessor: "username",
        },
        {
            label: "Email",
            accessor: "email"
        },
    ];

    const handleAddMember = async () => {
        await handleRTKQuery(() => {
            addMemberApi({teamId, userId});
        }, () => {
            setShow(false);
        });
    }

    return (
        <>
            <Modal title={"Add Member"} show={show} setShow={setShow} className={"w-3/5 mx-4"}
                   fields={
                       <>
                           <DataTable
                               columns={columns}
                               actions={{
                                   fetchAction: useSearchUsersQuery
                               }}
                               defaultFilter={{
                                   teamId: teamId,
                                   teamParam: "Is_MEMBER_SEARCH_PARAM",
                                   tenantId: teamByIdQuery?.data.tenantId,

                               }}
                               renderOptions={{
                                   skipUserSettingRender: true
                               }}
                               rowSelectionOptions={{
                                   enableRowSelection: true,
                                   onSelectionChange: handleRowSelection,
                                   enableMultiRowSelection: false,
                                   rowIdAccessor: 'id'
                               }}
                               appendFilterToUrl={false}
                           ></DataTable>


                           <ButtonTray
                               buttons={[
                                   {
                                       buttonProps: {
                                           btnText: "Cancel",
                                           onClick: () => setShow(false),
                                           type: "close",
                                       },
                                       buttonType: "button"
                                   },
                                   {
                                       buttonProps: {
                                           btnText: "Submit",
                                           onClick: handleAddMember,
                                           type: "button",
                                           isLoading: (addMemberApiResponse.isLoading),
                                       },
                                       buttonType: "button",

                                   }
                               ]}
                               align={"end"}
                               gap={2}
                           />

                       </>
                   }
            />

        </>
    )
}
export default AddMember;