import React from "react";
import { Oval } from "react-loader-spinner";

interface LoaderProps {
    buttonSizePx?: number
}

const TailSpinLoader = (props: LoaderProps) => {
    return (
        <div role="status" className={'ml-1'}>
            {/*TODO: Once we update our tailwind config to be pulled from CSS variables, we'll use those same CSS variables here*/}
            <Oval height={props.buttonSizePx || 18}
                  width={props.buttonSizePx || 18}
                  strokeWidth={6}
            />
        </div>
    );
}

export default TailSpinLoader;