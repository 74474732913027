import {hasPermission} from "../../../../helpers/utils/AccessControlUtils";
import {createClickableLink} from "../../../../components/common/datatable/DataTableUtils";
import {useParams} from "react-router-dom";
import {useFindUserSettingsByGroupNameQuery} from "../../../../components/common/userSetting/userSettingsApi";
import {getBooleanDisplayIcon} from "../../../../helpers/utils/Utils";
import {DataTable} from "../../../../components/common/datatable/DataTable";
import {useGetBranchesForCustomerQuery} from "../branchApi";
import {FaPlus} from "react-icons/fa6";
import React from "react";
import ModalButton from "../../../../components/common/form/ModalButton";
import AddBranchModal from "../modal/AddBranchModal";
import {DataTableColumn} from "../../../../components/common/datatable/DataTableTypes";

const BranchTab = () => {

    let {customerId} = useParams();
    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("Branch");

    let columns: DataTableColumn[] = [
        {
            label: "Name", accessor: "name",
            cell: (props: any) => {
                let path = "/access-control/customers/" + customerId + "/" + props.row.original.id;
                return createClickableLink(path, props.value, userSettings, false, "", ["BRAN-R", "BRAN-V"]);
            }
        },
        {label: "Partner Branch", accessor: "partnerBranchId"},
        {
            label: "Is Default", accessor: "defaultBranch",
            cell: (props: any) => getBooleanDisplayIcon(props.value, "Enabled", "Disabled"),
        },

    ]

    return (
        <>
            {hasPermission(["BRAN-C"]) &&
                <div className={"flex justify-end"}>

                    <ModalButton
                        triggerButton={
                            <div
                                className="h-7 w-10 text-text-1 rounded-md bg-highlight-3 flex justify-center items-center cursor-pointer hover:bg-highlight-7 absolute top-0 left-auto">
                                <FaPlus/>
                            </div>
                        }
                        onOpen={() => {
                        }}
                        modal={
                            <AddBranchModal
                                show={true}
                                setShow={() => {
                                }}
                                customerId={customerId}
                            />
                        }
                    />
                </div>
            }

            <div className='-mt-4'>
                <DataTable
                    columns={columns}
                    actions={{
                        fetchAction: useGetBranchesForCustomerQuery
                    }}
                    defaultFilter={{
                        customerId: customerId
                    }}
                    userSettingGroup={"Branch"}
                    appendFilterToUrl={false}
                ></DataTable>
            </div>
        </>
    )
}

export default BranchTab;