import {ModalProps} from "../../../helpers/constants";
import React, {FC} from "react";
import {DataTableColumn} from "../../common/datatable/DataTableTypes";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {DataTable} from "../../common/datatable/DataTable";
import ButtonTray from "../../common/form/ButtonTray";
import Modal from "../../common/form/Modal";
import {useSearchProductsQuery} from "../../../features/order/productsApi";
import {createClickableLink} from "../../common/datatable/DataTableUtils";

interface props extends ModalProps {
    orderNumber: string,
    setOrderNumber: any
}

const SearchOrderModal: FC<props> = ({orderNumber, setOrderNumber, show, setShow}) => {

    const {data: enums} = useGetEnumsQuery("");

    const columns: DataTableColumn[] = [
        {
            label: "Order No.",
            accessor: "orderNumber",
            overrideSortColumn: "order.orderNumber",
            cell: (props: any) => {
                let path = `/orders/${props.row.original.orderId}#${props.row.original.id}`;
                return createClickableLink(path, props.value, null, false, "", ["ORDR-R", "ORDR-V"]);
            },
        },
        {
            label: "Type",
            accessor: "type",
            cell: (props: any) => getEnumDisplayNameByConstant(enums?.["ProductType"], props.value)
        }
    ]
    return (
        <>
            <Modal title={"Search Order"} show={show} setShow={setShow} className={"w-3/5 mx-4"}
                   fields={
                       <>
                           <DataTable
                               columns={columns}
                               actions={{
                                   fetchAction: useSearchProductsQuery
                               }}
                               defaultFilter={{
                                   orderNumber: orderNumber,
                               }}
                               renderOptions={{
                                   skipUserSettingRender: true
                               }}
                               rowSelectionOptions={{
                                   enableRowSelection: true,
                                   onSelectionChange: () => {
                                       setShow(false);
                                       setOrderNumber("");
                                   },
                                   enableMultiRowSelection: false,
                                   rowIdAccessor: 'id'
                               }}
                               appendFilterToUrl={false}
                           ></DataTable>

                           <ButtonTray
                               buttons={[
                                   {
                                       buttonProps: {
                                           btnText: "Cancel",
                                           onClick: () => setShow(false),
                                           type: "close",
                                       },
                                       buttonType: "button"
                                   }
                               ]}
                               align={"end"}
                               gap={2}
                           />
                       </>
                   }
            />
        </>
    )
}

export default SearchOrderModal;