import {strApi} from "../../../app/strApi";
import {
    getAuthorizationHeader,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../../helpers/utils/RtkQueryUtils";
import {serialize} from "../../../helpers/utils/Utils";

export const cannedNotesApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        getCannedNoteById: build.query({
            query: (id) => {
                return {
                    url: `/canned-notes/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ['cannedNoteById'],
            async onQueryStarted(_, {queryFulfilled}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        updateCannedNote: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/canned-notes/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['cannedNotes', 'cannedNoteById'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Canned Note")
            },
        }),
        searchCannedNotes: build.query({
            query: (data) => {
                let paramData = serialize(data);
                return {
                    url: `/canned-notes/search?${paramData}`,
                    method: 'POST',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ["cannedNotes"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
    }),
});


export const {
    useSearchCannedNotesQuery,
    useUpdateCannedNoteMutation,
    useGetCannedNoteByIdQuery

} = cannedNotesApi;