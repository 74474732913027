import {useParams} from "react-router-dom";
import ModalButton from "../../../../components/common/form/ModalButton";
import {FaPlus, FaTrash} from "react-icons/fa6";
import {DataTable} from "../../../../components/common/datatable/DataTable";
import React, {FormEvent, useState} from "react";
import {useRemoveMemberFromTeamMutation, useTeamMembersQuery} from "../teamsApi";
import {hasPermission} from "../../../../helpers/utils/AccessControlUtils";
import ConfirmationModal from "../../../../components/layout/modal/ConfirmationModal";
import AddMember from "../modal/AddMember";
import {DataTableColumn} from "../../../../components/common/datatable/DataTableTypes";

const MemberTab = () => {

    let {teamId} = useParams();
    const [selectedMemberId, setSelectedMemberId] = useState("");
    const [deleteMemberApi, {isLoading: deleteMemberApiResponse}] = useRemoveMemberFromTeamMutation();

    const handleDeleteMemberSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (selectedMemberId) {
            return deleteMemberApi({teamId: teamId, userId: selectedMemberId});
        }
    }

    const columns: DataTableColumn[] = [
        {
            label: "Name",
            accessor: "username",
        },
        {
            label: "Email",
            accessor: "email"
        },
        {
            label: "Action", visibility: {
                canColumnShow: hasPermission(["TEAM-U"]),
                showByDefault: hasPermission(["TEAM-U"]),
                hasShowSetting: false,
            },
            accessor: "creationDate",
            canSort: false,
            cell: (props: any) => {
                return <>
                    <div className="ml-4">
                        <ModalButton
                            triggerButton={
                                <FaTrash
                                    className={"cursor-pointer text-highlight-3 text-lg hover:text-highlight-7"}
                                    title={"Delete Member"}/>
                            }
                            onOpen={() => {
                                setSelectedMemberId(props.row.original.id)
                            }}
                            modal={
                                <ConfirmationModal
                                    show={true}
                                    setShow={() => {
                                    }}
                                    title={"Delete Member"}
                                    bodyMessage={`Are you sure you want to remove ${props.row.original.username} from the member list ?`}
                                    onSubmit={handleDeleteMemberSubmit}
                                    isLoading={deleteMemberApiResponse}
                                />
                            }
                        />
                    </div>
                </>
            }
        }

    ]

    return (
        <>

            {hasPermission(["TEAM-U"]) &&
                <div className={"flex justify-end"}>
                    <ModalButton
                        triggerButton={
                            <div
                                className="h-7 w-10 text-text-1 rounded-md bg-highlight-3 flex justify-center items-center cursor-pointer hover:bg-highlight-7 absolute top-0 left-auto">
                                <FaPlus/>
                            </div>
                        }
                        onOpen={() => {
                        }}
                        modal={
                            <AddMember
                                show={true}
                                setShow={() => {
                                }}
                                teamId={teamId}
                            />
                        }
                    />

                </div>}

            <div className='-mt-4'>
                <DataTable
                    columns={columns}
                    actions={{
                        fetchAction: useTeamMembersQuery
                    }}
                    defaultFilter={{
                        teamId: teamId
                    }}
                    userSettingGroup={"Team Member"}
                    appendFilterToUrl={false}
                />
            </div>

        </>
    )
}

export default MemberTab;