import {strApi} from "../../../app/strApi";
import {getAuthorizationHeader, handleQueryError} from "../../../helpers/utils/RtkQueryUtils";
import {serialize} from "../../../helpers/utils/Utils";

export const systemPropertiesApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        searchSystemProperties: build.query({
            query: (query) => {
                return {
                    url: `/system-properties/search?${serialize(query)}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ["systemProperties"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        getSystemPropertyById: build.query({
            query: (id) => {
                return {
                    url: `/system-properties/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ["systemPropertyById"],
            async onQueryStarted(_, {queryFulfilled}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        updateSystemPropertyById: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/system-properties/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ["systemPropertyById", "systemProperties"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

    })
})

export const {
    useSearchSystemPropertiesQuery,
    useGetSystemPropertyByIdQuery,
    useUpdateSystemPropertyByIdMutation
} = systemPropertiesApi