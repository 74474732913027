import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import Button from "../../../components/common/form/Button";
import Form from "../../../components/common/form/Form";
import {useNavigate} from "react-router-dom";
import {OptionType} from "../../../helpers/utils/StyleUtils";
import InputField from "../../../components/common/form/InputField";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import SelectField from "../../../components/common/form/SelectField";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {useGetAllTenantsQuery} from "./tenantApi";
import {useCreateCustomerMutation} from "./customerApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {Routes as AppRoutes} from "../../../routes";
import ButtonTray from "../../../components/common/form/ButtonTray";

const CreateCustomerForm: FC = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/"},
        {content: "Customers", url: "/access-control/customers"},
        {content: "Create Customer", url: "/access-control/customers/create"},
    ]);

    const navigate = useNavigate();
    const [customerData, setCustomerData] = useState<any>({
        'customerLevel': {value: 'TENANT', label: 'Tenant'}
    });
    const {data: enums} = useGetEnumsQuery("");
    const tenantList = useGetAllTenantsQuery("");
    const [createCustomer, createCustomerApiResponse] = useCreateCustomerMutation();

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        let obj = {
            customerName: customerData.customerName,
            customerType: customerData.customerType?.value || 'RETAIl',
            customerLevel: customerData.customerLevel?.value,
            billingType: customerData.billingType?.value || 'PERCLOSE',
            tenantId: customerData?.customerLevel?.value !== "TENANT" ? customerData.tenantId?.value : customerData.tenantId,
            contactStrategy: 'UNIVERSAL',
        }

        await handleRTKQuery(
            async () => {
                return await createCustomer(obj).unwrap();
            },
            (response: any) => {
                setCustomerData({});
                navigate("/access-control/customers");
            }
        );
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;
        setCustomerData({
            ...customerData,
            [name]: value,
        })
    }

    useEffect(() => {

        let val = customerData?.customerLevel?.value;
        if (val === "TENANT"){
            setCustomerData({
                ...customerData,
                tenantId : null
            })
        }

    }, [customerData?.customerLevel]);

    return (
        <>

            <div className="adduser_container px-4 py-2">

                <Breadcrumb crumbs={crumbs}/>
                <div className="flex justify-center mt-3">
                    <div className="bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">Create
                            Customer</h3>

                        <Form onSubmit={handleSubmit} fields={<>

                            <div className="grid grid-cols-12 adduser_Wrapper">
                                <InputField
                                    name={"customerName"}
                                    label="Customer Name"
                                    onChange={handleOnChange}
                                    value={customerData?.customerName || ""}
                                    type="text"
                                    placeholder="Enter Customer Name..."
                                    required={true}
                                />

                                <SelectField
                                    placeholder='Select Contact Strategy...'
                                    value={customerData?.contactStrategy || null}
                                    options={createOptionListForSelectTag(enums?.["ContactStrategy"], "displayName", "constant")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "contactStrategy", value: selectedOption})
                                    }}
                                    required={true}
                                    label={"Contact Strategy"}
                                />

                                <SelectField
                                    placeholder='Select Customer Level...'
                                    value={customerData?.customerLevel || null}
                                    options={createOptionListForSelectTag(enums?.["CustomerLevel"]?.filter((cur: any) => {
                                        return cur.constant !== "SYS"
                                    }), "displayName", "constant")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "customerLevel", value: selectedOption})
                                    }}
                                    required={true}
                                    label={"Customer Level"}
                                />

                                {customerData?.customerLevel &&
                                    <>
                                        {
                                            customerData?.customerLevel?.value !== "TENANT" ?
                                                <SelectField
                                                    placeholder='Select Tenant...'
                                                    value={customerData?.tenantId || null}
                                                    options={createOptionListForSelectTag(tenantList?.data, "name", "id")}
                                                    onChange={(selectedOption) => {
                                                        handleOnChange({name: "tenantId", value: selectedOption})
                                                    }}
                                                    required={true}
                                                    label={"Tenant"}
                                                />
                                                :
                                                <InputField
                                                    name={"tenantId"}
                                                    label="Tenant Name"
                                                    onChange={handleOnChange}
                                                    value={customerData?.tenantId}
                                                    type="text"
                                                    placeholder="Enter Tenant..."
                                                    required={true}
                                                />
                                        }

                                    </>
                                }

                                {customerData?.customerLevel && customerData.customerLevel.value !== "TENANT" &&
                                    <>
                                        <SelectField
                                            placeholder='Select Customer Type...'
                                            value={customerData?.customerType || null}
                                            options={createOptionListForSelectTag(enums?.["CustomerType"], "displayName", "constant")}
                                            onChange={(selectedOption) => {
                                                handleOnChange({name: "customerType", value: selectedOption})
                                            }}
                                            required={true}
                                            label={"Customer Type"}
                                        />

                                        <SelectField
                                            placeholder='Select Billing Type...'
                                            value={customerData?.billingType || null}
                                            options={createOptionListForSelectTag(enums?.["BillingType"], "displayName", "constant")}
                                            onChange={(selectedOption) => {
                                                handleOnChange({name: "billingType", value: selectedOption})
                                            }}
                                            required={true}
                                            label={"Billing Type"}
                                        />
                                    </>
                                }

                            </div>

                            <ButtonTray
                                buttons={[
                                    {
                                        buttonProps: {
                                            btnText: "Cancel",
                                            onClick: () => navigate(AppRoutes.Customers.path),
                                            type: "close",
                                        },
                                        buttonType: "button"
                                    },
                                    {
                                        buttonProps: {
                                            btnText: "Submit",
                                            type: "submit",
                                            isLoading: (createCustomerApiResponse.isLoading),
                                        },
                                        buttonType: "button"
                                    }
                                ]}
                                align="end"
                                gap={2}
                            />
                        </>}/>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CreateCustomerForm;