import React, {FC, useState} from "react";
import {FaFilter} from "react-icons/fa";
import Select from "react-select";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import {selectStyle} from "../../../helpers/utils/StyleUtils";
import SkeletonLoader from "../../../components/common/SkeletonLoader";
import {useGetOrderLogListQuery} from "../ordersApi";
import {useGetEnumsQuery} from "../../../app/globalApi";
import AuditableInfo from "../../../components/common/AuditableInfo";
import {skipToken} from "@reduxjs/toolkit/query";

interface props {
    orderId: string
}

// Define the Props interface
interface HistoryRowProps {
    data: any;
}

const ProductHistory: FC<props> = ({orderId}) => {

    const [selectedLogType, setSelectedLogType] = useState<string[]>(['INFO'])
    const [show, setShow] = useState(false)
    const {data: enums} = useGetEnumsQuery("")

    const {
        data: orderLogs,
        isLoading: loading,
        isError: error,
        isFetching: fetching
    } = useGetOrderLogListQuery(orderId ? {orderId: orderId, logTypes: selectedLogType} : skipToken);

    const Row: FC<HistoryRowProps> = ({data}) => (
        <div title={data?.logType}
             className={`${data?.logType === 'INFO' && 'border-l-highlight-5'} border-l-8 border-t border-b border-r rounded-sm p-3 lg:grid sm:block grid-cols-12 border border-surface-3 pb-2 mt-3`}>
            <div className="col-span-6">
                <div title={data?.detailedLogMessage ?? data?.logMessage} className="text-text-1">
                    {data?.logMessage}
                </div>
            </div>
            <div className="col-span-6 text-sm">
                <AuditableInfo data={data}/>
            </div>
        </div>
    );

    const handleChange = (selectedOption: any[]) => {
        const selectedOptions = selectedOption?.map(c => c.value) || []
        setSelectedLogType(selectedOptions)
    };

    const iconButtonStyle = "cursor-pointer text-highlight-3 text-lg hover:text-highlight-7";

    return (
        <>
            <div className="bg-surface-2 rounded-md p-3 mt-3">
                <div className="flex gap-2 items-center border-b border-surface-3 pb-2">
                    <h3 className="text-text-1 font-semibold">History ({orderLogs?.length || 0}) </h3>
                    <div className={"mt-1"}>
                        <FaFilter className={iconButtonStyle} onClick={() => {
                            setShow(!show)
                        }}/>
                    </div>
                </div>
                {
                    show && <Select
                        placeholder='Select filter options...'
                        isMulti={true}
                        options={createOptionListForSelectTag(enums?.LogType, "displayName", "constant")}
                        value={createOptionListForSelectTag(enums?.LogType, "displayName", "constant").filter((option: any) => selectedLogType.includes(option.value))}
                        //@ts-ignore
                        onChange={handleChange}
                        styles={selectStyle}
                    />
                }
                <div className='overflow-y-auto max-h-56'>
                    {
                        (loading || fetching) ?
                            <SkeletonLoader className={"mt-2"} count={3}></SkeletonLoader> :
                            !error && orderLogs?.length === 0 ?
                                <div className="mt-2 border border-surface-3 p-4 rounded-sm">
                                    <h3 className="text-text-1">
                                        No order history to display
                                    </h3>
                                </div> :
                                !error && orderLogs?.map((data: any) => {
                                    return <Row
                                        data={data}
                                    />
                                })
                    }
                </div>
            </div>
        </>
    )
}

export default ProductHistory;