import {DataTable} from "../../../components/common/datatable/DataTable";
import {useGetTeamAndUserAssignmentsQuery} from "../../order/productsApi";
import React from "react";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import ModalButton from "../../../components/common/form/ModalButton";
import IconButton from "../../../components/common/form/IconButton";
import {FaTag} from "react-icons/fa";
import {formatValueAsDate} from "../../../helpers/utils/DateUtils";
import FollowUpTypeModal from "../../order/modal/FollowUpTypeModal";

const TeamWorkTab = () => {

    const {data: enums} = useGetEnumsQuery("");
    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("Team Work");

    const columns = [
        {
            label: "Order No.",
            accessor: "orderNumber",
            overrideSortColumn: "order.orderNumber",
            cell: (props: any) => {
                let path = `/orders/${props.row.original.orderId}#${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["ORDR-R", "ORDR-V"]);
            }
        },
        {
            label: "Customer",
            accessor: "customerName",
            disableSortBy: true,
        },
        {
            label: "Branch",
            accessor: "branchName",
            disableSortBy: true,
        },
        {
            label: "County",
            accessor: "countyName",
            overrideSortColumn: "countyId",
            disableSortBy: true,
        },
        {
            label: "Type",
            accessor: "type",
            cell: (props: any) => getEnumDisplayNameByConstant(enums?.["ProductType"], props.value),

        },
        {
            label: "Status",
            accessor: "status",
            cell: (props: any) => {
                let followObj = props.row.original.followUp;
                let followUpTypeDTO = props.row.original.followUpTypeDTO;
                return (
                    <>
                        <div className={`flex gap-1 ${followObj ? 'ms-[-1rem]' : ''}`}>

                            {hasPermission(["PROFOLLUP-V"]) && followObj != null &&
                                <ModalButton
                                    triggerButton={
                                        <IconButton icon={
                                            <FaTag className={"text-sm"}
                                                   style={{color: followObj && followUpTypeDTO?.hexColor}}/>}
                                                    title={followUpTypeDTO?.name + " : " + formatValueAsDate(followObj?.followUpAdded) + ' ' + followObj?.followUpMessage}
                                                    onClick={() => ''}/>
                                    }
                                    modal={
                                        hasPermission(["PROFOLLUP-R"]) &&
                                        <FollowUpTypeModal
                                            show={true}
                                            setShow={() => {
                                            }}
                                            productId={props.row.original.id}
                                        />
                                    }
                                />
                            }
                            <span>{getEnumDisplayNameByConstant(enums?.["ProductStatus"], props.value)}</span>
                        </div>
                    </>
                )
            }
        },
        {
            label: "Assigned Date", accessor: "assignmentInfo.assignedDate", cell: (props: any) => {
                return formatValueAsDate(props.row.original.assignmentInfo.assignedDate);
            }
        },
        {
            label: "Assigned To",
            accessor: "assigneeName",
            disableSortBy: true,
        },
    ]

    return (
        <>
            <div className='-mt-4'>
                <DataTable
                    columns={columns}
                    actions={{
                        fetchAction: useGetTeamAndUserAssignmentsQuery
                    }}
                    defaultFilter={{
                        assigneeType: "USER",
                        gridType: "TEAM_WORK",
                    }}
                    userSettingGroup='Team Work'
                    defaultSortOptions={{
                        accessor: 'assignmentInfo.assignedDate',
                        direction: 'desc'
                    }}
                    appendFilterToUrl={false}
                />
            </div>
        </>
    )
}
export default TeamWorkTab;