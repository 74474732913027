import {strApi} from "../../../app/strApi";
import {
    getAuthorizationHeader, getFileDownloadHeaders,
    handleQueryError,
    handleQueryErrorAndSuccess
} from "../../../helpers/utils/RtkQueryUtils";
import {getFileName, serialize} from "../../../helpers/utils/Utils";
import {downloadFile} from "../../../helpers/utils/FileUtils";

export const statesApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        getAllStates: build.query({
            query: () => {
                return {
                    url: `/states`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        searchStates: build.query({
            query: (query) => {
                return {
                    url: `/states/search?${serialize(query)}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ["states"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        createState: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/states`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['states'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "State");
            },
        }),
        getStateById: build.query({
            query: (id) => {
                return {
                    url: `/states/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader(),
                };
            },
            providesTags: ['stateById'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        updateState: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/states/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['states', 'stateById'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "State");
            },
        }),

        exportStateData: build.query({
            query: (query) => {
                return {
                    url: `/states/export-csv`,
                    method: 'GET',
                    params: query,
                    headers: getFileDownloadHeaders(),
                    responseType: 'arraybuffer',
                    responseHandler: async (response: { blob: () => any; }) => {
                        const blob = await response.blob();
                        downloadFile(blob, getFileName("states"))
                        return {success: true};
                    },
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

    }),
});


export const {
    useGetAllStatesQuery,
    useSearchStatesQuery,
    useCreateStateMutation,
    useUpdateStateMutation,
    useGetStateByIdQuery,
    useLazyExportStateDataQuery
} = statesApi;