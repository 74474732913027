import {strApi} from "../../app/strApi";
import {getAuthorizationHeader, handleQueryErrorAndSuccess} from "../../helpers/utils/RtkQueryUtils";
import {serialize} from "../../helpers/utils/Utils";

export const recordingDocumentApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        updateDocument: build.mutation({
            query: (id) => {
                return {
                    url: `/recording-documents/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled,"Updated", "Document")
            },
            invalidatesTags: ['productDocumentList']
        }),
        removeDocument: build.mutation({
            query: (id) => {
                return {
                    url: `/recording-documents/${id}`,
                    method: 'DELETE',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled,"Removed", "Document")
            },
            invalidatesTags: ['productDocumentList']
        }),
        addDocument: build.mutation({
            query: ({name, recordingDocumentDTO}) => {
                return {
                    url: `/recording-documents?${serialize({name: name})}`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: recordingDocumentDTO
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled,"Added","Document")
            },
            invalidatesTags: ['productDocumentList']
        }),
    }),
});

export const {
    useUpdateDocumentMutation,
    useRemoveDocumentMutation,
    useAddDocumentMutation
} = recordingDocumentApi;