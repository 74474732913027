import {FC, useEffect, useState} from "react"
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {useSearchCustomersQuery} from "./customerApi";
import {
    createClickableLink,
    SearchSelectWithIndeterminateBoolean
} from "../../../components/common/datatable/DataTableUtils";
import {SearchInputWrapper} from "../../../components/common/datatable/filter/SearchInputWrapper";
import {SearchSelectWithEnum} from "../../../components/common/datatable/filter/SearchSelectWrapper";
import {formatValueAsDate} from "../../../helpers/utils/DateUtils";
import {getBooleanDisplayIcon} from "../../../helpers/utils/Utils";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {SearchDatePickerWrapper} from "../../../components/common/datatable/filter/SearchDatePickerWrapper";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import {FaPlus} from "react-icons/fa6";
import IconButton from "../../../components/common/form/IconButton";
import {useNavigate} from "react-router-dom";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";
import {useLazyExportCustomerDataQuery} from "./customerApi";

const Customers: FC = () => {

    const navigate = useNavigate()

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Customers", url: ""},

    ])

    useEffect(() => {
        document.title = "Customers";
    });

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("Customer");
    const {data: enums} = useGetEnumsQuery("");
    const [triggerExportCustomerDataQuery] = useLazyExportCustomerDataQuery();

    const columns: DataTableColumn[] = [
        {
            label: "Name", accessor: "customerName",
            cell: (props: any) => {
                let path = "/access-control/customers/" + props.row.original.id;
                return createClickableLink(path, props.row.original.customerName, userSettings, false, "", ["CUST-R", "CUST-V"]);
            },
            filterOptions: [{
                filterComponent: <SearchInputWrapper type={"text"} placeholder={"Enter Name..."}
                                                     name={"customerName"} accessor={"customerName"}/>,
            }],
        },
        {
            label: "Number", accessor: "customerNumber",
            filterOptions: [{
                filterComponent: <SearchInputWrapper
                    type='text'
                    placeholder='Enter customer number...'
                    accessor='customerNumber'
                    name='customerNumber'/>,
                overrideFilterLabel: "Customer No.",
            }],
        },
        {
            label: "Tenant",
            accessor: "tenantName",
            visibility: {
                disallowedCustomerLevels: ["CUSTOMER", "TENANT"]
            },
            canSort: false
        },

        {
            label: "Created Date", accessor: "creationDate",
            cell: (props: any) => {
                return formatValueAsDate(props.row.original.creationDate);
            },
            filterOptions: [
                {
                    filterComponent: <SearchDatePickerWrapper accessor={"createdDateFrom"}/>,
                    overrideFilterAccessor: "createdDateFrom",
                    overrideFilterLabel: "Created Date - From",
                },
                {
                    filterComponent: <SearchDatePickerWrapper accessor={"createdDateTo"}/>,
                    overrideFilterAccessor: "createdDateTo",
                    overrideFilterLabel: "Created Date - To",
                }],
        },

        {
            label: "Level", accessor: "customerLevel",
            cell: (props: any) => getEnumDisplayNameByConstant(enums?.["CustomerLevel"], props.value),
            filterOptions: [{
                filterComponent: SearchSelectWithEnum(enums?.["CustomerLevel"], "Select Customer Level...", false, "customerLevel"),
                overrideFilterLabel: "Customer Level",
            }],
        },
        {
            label: "Status", accessor: "enabled",
            cell: (props: any) => getBooleanDisplayIcon(props.row.original.enabled, "Enabled", "Disabled"),
            filterOptions: [{
                filterComponent: SearchSelectWithIndeterminateBoolean("Select Status...", "enabled", "Enabled", "Disabled"),
                overrideFilterLabel: "Enabled",
            }],
        }
    ]

    return (
        <div className="user-container px-5 py-5">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useSearchCustomersQuery,
                    exportAction: triggerExportCustomerDataQuery
                }}
                userSettingGroup={"Customer"}
                buttonOptions={{
                    addButton: {
                        button: <IconButton
                            icon={<FaPlus/>}
                            hasBackground={true}
                            onClick={() => {
                                navigate("/access-control/customers/create")
                            }}
                            title={"Create Customer"}
                        />, permissions: ["CUST-C"]
                    },
                }}
                basePermission={"CUST"}
            />

        </div>

    )
}
export default Customers