import {strApi} from "../../../app/strApi";
import {serialize} from "../../../helpers/utils/Utils";
import {getAuthorizationHeader, handleQueryError,  handleQueryErrorAndSuccess} from "../../../helpers/utils/RtkQueryUtils";

export const regionApi = strApi.injectEndpoints({
    endpoints: (build) => ({

        searchRegions: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/customer-regions/search?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['regions'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        getRegionById: build.query({
            query: (regionId) => {
                return {
                    url: `/customer-regions/${regionId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        createRegion: build.mutation({
            query: (requestBody) => {
                return {
                    url: `/customer-regions`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['regions'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await  handleQueryErrorAndSuccess(queryFulfilled,"Created","Region");
            },
        }),

        updateRegion: build.mutation({
            query: ({id, requestBody}) => {
                return {
                    url: `/customer-regions/${id}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['regions', 'regionById'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await  handleQueryErrorAndSuccess(queryFulfilled,"Updated","Region");
            },
        }),

        deleteRegionById: build.mutation({
            query: (id) => {
                return {
                    url: `/customer-regions/${id}`,
                    method: 'DELETE',
                    headers: getAuthorizationHeader()
                };
            },
            invalidatesTags: ['regions'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await  handleQueryErrorAndSuccess(queryFulfilled,"Deleted","Region");
            },
        }),

        regionByCustomerId: build.query({
            query: (customerId) => {
                // Serialize the data to query parameters
                let paramData = serialize({customerId: customerId});
                return {
                    url: `/customer-regions/by-customer?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
    })
})

export const {
    useSearchRegionsQuery,
    useGetRegionByIdQuery,
    useUpdateRegionMutation,
    useCreateRegionMutation,
    useDeleteRegionByIdMutation,
    useRegionByCustomerIdQuery
} = regionApi