import Modal from "../../../components/common/form/Modal";
import {ModalProps} from "../../../helpers/constants";
import React, {FC, useState} from "react";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {useSearchTeamsQuery} from "../../user/teams/teamsApi";
import Button from "../../../components/common/form/Button";
import {useUpdateAssignmentInfoMutation} from "../productsApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";

interface props extends ModalProps {
    tenantId: string,
    productId: string
}

const AssignProductToTeamModal: FC<props> = ({tenantId, productId, show, setShow
}) =>
{


    const [selectedId, setSelectedId] = useState<any>(null);
    const [updateAssignmentInfoApi, updateAssignmentInfoApiResponse] = useUpdateAssignmentInfoMutation();

    const handleRowSelection = (newSelectedRow: any, row: any) => {
        setSelectedId(row?.original?.id);
    };

    const columns = [
        {
            label: "Name",
            accessor: "teamName"
        }
    ]

    const handleSubmit = async () => {

        if (selectedId){
            await handleRTKQuery(() => {
                updateAssignmentInfoApi({productId: productId, assigneeId: selectedId, assigneeType: "TEAM",reason : "Manually assigned to team"});
            }, () => {
                setShow(false);
            });
        }
    }

    return (
        <>
            <Modal title={"Add to Team"} show={show} setShow={setShow} className={"w-3/5 mx-4"}
                   fields={
                       <>
                           <DataTable
                               columns={columns}
                               actions={{
                                   fetchAction: useSearchTeamsQuery
                               }}
                               defaultFilter={{
                                   tenantId: tenantId,
                               }}
                               renderOptions={{
                                   skipUserSettingRender: true
                               }}
                               rowSelectionOptions={{
                                   enableRowSelection: true,
                                   onSelectionChange: handleRowSelection,
                                   enableMultiRowSelection: false,
                                   rowIdAccessor: 'id'
                               }}
                               appendFilterToUrl={false}
                           ></DataTable>

                           <div className="flex justify-end gap-1 border-t border-surface-3 pt-4 mt-4">
                               <Button btnText={"Close"} isLoading={false} type={"close"}
                                       onClick={() => setShow(false)}/>
                               <Button btnText={"Assign"} isLoading={false}
                                       onClick={handleSubmit}
                                       isDisabled={updateAssignmentInfoApiResponse.isLoading || !selectedId}
                                       type={"submit"}></Button>
                           </div>
                       </>
                   }
            />

        </>
    )
}
export default AssignProductToTeamModal;