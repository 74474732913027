import {serialize} from "../../helpers/utils/Utils";
import {strApi} from "../../app/strApi";
import {getAuthorizationHeader, handleQueryError} from "../../helpers/utils/RtkQueryUtils";

export const simplifileApi = strApi.injectEndpoints({
    endpoints: (build) => ({
        getRecipientById: build.query({
            query: ({id, query}) => {
                return {
                    url: `/simplifile/recipients/${id}?${serialize(query)}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        getAuthenticatedLink: build.query({
            query: (query) => {
                return {
                    url: `/simplifile/authenticated-link?${serialize(query)}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            }
        }),

        getRecipientInstruments: build.query({
            query: ({recipientId, query}) => {
                return {
                    url: `/simplifile/${recipientId}/instruments?${serialize(query)}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ["recipientInstruments"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
        getRequirementsForInstrument: build.query({
            query: ({recipientId, instrumentName, tenantId}) => {
                return {
                    url: `/simplifile/${recipientId}/instrumentRequirements?instrumentName=${instrumentName}&tenantId=${tenantId}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ["requirementsForInstrument"],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),
    }),
});

export const {
    useGetRecipientByIdQuery,
    useLazyGetAuthenticatedLinkQuery,
    useGetRecipientInstrumentsQuery,
    useGetRequirementsForInstrumentQuery
} = simplifileApi;