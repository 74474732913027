import InputField from "../../../../components/common/form/InputField";
import React, {FC, useState} from "react";
import FincenMemberForm from "./FincenMemberForm";
import {IoIosArrowForward} from "react-icons/io";
import ModalButton from "../../../../components/common/form/ModalButton";
import {FaTrash} from "react-icons/fa6";
import ConfirmationModal from "../../../../components/layout/modal/ConfirmationModal";
import {isNullOrEmpty} from "../../../../helpers/utils/Utils";

interface props {
    title: string,
    item?: any,
    setItem?: any,
    setUnsavedPersonInfo: any
}

const FincenPartySection: FC<props> = ({title, item, setItem, setUnsavedPersonInfo}) => {

    const [showPackageDetail, setShowPackageDetail] = useState<boolean[]>(new Array(item?.contacts.length).fill(false));

    const [tempList, setTempList] = useState<any[]>(item?.contacts);
    const [originalList, setOriginalList] = useState<any[]>(item?.contacts);

    const toggleShowPackageDetail = (index: number) => {
        const updatedShowPackageDetail = [...showPackageDetail];
        updatedShowPackageDetail[index] = isNullOrEmpty(updatedShowPackageDetail[index]) ? true : !updatedShowPackageDetail[index];
        setShowPackageDetail(updatedShowPackageDetail);
    }

    React.useEffect(() => {
        if (item?.contacts) {
            setShowPackageDetail((prev) =>
                new Array(item.contacts.length).fill(false).map((_, i) => prev[i] ?? false)
            );
            setTempList(item?.contacts);
            setOriginalList(item?.contacts);
        }
    }, [item?.contacts]);


    const handleUndo = (index: number) => {
        // Create a new array with the updated item
        const updatedList = originalList.map((item, i) => (i === index ? originalList[i] : tempList[i]));

        // Update the state with the new array
        setTempList(updatedList);
        toggleShowPackageDetail(index);
    };

    return (
        <>
            <div className="col-span-12 mt-6 p-4 rounded-sm border border-surface-6 relative">
                <label className="absolute bg-surface-2 -top-3.5 pr-1 text-text-1">{title}</label>

                <InputField
                    required={true}
                    label='Legal Name' value={item?.name} name={"name"}
                    onChange={(e: any) => {
                        setItem((prevParty: any) => ({
                            ...prevParty,
                            name: e.target.value,
                        }))
                    }}
                />

                <FincenMemberForm
                    submitBtnText={"Add"}
                    handleSubmitAction={(e) => {
                        setItem((prevParty: any) => ({
                            ...prevParty,
                            contacts: [...(prevParty.contacts ?? []), e],
                        }));
                    }}
                    setUnsavedPersonInfo={setUnsavedPersonInfo}
                />

                {
                    tempList.map((curContact: any, index: number) => (
                        <>

                            <div className={`col-span-12 md:grid md:grid-cols-12 gap-3`}>
                                <div className="col-span-3"/>
                                <div className="bg-surface-2 pt-2 col-span-9 rounded-b-md">
                                    <div className="bg-highlight-2 justify-between flex px-2 py-1 cursor-pointer">
                                        <div className="flex gap-1 items-center">
                                            <h3 className="text-text-1 font-semibold">{curContact.name}</h3>
                                        </div>
                                        <div className="flex gap-3">
                                            <IoIosArrowForward
                                                className={"text-text-1 text-lg cursor-pointer " + (showPackageDetail[index] ? "rotate-90" : "")}
                                                onClick={() => {
                                                    toggleShowPackageDetail(index);
                                                }}
                                            />

                                            <ModalButton
                                                triggerButton={
                                                    <FaTrash
                                                        className={"cursor-pointer text-text-1 text-lg hover:text-text-hover-1"}
                                                        title={"Delete Person Info"}/>
                                                }
                                                onOpen={() => {
                                                }}
                                                modal={
                                                    <ConfirmationModal
                                                        show={true}
                                                        setShow={() => {
                                                        }}
                                                        title={"Delete Person Info"}
                                                        bodyMessage={"Are you sure you want to delete this person info?"}
                                                        onSubmit={() => {
                                                            setItem((prevState: any) => ({
                                                                ...prevState,
                                                                ["contacts"]: prevState["contacts"].filter((_: any, i: number) => i !== index)
                                                            }));
                                                        }}
                                                        isLoading={false}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    {showPackageDetail[index] &&

                                        <div className="col-span-9">

                                            <FincenMemberForm
                                                submitBtnText={"Update"}
                                                obj={curContact}
                                                handleSubmitAction={(e) => {
                                                    setItem((prevParty: any) => {
                                                        const updatedContacts = [...(prevParty.contacts ?? [])]; // Create a shallow copy of the contacts array
                                                        updatedContacts[index] = e;
                                                        return {
                                                            ...prevParty,
                                                            contacts: updatedContacts, // Update the contacts array
                                                        };
                                                    });
                                                }}
                                                undoAction={() => {
                                                    handleUndo(index)
                                                }}
                                                setUnsavedPersonInfo={setUnsavedPersonInfo}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    ))
                }
            </div>
        </>
    )
}
export default FincenPartySection;