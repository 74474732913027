import React, {ChangeEvent, FC, useState} from 'react';
import clsx from 'clsx'; // Import clsx for class concatenation

interface FormFieldProps {
    label?: string;
    value?: string;
    type?: string; // TODO: Change this to an enum of available types
    placeholder?: string;
    disabled?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    className?: string;
    required?: boolean;
    checked?: boolean;
    name?: string;
    labelPosition?: 'left' | 'right' | 'top';
    error?: any;
    icon?: React.ReactNode;
    hideLabel?: boolean
}

const InputField: FC<FormFieldProps> = ({
                                            label,
                                            value,
                                            type = 'text',
                                            placeholder = '',
                                            disabled = false,
                                            required = false,
                                            checked = false,
                                            onChange,
                                            onClick,
                                            className = '',
                                            name,
                                            labelPosition = 'left',
                                            error,
                                            icon,
                                            hideLabel = false
                                        }) => {
    const [showPassword, setShowPassword] = useState(false);

    // Function to render the input field
    const renderInputField = () => (
        <>
            <input
                type={showPassword && type === 'password' ? 'text' : type}
                step={type === 'number' ? 'any' : ''}
                checked={checked}
                placeholder={placeholder || `Enter ${label}...`}
                value={value}
                onChange={onChange}
                disabled={disabled}
                required={required}
                onClick={onClick}
                name={name}
                className={clsx(
                    'placeholder:text-text-2 rounded-md border p-2 text-text-1 bg-surface-2 ',
                    type === 'checkbox' || type === 'radio' ? 'm-2' : 'w-full',
                    labelPosition !== 'top' && 'mt-3',
                    disabled
                        ? 'cursor-not-allowed text-text-3'
                        : 'border-surface-4',
                    className // Allow overriding styles via className prop
                )}
            />
            {type === 'password' && value && (
                <i
                    className={clsx(
                        'fa cursor-pointer text-text-1 absolute left-auto right-5 top-6',
                        showPassword ? 'fa-eye' : 'fa-eye-slash'
                    )}
                    onClick={() => setShowPassword(!showPassword)}
                    aria-hidden="true"
                />
            )}
            {error && <div className="text-red-500 text-sm">{error}</div>}
        </>
    );

    // Function to render the label and optional icon
    const renderLabel = () => (
        <div className="flex items-center">
            {label && (
                <span className={'text-text-1'}>
                    {required ? `*${label}` : label}
                </span>
            )}
            {icon && <span className="ml-2">{icon}</span>}
        </div>
    );

    const baseClasses = 'col-span-12 grid grid-cols-12 gap-3';
    const labelClasses = 'col-span-3 sm:mt-2 lg:mt-0 mt-2 flex items-center';
    const inputClasses = `relative ${hideLabel ? 'col-span-12' : (type !== "radio" ? 'col-span-9' : 'col-span-1')}`;
    // Layout based on labelPosition and type
    const layout = () => {

        switch (labelPosition) {
            case 'left':
                return (
                    <div className={baseClasses}>
                        {!hideLabel && <div className={labelClasses}>{renderLabel()}</div>}
                        <div className={inputClasses}>{renderInputField()}</div>
                    </div>
                );
            case 'right':
                return (
                    <div className={baseClasses}>
                        {!hideLabel && <div className={inputClasses}>{renderInputField()}</div>}
                        <div className={labelClasses}>{renderLabel()}</div>
                    </div>
                );
            case 'top':
                return (
                    <div className="col-span-12">
                        {!hideLabel && <div>{renderLabel()}</div>}
                        <div>{renderInputField()}</div>
                    </div>
                );
            default:
                return renderInputField();
        }
    };

    return (
        <>
            {type !== 'toggle' && layout()}

            {type === 'toggle' && (
                <div className="col-span-12 grid grid-cols-12 gap-3 mt-3">
                    <div className={labelClasses}>{renderLabel()}</div>
                    <div className='col-span-9 mt-1'>
                        <div
                            onClick={onClick}
                            className={clsx(
                                'cursor-pointer relative toggle_class w-11 h-6',
                                checked
                                    ? 'bg-highlight-3'
                                    : 'bg-surface-4',
                                'rounded-full transition-all'
                            )}>
                            <div
                                className={clsx(
                                    'cursor-pointer relative toggle_class w-11 h-6 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white  after:absolute after:top-[2px] after:bg-white after:border-gray-300 after:border after:border-solid after:border-1 after:border-white after:rounded-full after:h-5 after:w-5 after:transition-all after:bg-white after:border-white',
                                    checked ? 'bg-highlight-3 after:end-[2px]' : 'bg-surface-4 after:start-[2px]'
                                )}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default InputField;
