import React from 'react';
import {Link} from 'react-router-dom';

export interface Crumb {
    url: string;
    content: string;
}

interface BreadcrumbProps {
    crumbs: Crumb[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({crumbs}) => {

    const isLast = (index: number): boolean => {
        return index === crumbs.length - 1;
    };

    return (
        <nav aria-label="breadcrumb">
            <ol className="flex gap-2">
                {
                    crumbs.map((crumb, i) => {
                        const disabled = isLast(i);
                        return (
                            <li key={i}
                                className={`breadcrumb-item ${disabled ? 'active-url text-text-4' : 'hover:text-highlight-7 text-text-2'}`}
                                aria-current={disabled ? 'page' : undefined}>
                                {
                                    disabled ?
                                        crumb.content
                                        :
                                        <Link to={crumb.url}>{crumb.content}</Link>
                                }
                            </li>
                        );
                    })
                }
            </ol>
        </nav>
    );
};

export default Breadcrumb;
