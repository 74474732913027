import React, {FC, FormEvent, useEffect, useState} from "react";
import Form from "../../../components/common/form/Form";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import Button from "../../../components/common/form/Button";
import Modal from "../../../components/common/form/Modal";
import {useGetProductByIdQuery, useUpdateProductStatusMutation} from "../productsApi";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import {useGetEnumsQuery} from "../../../app/globalApi";
import {skipToken} from "@reduxjs/toolkit/query";
import SelectField from "../../../components/common/form/SelectField";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";


interface UpdateProductStatusProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    productId?: string | null;
}

const UpdateProductStatusModal: FC<UpdateProductStatusProps> = ({productId, show, setShow}) => {
    const {data: enums} = useGetEnumsQuery("")
    const [productStatus, setProductStatus] = useState<any>("")
    const [updateProductStatus, {isLoading}] = useUpdateProductStatusMutation()

    const {
        data: productData,
    } = useGetProductByIdQuery(productId ?? skipToken);

    useEffect(() => {
        setProductStatus({
            value: productData?.status,
            label: getEnumDisplayNameByConstant(enums?.["ProductStatus"], productData?.status)
        })
    }, [productData]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await handleRTKQuery(
            async () => {
                return await updateProductStatus({productId: productId, productStatus: productStatus?.value}).unwrap();
            },
            () => {
                setShow(false);
                setProductStatus("");
            }
        );
    }

    const handleChange = (selectedOption: any) => {
        setProductStatus(selectedOption || "")
    };

    return (
        <>
            {productData &&
                <Modal title={"Product Status"} show={show} setShow={setShow} className={"w-3/5 mx-4"}
                       fields={
                           // @ts-ignore
                           <Form onSubmit={handleSubmit} fields={<>

                               <SelectField
                                   label="Product Status"
                                   value={productStatus || null}
                                   placeholder="Select Product Status..."
                                   options={createOptionListForSelectTag(enums?.["ProductStatus"], "displayName", "constant")}
                                   onChange={(selectedOption) => {
                                       handleChange(selectedOption)
                                   }}
                                   required={true}
                               />

                               <div className="flex justify-end gap-1 border-t border-surface-3 pt-4 mt-4">
                                   <Button btnText={"Close"} isLoading={false} type={"close"}
                                           onClick={() => setShow(false)}/>
                                   <Button btnText={"Submit"} isLoading={isLoading} type={"submit"}></Button>
                               </div>
                           </>
                           }>
                           </Form>

                       }/>
            }
        </>
    )
}

export default UpdateProductStatusModal;