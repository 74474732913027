import React, {useEffect, useMemo, useState} from "react";
import {LinkOpeningStyle, PageControl} from "../../../helpers/constants";
import Form from "../form/Form";
import {useFindUserSettingsByGroupNameQuery, useUpdateUserSettingsMutation} from "./userSettingsApi";
import Button from "../form/Button";
import {getDefaultUserSettingsForGroup} from "./UserSettingUtils";
import Modal from "../form/Modal";
import {DataTableColumn} from "../datatable/DataTableTypes";
import {UserSetting} from "./UserSettingTypes";
import _ from "lodash";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import SelectField from "../form/SelectField";
import {getEnumDisplayNameByConstant} from "../../../helpers/utils/EnumUtils";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import ButtonTray from "../form/ButtonTray";

interface UserSettingsModalProps {
    groupName: string;
    columns: DataTableColumn[];
    show: boolean;
    setShow: (show: boolean) => void;
}

const UserSettingsModal = (props: UserSettingsModalProps) => {

    const userSettingQuery = useFindUserSettingsByGroupNameQuery(props.groupName);
    const [updateUserSettings, {isLoading: mutationLoading}] = useUpdateUserSettingsMutation();
    const [applySubmit, setApplySubmit] = useState(false);

    const isLoading = useMemo(() => userSettingQuery.isLoading || mutationLoading,
        [userSettingQuery.isLoading, mutationLoading]);

    const [pendingUserSettings, setPendingUserSettings] = useState<UserSetting[]>([]);
    const [userSettings, setUserSettings] = useState<UserSetting[]>([]);

    const [pageControlSetting, setPageControlSetting] = useState<any>({});
    const [linkOpeningStyleSetting, setLinkOpeningStyleSetting] = useState<any>({});


    // TODO: Populate this
    const customerLevel = 'CUSTOMER';

    useEffect(() => {
        if (!userSettingQuery?.data) return;

        let data = userSettingQuery?.data;
        if (data.length === 0) {
            data = getDefaultUserSettingsForGroup(props.columns, customerLevel, props.groupName);
            setPendingUserSettings(data);

        } else {
            // Combine defaults with our current settings so that if we are missing settings (e.g. new column) then we add it to our settings.
            const combinedData = _.unionBy(data, getDefaultUserSettingsForGroup(props.columns, customerLevel, props.groupName), 'key');

            // Filter the data list based on the visibility of columns
            const filteredData = combinedData.filter((item: any) => {
                const column = props.columns.find((col: any) => col.label === item.key);
                return column ? column?.visibility?.canColumnShow : true;
            });

            if (combinedData.length !== filteredData.length) {
                // If we have new ones, perist them.
                setUserSettings(filteredData);
                setPendingUserSettings(filteredData);
            } else {
                setPendingUserSettings(combinedData);
            }
        }


    }, [userSettingQuery?.data]);

    useEffect(() => {
        // This may be a pain point, check back here if there are problems. The 'isSuccess' might make grids too heavily dependent on settings.
        if (!pendingUserSettings || pendingUserSettings.length === 0) return;

        let pageControl = pendingUserSettings.find(s => s.key === 'pageControl');
        let linkOpeningStyle = pendingUserSettings.find(s => s.key === 'linkOpeningStyle');

        setPageControlSetting(pageControl ? {
            value: pageControl?.value,
            label: getEnumDisplayNameByConstant(PageControl, pageControl?.value)
        } : null);

        setLinkOpeningStyleSetting(linkOpeningStyle ? {
            value: linkOpeningStyle?.value,
            label: getEnumDisplayNameByConstant(LinkOpeningStyle, linkOpeningStyle?.value)
        } : null);

    }, [pendingUserSettings, props.columns, props.groupName]);

    useEffect(() => {
        if (!userSettings || userSettings.length === 0) return;

        updateUserSettings(userSettings);
    }, [userSettings, updateUserSettings])

    useEffect(() => {
        if (!applySubmit) return;

        props.setShow(false);
        setApplySubmit(false);
    }, [applySubmit, mutationLoading]);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        setUserSettings(pendingUserSettings);
        setApplySubmit(true);
    }

    const handleChange = (e: any | { name: string; value: String | null }) => {
        const {name, checked, value} = "target" in e ? e.target : e;

        const tempSettings = [...pendingUserSettings].map(s => {
            if (s.key !== name) {
                return {...s};
            }

            if (checked === true) {
                return {...s, value: 'show'};
            } else if (checked === false) {
                return {...s, value: 'hide'};
            } else {
                return {...s, value: value};
            }
        });

        setPendingUserSettings(tempSettings);
    }

    return (
        <>
            <Modal title={'User Settings'}
                   show={props.show}
                   setShow={props.setShow}
                   className={"w-3/5 mx-4"}
                   fields={
                       <Form onSubmit={handleSubmit}
                             fields={<>
                                 <div className={"row"}>
                                     <div className={"lg:w-full md:w-1/2 mb-3  grid grid-cols-12"}>
                                         {pendingUserSettings?.filter(cur => ['show', 'hide'].includes(cur.value) && cur.key).map((cur, index) => {
                                             return <>
                                                 <div className="form-check col-span-6 mt-4">
                                                     <input type='checkbox'
                                                            id={index.toString()}
                                                            className="form-check-input"
                                                            name={cur.key}
                                                            onClick={handleChange}
                                                            defaultChecked={cur.value === 'show'}
                                                     />
                                                     <label
                                                         className="text-text-1 ml-2">{cur.key}
                                                     </label>
                                                 </div>
                                             </>
                                         })
                                         }
                                     </div>

                                     {Array.isArray(pendingUserSettings) && pendingUserSettings.length > 0 &&
                                         <>
                                             <SelectField
                                                 placeholder='Select Page Control...'
                                                 options={createOptionListForSelectTag(PageControl, "displayName", "constant")}
                                                 value={pageControlSetting || null}
                                                 onChange={(e) => {
                                                     handleChange({name: "pageControl", value: e?.value})
                                                 }}
                                                 required={true}
                                                 label={"Page Control"}
                                                 labelPosition={"top"}
                                             />

                                             <div className={"mt-4"}>
                                                 <SelectField
                                                     placeholder='Select Link Opening Style...'
                                                     options={createOptionListForSelectTag(LinkOpeningStyle, "displayName", "constant")}
                                                     value={linkOpeningStyleSetting || null}
                                                     onChange={(e) => {
                                                         handleChange({name: "linkOpeningStyle", value: e?.value})
                                                     }}
                                                     required={true}
                                                     label={"Link Opening Style"}
                                                     labelPosition={"top"}
                                                 />
                                             </div>

                                         </>
                                     }

                                 </div>

                                 <ButtonTray
                                     buttons={[
                                         {
                                             buttonProps: {
                                                 btnText: "Cancel",
                                                 onClick: () => props.setShow(false),
                                                 type: "close",
                                             },
                                             buttonType: "button"
                                         },
                                         {
                                             buttonProps: {
                                                 btnText: "Submit",
                                                 type: "submit",
                                                 isLoading: (isLoading),
                                                 isVisible:hasPermission(["UPRO-U"])
                                             },
                                             buttonType: "button",

                                         }
                                     ]}
                                     align={"end"}
                                     gap={2}
                                 />

                             </>}
                       />

                   }/>
        </>)

}

export default UserSettingsModal;