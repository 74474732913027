import React, {FC, useEffect, useMemo} from "react";
import {FaClock} from "react-icons/fa6";
import DashboardCardView from "../../components/common/DashboardCardView";
import MyWorkTab from "./myWork/MyWorkTab";
import TeamWorkTab from "./teamWork/TeamWorkTab";
import PendingTeamWorkTab from "./pendingTeamWork/PendingTeamWorkTab";
import {Tabs} from "react-simple-tabs-component";
import {useCheckIsUserLeaderOfAnyTeamQuery, useCheckIsUserMemberOfAnyTeamQuery} from "../user/users/usersApi";
import {FaCog, FaTag} from "react-icons/fa";
import {useFindOrderCountByStatusesQuery} from "../order/productsApi";
import {hasPermission} from "../../helpers/utils/AccessControlUtils";

const STATUSES = ['PREPARING', 'IN_PROGRESS', 'READY_TO_RECORD', 'COMPLETED', 'FOLLOW_UP','REJECTED'];

const Home: FC = () => {

    const isUserLeader = useCheckIsUserLeaderOfAnyTeamQuery("");
    const isUserMember = useCheckIsUserMemberOfAnyTeamQuery("");
    const cardData = useFindOrderCountByStatusesQuery("");

    useEffect(() => {
        document.title = "Home";
    });

    const tabs = useMemo(() => {
        const result: any[] = [];

        if (isUserMember?.data === true) {
            result.push({
                label: 'My Work',
                Component: MyWorkTab
            });
        }

        if (isUserLeader?.data === true) {
            result.push({
                label: 'Team Members Work',
                Component: TeamWorkTab
            });
        }

        if (isUserMember?.data === true) {
            result.push({
                label: 'Pending Team Work',
                Component: PendingTeamWorkTab
            });
        }

        return result;
    }, [isUserLeader?.data, isUserMember?.data]);

    return (
        <>
            <div className="grid lg:grid-cols-4 sm:grid-cols-1 md:grid-col-2 gap-4 mt-8 ms-4 me-5">

                <DashboardCardView label='Active Orders' icon={FaCog} data={cardData?.data?.activeOrderCount}
                                   filterQuery={
                                       {
                                           path: "/orders",
                                           query: {"statuses": STATUSES}
                                       }
                                   }
                                   isAllowedClick={hasPermission(["ORDR-R", "ORDR-V"], true)}
                                   isLoading={cardData?.isLoading}
                />

                <DashboardCardView label='Rush Orders' icon={FaClock} data={cardData?.data?.isRushOrderCount}
                                   filterQuery={
                                       {
                                           path: "/orders",
                                           query: {
                                               "statuses": STATUSES,
                                               "rush": "true"
                                           }
                                       }
                                   }
                                   isAllowedClick={hasPermission(["ORDR-R", "ORDR-V"], true)}
                                   isLoading={cardData?.isLoading}
                />
                <DashboardCardView label='Follow-up Orders' icon={FaTag} data={cardData?.data?.followUpOrderCount}
                                   filterQuery={
                                       {
                                           path: "/orders",
                                           query: {
                                               "statuses": STATUSES,
                                               "followUpTypes": ["any"]
                                           }
                                       }
                                   }
                                   isAllowedClick={hasPermission(["ORDR-R", "ORDR-V"], true)}
                                   isLoading={cardData?.isLoading}
                />
            </div>

            {tabs.length > 0 &&
                <div className="px-4 py-2">
                    <Tabs tabs={tabs} className="bootstrap-tabs-component mt-3"/>
                </div>
            }
        </>
    )
}

export default Home;