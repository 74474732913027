import React, {useEffect, useState} from "react";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {useLazyExportInvoiceLineDataQuery, useSearchInvoiceLinesQuery} from "./CannedInvoiceLinesApi";
import {useGetAllTenantsQuery} from "../../user/customer/tenantApi";
import {SearchSelectWithList} from "../../../components/common/datatable/filter/SearchSelectWrapper";
import {SearchInputWrapper} from "../../../components/common/datatable/filter/SearchInputWrapper";
import IconButton from "../../../components/common/form/IconButton";
import {FaPlus} from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import {Routes as AppRoutes} from "../../../routes";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";

const CannedInvoiceLines = () => {

    const tenantList = useGetAllTenantsQuery("");
    const navigate = useNavigate()

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Canned Invoice Lines", url: ""},

    ])

    useEffect(() => {
        document.title = "Canned File Names";
    });

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("Canned Invoice Line");
    const [triggerExportCannedInvoiceLineDataQuery] = useLazyExportInvoiceLineDataQuery();

    const columns: DataTableColumn[] = [
        {
            label: "Label",
            accessor: "label",
            cell: (props: any) => {
                let path = `/canned-invoice-lines/${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["CANIL-R", "CANNOTE-V"]);
            },
            filterOptions: [{
                filterComponent: <SearchInputWrapper type={"text"} placeholder={"Enter Label..."} name={"label"}
                                                     accessor={"label"}/>,
            }],
        },
        {
            label: "Tenant", accessor: "tenantName",
            visibility: {
                disallowedCustomerLevels: ["CUSTOMER", "TENANT"]
            },
            canSort: false,
            filterOptions: [{
                filterComponent: tenantList?.data ? SearchSelectWithList(tenantList?.data, (tenant: any) => `${tenant.name} `, "id", "Select Tenant...", false, "tenantId") : [],
                overrideFilterAccessor: "tenantId"
            }],
        },
        {
            label: "Amount",
            accessor: "amount",
        }
    ]

    return (

        <div className="user-container px-5 py-5">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useSearchInvoiceLinesQuery,
                    exportAction: triggerExportCannedInvoiceLineDataQuery
                }}
                buttonOptions={{
                    addButton: {
                        button: <IconButton
                            icon={<FaPlus/>}
                            hasBackground={true}
                            onClick={() => {
                                navigate(AppRoutes.CreateCannedInvoiceLine.path)
                            }}
                            title={"Create Canned Invoice Line"}
                        />,
                        permissions: ["CANIL-C"]
                    },
                }}
                userSettingGroup={"Canned Invoice Line"}
                basePermission={"CANIL"}
            />

        </div>

    )
}
export default CannedInvoiceLines;