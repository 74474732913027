import React, {useState, ReactNode} from 'react';

export interface ModalButtonProps {
    modal: ReactNode;
    triggerButton: ReactNode;
    onOpen?: () => void;
}

const ModalButton: React.FC<ModalButtonProps> = ({modal, triggerButton, onOpen}) => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        if (onOpen) {
            onOpen();
        }

        setShowModal(true);
    };

    return (
        <>
                {React.cloneElement(triggerButton as React.ReactElement<any>, {onClick: handleOpenModal})}
                {showModal && React.cloneElement(modal as React.ReactElement<any>, {show: showModal, setShow: setShowModal})}
        </>
    );
};

export default ModalButton;
