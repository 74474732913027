import React, {ChangeEvent, useEffect, useState} from "react";
import {EnumOption} from "../../../../enums/common";
import {createOptionListForSelectTag} from "../../../../helpers/utils/Utils";
import InputField from "../../../../components/common/form/InputField";
import SelectField from "../../../../components/common/form/SelectField";


// Define types for the props of PersonForm
interface PersonFormProps {
    enums: { PersonType: EnumOption[] };
    handleSubmitAction: (personObj: any) => void;
    undoAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    submitBtnText: string;
    obj: any;
    requirementsForInstrument: any[];
    item: string;
    setUnsavedPersonInfo: any
}

// Reusable PersonForm component
export const PersonInfoForm: React.FC<PersonFormProps> = ({
                                                              enums,
                                                              submitBtnText,
                                                              handleSubmitAction,
                                                              undoAction,
                                                              obj,
                                                              requirementsForInstrument,
                                                              item,
                                                              setUnsavedPersonInfo
                                                          }) => {

    const [personObj, setPersonObj] = useState<any>(obj);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isInitialChange, setIsInitialChange] = useState(true);

    useEffect(() => {
        setPersonObj(obj);
    }, [obj]);


    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: any }) => {
        const { name, value } = "target" in e ? e.target : e;

        const updatePersonObj = () => {
            setPersonObj((prev: any) => ({
                ...prev,
                [name]: value,
            }));
        };

        if (isInitialChange) {
            setIsInitialChange(false);
            setTimeout(updatePersonObj, 20);
        } else {
            updatePersonObj();
        }

        setIsChanged(true);
        setUnsavedPersonInfo(true);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!personObj?.type) {
            personObj.type = {"label": "Individual", "value": "INDIVIDUAL"};
        }
        handleSubmitAction(personObj);
        setPersonObj(obj);
        setIsChanged(false);
        setUnsavedPersonInfo(false);
    }

    const isRequired = (path: string): boolean => {

        if (requirementsForInstrument === undefined)
            return false;

        const requirement = requirementsForInstrument?.find(el => el.path === path);
        if (!requirement) return false;

        if (requirement.conditionals?.length) {
            for (const conditional of requirement.conditionals) {
                const attribute = conditional.path?.split('.')[1];
                if (personObj[attribute]?.value.toLowerCase() === conditional.value.toLowerCase() &&
                    (conditional.required === "ALWAYS")) {
                    return true;
                }
            }
            return false;
        }

        return requirement.required === "ALWAYS";
    };



    return (
        <form onSubmit={handleSubmit}
              className={`border border-surface-6 p-3 ${submitBtnText === "Add" ? "rounded-md" : "rounded-b-md"} bg-surface-2`}>
            <div className="grid grid-cols-12 gap-2">
                <SelectField
                    label="Person Type"
                    value={personObj?.type}
                    placeholder="Select Name..."
                    options={createOptionListForSelectTag(enums?.PersonType, "displayName", "constant")}
                    onChange={(e : any) => {
                        handleOnChange({name: "type", value: e})
                    }}
                    required={item === "grantor" ? isRequired("grantors[].type") : isRequired("grantees[].type")}
                    labelPosition={"top"}
                />
            </div>
            {personObj?.type?.value !== "ORGANIZATION" && (
                <div className="grid grid-cols-12 gap-2 mt-3">
                    <InputField
                        label="First Name"
                        value={personObj?.firstName || ""}
                        type="text"
                        placeholder="Enter First Name..."
                        onChange={handleOnChange}
                        name={"firstName"}
                        required={item === "grantor" ? isRequired("grantors[].firstName") : isRequired("grantees[].firstName")}
                        labelPosition={"top"}
                    />
                    <InputField
                        label="Middle Name"
                        value={personObj?.middleName || ""}
                        type="text"
                        placeholder="Enter Middle Name..."
                        onChange={handleOnChange}
                        name={"middleName"}
                        required={item === "grantor" ? isRequired("grantors[].middleName") : isRequired("grantees[].middleName")}
                        labelPosition={"top"}
                    />
                    <InputField
                        label="Last Name"
                        value={personObj?.lastName || ""}
                        type="text"
                        placeholder="Enter Last Name..."
                        onChange={handleOnChange}
                        name={"lastName"}
                        required={item === "grantor" ? isRequired("grantors[].lastName") : isRequired("grantees[].lastName")}
                        labelPosition={"top"}
                    />
                </div>
            )}
            {personObj?.type?.value === "ORGANIZATION" && (
                <div className="grid grid-cols-12 gap-2 mt-3">
                    <InputField
                        label="Business Name"
                        value={personObj?.nameUnparsed || ""}
                        type="text"
                        placeholder="Enter Business Name..."
                        onChange={handleOnChange}
                        name={"nameUnparsed"}
                        required={item === "grantor" ? isRequired("grantors[].nameUnparsed") : isRequired("grantees[].nameUnparsed")}
                        labelPosition={"top"}
                    />
                </div>
            )}
            {isChanged && (
                <div className="grid grid-cols-12 gap-2 mt-2">
                    <div className="col-span-12 flex items-end justify-end">
                        <button
                            type="button"
                            className="bg-surface-3 rounded-md text-text-1 text-xs px-2 py-1 mr-3 hover:bg-highlight-7"
                            onClick={(e) => {
                                if (submitBtnText === "Add") {
                                    setPersonObj({});
                                } else {
                                    undoAction && undoAction(e);
                                }
                                setIsChanged(false);
                                setUnsavedPersonInfo(false);
                            }}>
                            Undo
                        </button>
                        <button
                            type="submit"
                            className="bg-highlight-2 rounded-md text-text-1 text-xs px-2 py-1"
                        >
                            {submitBtnText}
                        </button>
                    </div>
                </div>
            )}
        </form>
    );
};
