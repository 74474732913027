import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "./form/Button";

export default function ErrorPage({ statusCode, message, onClose }) {

    const defaultMessages = {
        404: 'Page Not Found',
        500: 'Internal Server Error',
        403: 'Access Denied',
        401: 'Unauthorized',
        default: 'Something went wrong',
    };

    const displayMessage = message || defaultMessages[statusCode] || defaultMessages.default;

    const navigate = useNavigate();

    return (
        <div className="error_wrapper text-center flex items-center justify-center h-screen text-text-5">
            <div>
                <h2 className="text-center text-text-5 text-3xl">{statusCode || 'Unknown'}</h2>
                <p className="mb-5 mt-2">{displayMessage}</p>
                <Button btnText={"Back To Home"} isLoading={false} type={"button"}
                        onClick={() => {
                            if (onClose) onClose(); // Call onClose when button is clicked
                            navigate("/home");
                        }}
                />
            </div>
        </div>
    );
}
