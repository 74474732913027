import {useParams} from "react-router-dom";
import {useGetCustomerByIdQuery} from "./customerApi";
import React, {useEffect, useMemo, useState} from "react";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import SettingTab from "./setting/SettingTab";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {Tabs} from "react-simple-tabs-component";
import AuditableInfoButton from "../../../components/common/form/AuditableInfoButton";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";

const CustomerDetailPage = () => {
    const {customerId} = useParams();
    const [crumbs, setCrumbs] = useState<any[]>([]);

    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getCustomerById, {customerId}, {
        redirectToErrorPage: true,
        skip: !customerId
    });

    const customerQuery = useGetCustomerByIdQuery(customerId);

    useEffect(() => {
        const customerObj = customerQuery?.data;
        if (customerObj) {
            setCrumbs([
                {content: "Home", url: "/home"},
                {content: "Customers", url: "/access-control/customers"},
                {content: customerObj?.customerName, url: ""}
            ]);

            document.title = `${customerObj?.customerName}`;
        }
    }, [customerQuery?.data]);

    // Compute tabs using useMemo
    const tabs = useMemo(() => {
        const result: any[] = [];
        if (hasPermission(["CUST-R"])) {
            result.push({
                label: 'Settings',
                Component: SettingTab
            });
        }
        return result;
    }, []);



    return (
        <div className="px-4 py-2">
            <div className={"justify-between sm:block md:flex lg:flex"}>
                <Breadcrumb crumbs={crumbs}/>
                <AuditableInfoButton obj={customerQuery?.data}/>

            </div>

            <Tabs tabs={tabs} className="bootstrap-tabs-component mt-3"/>
        </div>
    );
}

export default CustomerDetailPage;
