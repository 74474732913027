export const downloadFile = (blob: Blob | MediaSource, filename: string) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
};

export const openFileInNewTab = (blob: Blob | MediaSource) => {
    const url = window.URL.createObjectURL(blob)
    window.open(url)
    // Optionally, revoke the object URL after some time to free up resources
    setTimeout(() => window.URL.revokeObjectURL(url), 100);
};

export const openFileInFrame = (blob: Blob | MediaSource) => {
    const url = window.URL.createObjectURL(blob);
    const iframe = document.querySelector("iframe");
    if (iframe?.src) iframe.src = url;
    // Optionally, revoke the object URL after some time
    setTimeout(() => URL.revokeObjectURL(url), 100);
}