import {useParams} from "react-router-dom";
import {useGetEnumsQuery} from "../../../../app/globalApi";
import {DataTable} from "../../../../components/common/datatable/DataTable";
import {hasPermission} from "../../../../helpers/utils/AccessControlUtils";
import {getEnumDisplayNameByConstant} from "../../../../helpers/utils/EnumUtils";
import {useDeleteTeamRuleMutation, useTeamRulesQuery} from "../teamsApi";
import ModalButton from "../../../../components/common/form/ModalButton";
import {FaPlus, FaTrash} from "react-icons/fa6";
import ConfirmationModal from "../../../../components/layout/modal/ConfirmationModal";
import React, {FormEvent, useState} from "react";
import AddUpdateRule from "../modal/AddUpdateRule";
import {DataTableColumn} from "../../../../components/common/datatable/DataTableTypes";

const RuleTab = () => {

    let {teamId} = useParams();
    const {data: enums} = useGetEnumsQuery("");
    const [selectedRuleId, setSelectedRuleId] = useState("");
    const [deleteRuleApi, {isLoading: deleteRuleApiResponse}] = useDeleteTeamRuleMutation();

    const handleDeleteRuleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (selectedRuleId) {
            return deleteRuleApi(selectedRuleId);
        }
    }

    const columns: DataTableColumn[] = [
        {
            label: "Priority", accessor: "weight",
            cell: (props: any) => {
                if (hasPermission(["TEAM-U"])) {
                    return <>
                        <ModalButton
                            triggerButton={<span
                                className={"text-highlight-6 hover:text-highlight-7 cursor-pointer"}>{props.row.original.weight}</span>}
                            onOpen={() => {
                            }}
                            modal={
                                <AddUpdateRule
                                    show={true}
                                    setShow={() => {
                                    }}
                                    teamRuleId={props.row.original.id}
                                    title={"Update Team Rule"}
                                    btnText={"Update"}
                                />
                            }
                        />
                    </>
                } else {
                    return <>
                        <span>{props.row.original.weight}</span>
                    </>
                }
            },
        },
        {
            label: "Product Types", accessor: "ruleQueryParameters.productTypes",
            cell: (props: any) => {
                let content = props.row.original.ruleQueryParameters?.productTypes?.map((cur: any) => {
                    return getEnumDisplayNameByConstant(enums?.["ProductType"], cur);
                }).join(", ");

                return <><span className="text_wrap_msa_grid" title={content}>{content}</span></>
            }, canSort: false,
        },
        {
            label: "Product Statuses", accessor: "ruleQueryParameters.productStatuses", cell: (props: any) => {
                let content = props.row.original.ruleQueryParameters?.productStatuses?.map((cur: any) => {
                    return getEnumDisplayNameByConstant(enums?.["ProductStatus"], cur);
                }).join(", ");
                return <><span className="text_wrap_msa_grid" title={content}>{content}</span></>
            }, canSort: false,
        },
        {
            label: "State", accessor: "stateName", canSort: false,
        },
        {
            label: "County", accessor: "countyName", canSort: false,
        },
        {
            label: "Metro Area", accessor: "metroAreaName", canSort: false,
        },
        {
            label: "Customers", accessor: "customers", canSort: false,
            cell: (props: any) => {
                let content = props.row.original.customers.map((curCustomer: any) => {
                    return curCustomer.customerName;
                }).join(", ");
                return <><span className="text_wrap_msa_grid" title={content}>{content}</span></>
            },
        },
        {
            label: "Branches", accessor: "branches", cell: (props: any) => {
                let content = props.row.original.branches.map((curCustomer: any) => {
                    return curCustomer.name;
                }).join(", ");
                return <><span className="text_wrap_msa_grid" title={content}>{content}</span></>
            }, canSort: false,
        },
        {
            label: "Action",
            visibility: {
                canColumnShow: hasPermission(["TEAM-U"]),
                showByDefault: hasPermission(["TEAM-U"]),
                hasShowSetting: false,
            },
            accessor: "creationDate",
            canSort: false,
            cell: (props: any) => {
                return <>
                    <div className="ml-4">
                        <ModalButton
                            triggerButton={
                                <FaTrash
                                    className={"cursor-pointer text-highlight-3 text-lg hover:text-highlight-7"}
                                    title={"Delete Team Rule"}/>
                            }
                            onOpen={() => {
                                setSelectedRuleId(props.row.original.id)
                            }}
                            modal={
                                <ConfirmationModal
                                    show={true}
                                    setShow={() => {
                                    }}
                                    title={"Delete Team Rule"}
                                    bodyMessage={`Are you sure you want to delete this Team Rule ?`}
                                    onSubmit={handleDeleteRuleSubmit}
                                    isLoading={deleteRuleApiResponse}
                                />
                            }
                        />
                    </div>
                </>
            }
        },

    ]


    return (
        <>
            {hasPermission(["TEAM-U"]) &&
                <div className={"flex justify-end"}>
                    <ModalButton
                        triggerButton={
                            <div
                                className="h-7 w-10 text-text-1 rounded-md bg-highlight-3 flex justify-center items-center cursor-pointer hover:bg-highlight-7 absolute top-0 left-auto">
                                <FaPlus/>
                            </div>
                        }
                        onOpen={() => {
                        }}
                        modal={
                            <AddUpdateRule
                                show={true}
                                setShow={() => {
                                }}
                                title={"Create Team Rule"}
                                btnText={"Create"}
                            />
                        }
                    />
                </div>
            }

            <div className='-mt-4'>
                <DataTable
                    columns={columns}
                    actions={{
                        fetchAction: useTeamRulesQuery
                    }}
                    defaultFilter={{
                        teamId: teamId
                    }}
                    userSettingGroup={"Team Rule"}
                    appendFilterToUrl={false}
                />
            </div>
        </>
    )
}
export default RuleTab;