import RoleForm from "./RoleForm";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetRoleByIdQuery} from "./rolesApi";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";

const UpdateRole = () => {

    let {roleId} = useParams();
    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getRoleById, {roleId}, {
        redirectToErrorPage: true,
        skip: !roleId
    });
    const [crumbs, setCrumbs] = useState<any[]>([]);

    const roleByIdApi = useGetRoleByIdQuery(roleId ?? skipToken);

    useEffect(() => {
        let roleObj = roleByIdApi?.data;
        if (roleObj) {
            setCrumbs([
                {content: "Home", url: "/home"},
                {content: "Roles", url: "/access-control/roles"},
                {content: roleObj?.name, url: ""}
            ]);

            document.title = `${roleObj?.name}`;
        }
    }, [roleByIdApi]);


    return (
        <>
            <RoleForm crumbs={crumbs} title={"Update Role"} btnText={"Update"} isEditable={hasPermission(["ROL-U"])}/>

        </>
    )
}
export default UpdateRole;