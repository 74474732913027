import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";
import {
    useCreateSimplifileConfigMutation,
    useGetSimplifileConfigByIdQuery,
    useUpdateSimplifileConfigByIdMutation
} from "./simplifileConfigsApi";
import Breadcrumb, {Crumb} from "../../../components/common/Breadcrumbs";
import AuditableInfoButton from "../../../components/common/form/AuditableInfoButton";
import Form from "../../../components/common/form/Form";
import {Routes as AppRoutes} from "../../../routes";
import {jwtDecode} from "../../../helpers/utils/AccessControlUtils";
import ButtonTray from "../../../components/common/form/ButtonTray";
import InputField from "../../../components/common/form/InputField";
import SelectField from "../../../components/common/form/SelectField";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import {useGetAllTenantsQuery} from "../../user/customer/tenantApi";
import {OptionType} from "../../../helpers/utils/StyleUtils";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import {skipToken} from "@reduxjs/toolkit/query";

interface Props {
    crumbs: Crumb[];
    title: string,
    btnText: string,
    isEditable?: any
}

const SimplifileConfigForm: React.FC<Props> = ({crumbs, title, btnText, isEditable = true}) => {

    const navigate = useNavigate();
    let {configId} = useParams();
    const tenantApiResponse = useGetAllTenantsQuery("");

    const simplifileConfigByIdApi = useGetSimplifileConfigByIdQuery(configId ?? skipToken);
    const [updateSimplifileConfig, updateSimplifileConfigApiResponse] = useUpdateSimplifileConfigByIdMutation();
    const [createSimplifileConfig, createSimplifileConfigApiResponse] = useCreateSimplifileConfigMutation();
    const [configData, setConfigData] = useState<any>({});


    useEffect(() => {
        let obj = simplifileConfigByIdApi?.data;
        if (obj) {
            setConfigData({
                ...obj,
                tenantId: obj?.tenantId ? {
                    label: tenantApiResponse?.data.filter((el: any) => el.id === obj?.tenantId)?.[0].name,
                    value: obj?.tenantId
                } : null
            })
        }
    }, [simplifileConfigByIdApi, tenantApiResponse?.data]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const commonObj = {
            ...configData,
            tenantId: configData?.tenantId?.value
        };

        const createOrUpdateCannedFileName = async () => {
            if (btnText === "Create") {
                await createSimplifileConfig(commonObj).unwrap();
            } else {
                await updateSimplifileConfig({
                    id: configId,
                    requestBody: commonObj,
                }).unwrap();
            }
        };

        await handleRTKQuery(createOrUpdateCannedFileName, () => {
            setConfigData({});
            navigate(AppRoutes.SimplifileConfig.path);
        });
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;

        // Update the state with the new value
        setConfigData((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    }

    return (
        <>
            <div className="px-4 py-2">

                <div className="justify-between sm:block md:flex lg:flex">
                    <Breadcrumb crumbs={crumbs}/>
                    <AuditableInfoButton obj={simplifileConfigByIdApi?.data}/>
                </div>

                <div className="flex justify-center mt-3">
                    <div className="bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">{title}</h3>
                        <Form onSubmit={handleSubmit} fields={
                            <>
                                <div className="grid-cols-12 mt-6 sm:block md:grid lg:grid">

                                    <InputField
                                        name={"submitterId"}
                                        label="Submitter Id"
                                        onChange={handleOnChange}
                                        value={configData?.submitterId || ""}
                                        type="text"
                                        placeholder="Enter Submitter Id..."
                                        required={true}
                                    />

                                    {jwtDecode()?.customerLevel === "SYS" && <SelectField
                                        placeholder='Select Tenant...'
                                        value={configData?.tenantId || null}
                                        options={createOptionListForSelectTag(tenantApiResponse?.data, "name", "id")}
                                        onChange={(selectedOption) => {
                                            handleOnChange({name: "tenantId", value: selectedOption})
                                        }}
                                        isLoading={tenantApiResponse.isLoading}
                                        label={"Tenant"}
                                        required={true}
                                    />}

                                    <InputField
                                        name={"baseUrl"}
                                        label="Base Url"
                                        onChange={handleOnChange}
                                        value={configData?.baseUrl || ""}
                                        type="text"
                                        placeholder="Enter Base Url..."
                                        required={true}
                                    />

                                    <InputField
                                        name={"apiToken"}
                                        label="Api Token"
                                        onChange={handleOnChange}
                                        onClick={() => {
                                            setConfigData((prev: any) => ({
                                                ...prev,
                                                apiToken: "", // Clear the token immutably
                                            }))
                                        }}
                                        value={configData?.apiToken || ""}
                                        type="text"
                                        placeholder="Enter Api Token..."
                                        required={true}
                                    />

                                </div>

                                <ButtonTray
                                    buttons={[
                                        {
                                            buttonProps: {
                                                btnText: "Cancel",
                                                onClick: () => navigate(AppRoutes.SimplifileConfig.path),
                                                type: "close",
                                            },
                                            buttonType: "button"
                                        },
                                        {
                                            buttonProps: {
                                                btnText: btnText,
                                                type: "submit",
                                                isLoading: (updateSimplifileConfigApiResponse.isLoading || createSimplifileConfigApiResponse.isLoading),
                                                isVisible: isEditable
                                            },
                                            buttonType: "button",

                                        }
                                    ]}
                                    align={"end"}
                                    gap={2}
                                />

                            </>
                        }/>
                    </div>
                </div>
            </div>

        </>
    )
}
export default SimplifileConfigForm;