import {FC, useEffect, useState} from "react";
import Breadcrumb from "../../../components/common/Breadcrumbs";
import OrderDetailsTab from "./OrderDetailsTab";
import FinishingTab from "./FinishingTabs";
import "../order.css";
import RecordingDetailTab from "./recording/RecordingDetailTab";
import FincenDetailTab from "./fincen/FincenDetailTab";

const CreateOrder: FC = () => {

    interface TabProps {
        isActive: boolean;
        label: string;
    }

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "Orders", url: "/orders"},
        {content: "Create Order", url: "/orders/create"},
    ]);

    const [tab, setTab] = useState(1);
    const [createOrderData, setCreateOrderData] = useState<any>({});

    const [buyer, setBuyer] = useState<any>({
        "type": "BUYER",
        "name": "",
        "contacts": []
    });

    const [seller, setSeller] = useState<any>({
        "type": "SELLER",
        "name": "",
        "contacts": []
    });


    useEffect(() => {
        document.title = "Create Orders";
    });

    const Tab: FC<TabProps> = ({isActive, label}) => (
        <div
            className={`col-span-4 order_tab_outer sm:mt-3 md:mt-0 mt-3 ${isActive ? "bg-highlight-3 active_tab" : "inactive_tab"}`}>
            <h3 className="text-text-1 py-2 px-6 relative">{label}</h3>
        </div>
    );

    return (
        <div className="px-5 py-5">
            <Breadcrumb crumbs={crumbs}/>

            <div className="flex justify-center mt-3">
                <div className="bg-surface-2 md:w-3/4 sm:w-full p-5 rounded-lg">
                    <div className="gap-4 grid-cols-12 sm:block md:grid pb-4 tab_container">
                        <Tab isActive={tab === 1} label="Add Order"/>
                        <Tab isActive={tab === 2} label="Add Product Details"/>
                        <Tab isActive={tab === 3} label="Finishing Up"/>
                    </div>
                    {/* Order tab */}
                    {
                        tab === 1 && <OrderDetailsTab setTab={setTab} createOrderData={createOrderData}
                                                      setCreateOrderData={setCreateOrderData}/>
                    }
                    {/* Package tab */}
                    {
                        tab === 2 && createOrderData?.productType.value === 'RECORDING' &&
                        <RecordingDetailTab setTab={setTab}
                                            createOrderData={createOrderData}
                                            setCreateOrderData={setCreateOrderData}/>
                    }
                    {
                        tab === 2 && createOrderData?.productType.value === 'FINCEN' &&
                        <FincenDetailTab setTab={setTab}
                                         createOrderData={createOrderData}
                                         setCreateOrderData={setCreateOrderData}
                                         buyer={buyer}
                                         setBuyer={setBuyer}
                                         seller={seller}
                                         setSeller={setSeller}
                        />
                    }
                    {/* Finishing tab */}
                    {
                        tab === 3 && <FinishingTab setTab={setTab} createOrderData={createOrderData}
                                                   setCreateOrderData={setCreateOrderData}/>
                    }
                </div>
            </div>
        </div>
    );
};

export default CreateOrder;
