import CannedInvoiceLineForm from "./CannedInvoiceLineForm";
import {useGetInvoiceLineByIdQuery} from "./CannedInvoiceLinesApi";
import {skipToken} from "@reduxjs/toolkit/query";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";

const UpdateCannedInvoiceLine = () => {

    const {cannedInvoiceLineId} = useParams();
    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getInvoiceLineById, {cannedInvoiceLineId}, {
        redirectToErrorPage: true,
        skip: !cannedInvoiceLineId
    });
    const InvoiceLineByIdApi = useGetInvoiceLineByIdQuery(cannedInvoiceLineId);
    const [crumbs, setCrumbs] = useState<any[]>([]);

    useEffect(() => {
        let obj = InvoiceLineByIdApi?.data;
        if (obj) {
            setCrumbs([
                {content: "Home", url: "/home"},
                {content: "Canned Invoice Lines", url: "/canned-invoice-lines"},
                {content: obj?.label, url: ""}
            ]);

            document.title = `${obj?.label}`;
        }
    }, [InvoiceLineByIdApi]);

    return (
        <>
            <CannedInvoiceLineForm title={"Update Canned Invoice Line"} btnText={"Update"}
                                   crumbs={crumbs} isEditable={hasPermission(["CANIL-U"])}/>
        </>
    )
}
export default UpdateCannedInvoiceLine;