import {ModalProps} from "../../helpers/constants";
import React, {FC} from "react";
import Modal from "./form/Modal";
import InputField from "./form/InputField";
import {formatValueAsDate} from "../../helpers/utils/DateUtils";
import {useGetUserAuditableInfoQuery} from "../../features/user/users/usersApi";

interface props extends ModalProps {
    creationDate: string,
    createdBy: string,
    modifiedDate: string,
    modifiedBy: string
}

const AuditableInfoModal: FC<props> = ({show, setShow, creationDate, createdBy, modifiedDate, modifiedBy}) => {

    const auditableInfoApi = useGetUserAuditableInfoQuery({
        createdBy: createdBy,
        modifiedBy: modifiedBy
    });


    return (
        <>
            <Modal
                title={"Auditable Info"}
                show={show}
                setShow={setShow}
                className={"w-3/5 mx-4 "}
                fields={
                    <>
                        <InputField
                            label="Created By"
                            value={auditableInfoApi?.data?.createdBy || "-"}
                            type="text"
                            disabled={true}
                        />

                        <InputField
                            label="Creation Date"
                            value={formatValueAsDate(creationDate) || ""}
                            type="text"
                            disabled={true}
                        />

                        <InputField
                            label="Modified By"
                            value={auditableInfoApi?.data?.modifiedBy || "-"}
                            type="text"
                            disabled={true}
                        />

                        <InputField
                            label="Modified Date"
                            value={formatValueAsDate(modifiedDate) || ""}
                            type="text"
                            disabled={true}
                        />

                    </>
                }
            />
        </>
    )
}
export default AuditableInfoModal;