import {DataTableColumn, getDefaultColumnVisibility} from "../datatable/DataTableTypes";
import {UserSetting} from "./UserSettingTypes";

export function modifyPageSizeSetting(groupName: string, userSettingsData: any[], pageSize: number) {
    const pageSizeKey = 'pageSize'
    let pageSizeExists = false;

    const updatedUserSettings = userSettingsData?.map(cur => {
        if (cur.key !== pageSizeKey) {
            return cur;
        }

        pageSizeExists = true;
        return {
            ...cur,
            value: pageSize
        }
    }) || [];

    // If pageNumber attribute doesn't exist, add it
    if (!pageSizeExists) {
        updatedUserSettings.push({
            group: groupName,
            key: pageSizeKey,
            value: pageSize
        });
    }

    return updatedUserSettings;
}


export function getDefaultUserSettingsForGroup(column: DataTableColumn[], customerLevel: 'CUSTOMER' | 'TENANT' | 'SYS', groupName: string) {
    let defaultUserSettings = [
        {
            group: groupName,
            key: 'pageSize',
            value: '15'
        },
        {
            group: groupName,
            key: 'pageControl',
            value: 'BOTTOM'
        },
        {
            group: groupName,
            key: 'linkOpeningStyle',
            value: 'CURRENT TAB'
        }
    ] as UserSetting[]

    column.forEach((column) => {
        if (!column) return;

        const visibility = column.visibility || getDefaultColumnVisibility();
        if (!visibility.canColumnShow || !visibility?.hasShowSetting || visibility?.disallowedCustomerLevels?.includes(customerLevel)) return;

        defaultUserSettings.push({
            group: groupName,
            key: column?.label,
            value: visibility.showByDefault ? "show" : "hide",
        });
    });

    return defaultUserSettings;
}

// @ts-ignore
export function computePageControl(userSettings, type) {
    if (userSettings?.error !== undefined) return;

    let setting = userSettings?.find((cur: UserSetting) => cur.key === 'pageControl');
    if (setting == null) {
        return type !== 'TOP';
    }

    if (setting.value.toUpperCase() === type) {
        return true;
    }

    return setting.value.toUpperCase() === 'BOTH';
}
