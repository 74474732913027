import React, {FC} from "react";
import {ModalProps} from "../../../helpers/constants";
import Modal from "../../../components/common/form/Modal";
import {formatValueAsDate} from "../../../helpers/utils/DateUtils";
import {DetailRow, getFullPersonName} from "../../../helpers/utils/OrderDetailUtils";

interface props extends ModalProps {
    recordingDocument: any
}

const VendorInfoModal: FC<props> = ({recordingDocument, show, setShow}) => {

    const vendorDetails = recordingDocument?.vendorDetails;

    return (
        <>
            <Modal title={"Vendor Information"} show={show} setShow={setShow} className={"w-3/5 mx-4"}
                   fields={
                       <div>
                           <DetailRow label={'Name'} value={vendorDetails?.vendorName}/>
                           <DetailRow label={'Status'} value={vendorDetails?.vendorStatus} isValueSpan={true}/>
                           <DetailRow label={'Document Type'} value={vendorDetails?.vendorDocumentType}/>
                           <DetailRow label={'Page Count'} value={vendorDetails?.vendorPageCount}/>
                           <DetailRow label={'Document Position'} value={vendorDetails?.vendorPosition}/>
                           <DetailRow label={'First Transaction'}
                                      value={formatValueAsDate(vendorDetails?.vendorTransactionInfo?.vendorFirstTransactionDate) || '-'}/>
                           <DetailRow label={'Last Transaction'}
                                      value={formatValueAsDate(vendorDetails?.vendorTransactionInfo?.vendorLastTransactionDate) || '-'}/>
                           <DetailRow label="Grantors"
                                      value={recordingDocument?.grantors
                                          ?.filter((g: any) => g)
                                          ?.map(getFullPersonName)
                                          .join(', ')
                                      }
                                      className='text-wrap'/>
                           <DetailRow label="Grantees"
                                      value={recordingDocument?.grantees
                                          ?.filter((g: any) => g)
                                          ?.map(getFullPersonName)
                                          .join(', ')
                                      }
                                      className='text-wrap'/>
                       </div>
                   }/>
        </>
    )
}

export default VendorInfoModal