import {FC, useEffect, useState} from "react"
import Breadcrumb from "../../../components/common/Breadcrumbs";
import {useFindUserSettingsByGroupNameQuery} from "../../../components/common/userSetting/userSettingsApi";
import {useSearchSystemPropertiesQuery} from "./systemPropertiesApi";
import {createClickableLink} from "../../../components/common/datatable/DataTableUtils";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {DataTableColumn} from "../../../components/common/datatable/DataTableTypes";


const SystemProperties: FC = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/home"},
        {content: "System Properties", url: ""},

    ])

    useEffect(() => {
        document.title = "System Properties";
    });

    const {data: userSettings} = useFindUserSettingsByGroupNameQuery("System Property");

    const columns: DataTableColumn[] = [
        {
            label: "Name",
            accessor: "name",
            cell: (props: any) => {
                let path = `/system-properties/${props.row.original.id}`;
                return createClickableLink(path, props.value, userSettings, false, "", ["SPRO-R", "SPRO-V"]);
            },
        },
        {label: "Value", accessor: "value"},
    ]

    return (

        <div className="user-container px-5 py-5">
            <div className="flex justify-between">
                <Breadcrumb crumbs={crumbs}/>
            </div>

            <DataTable
                columns={columns}
                actions={{
                    fetchAction: useSearchSystemPropertiesQuery
                }}
                userSettingGroup={"System Property"}
            />

        </div>

    )
}
export default SystemProperties;