import {strApi} from "../../../app/strApi";
import {serialize} from "../../../helpers/utils/Utils";
import {getAuthorizationHeader, handleQueryError, handleQueryErrorAndSuccess} from "../../../helpers/utils/RtkQueryUtils";

export const branchContactApi = strApi.injectEndpoints({
    endpoints: (build) => ({

        branchContacts: build.query({
            query: (data) => {
                // Serialize the data to query parameters
                let paramData = serialize(data);
                return {
                    url: `/branch-contacts/by-branch?${paramData}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            providesTags: ['branchContacts'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        getBranchContactById: build.query({
            query: (id) => {
                return {
                    url: `/branch-contacts/${id}`,
                    method: 'GET',
                    headers: getAuthorizationHeader()
                };
            },
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryError(queryFulfilled)
            },
        }),

        createBranchContact: build.mutation({
            query: ({branchId, requestBody}) => {

                let paramData = serialize({branchId: branchId});

                return {
                    url: `/branch-contacts?${paramData}`,
                    method: 'POST',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['branchContacts'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Created", "Branch Contact");
            },
        }),

        updateBranchContact: build.mutation({
            query: ({contactId, requestBody}) => {
                return {
                    url: `/branch-contacts/${contactId}`,
                    method: 'PUT',
                    headers: getAuthorizationHeader(),
                    body: requestBody
                };
            },
            invalidatesTags: ['branchContacts'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Updated", "Branch Contact");
            },
        }),

        deleteBranchContactById: build.mutation({
            query: (id) => {
                return {
                    url: `/branch-contacts/${id}`,
                    method: 'DELETE',
                    headers: getAuthorizationHeader()
                };
            },
            invalidatesTags: ['branchContacts'],
            async onQueryStarted(_, {queryFulfilled,}) {
                await handleQueryErrorAndSuccess(queryFulfilled, "Deleted", "Branch Contact");
            },
        }),
    })
})

export const {
    useCreateBranchContactMutation,
    useGetBranchContactByIdQuery,
    useUpdateBranchContactMutation,
    useBranchContactsQuery,
    useDeleteBranchContactByIdMutation
} = branchContactApi