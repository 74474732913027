import {ModalProps} from "../../../../helpers/constants";
import React, {FC, FormEvent, useEffect, useState} from "react";
import Modal from "../../../../components/common/form/Modal";
import ModalButton from "../../../../components/common/form/ModalButton";
import {FaTrash} from "react-icons/fa6";
import ConfirmationModal from "../../../../components/layout/modal/ConfirmationModal";
import {
    useDeleteSimplifileTokenMutation,
    useUpdateSimplifileTokenMutation,
    useFetchSimplifileTokenStatusQuery
} from "../usersApi";
import Form from "../../../../components/common/form/Form";
import {useParams} from "react-router-dom";
import {handleRTKQuery} from "../../../../helpers/utils/RtkQueryUtils";
import InputField from "../../../../components/common/form/InputField";
import {skipToken} from "@reduxjs/toolkit/query";
import ButtonTray from "../../../../components/common/form/ButtonTray";

const SimplifileTokenModal: FC<ModalProps> = ({show, setShow}) => {

    let {userId} = useParams();
    let [token, setToken] = useState<any>("");
    const simplifileTokenApi = useFetchSimplifileTokenStatusQuery(userId ?? skipToken);
    let [userData, setUserData] = useState<any>({});
    const [updateSimplifileToken, updateSimplifileTokenApiResponse] = useUpdateSimplifileTokenMutation();
    const [deleteSimplifileToken] = useDeleteSimplifileTokenMutation();

    useEffect(() => {
        let userObj = simplifileTokenApi?.data;

        setUserData(
            {
                simplifileToken: userObj ? "123467788" : null
            }
        );

    }, [simplifileTokenApi?.data]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (token) {
            await handleRTKQuery(
                async () => {
                    return await updateSimplifileToken({id: userId, simplifileToken: token}).unwrap();
                },
                () => {
                    setToken("");
                    setUserData("");
                    setShow(false);

                }
            )
        }
    }

    const handleRemoveSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        await handleRTKQuery(
            async () => {
                return await deleteSimplifileToken(userId).unwrap();
            },
            () => {
                setUserData({
                    simplifileToken : ""
                })
                setToken("");
            }
        )
    }

    return (
        <>
            <Modal title={"SimpliFile Token"} show={show} setShow={setShow} className="w-3/5 mx-4" fields={
                <Form onSubmit={handleSubmit} fields=
                    {
                        <>
                            <InputField
                                name="simpliFileToken"
                                label="SimpliFile Token"
                                onChange={(e: any) => {
                                    token = e.target.value;
                                    setToken(token);
                                }}
                                value={token || userData?.simplifileToken}
                                disabled={userData?.simplifileToken}
                                type="password"
                                placeholder="Enter SimpliFile Token..."
                                icon={
                                    userData.simplifileToken &&
                                    <>
                                        <ModalButton
                                            triggerButton={
                                                <FaTrash
                                                    className={"cursor-pointer text-highlight-3 text-lg hover:text-highlight-7 ml-3"}
                                                    title={"Remove Current Token"}/>
                                            }
                                            onOpen={() => {
                                            }}
                                            modal={
                                                <ConfirmationModal
                                                    show={true}
                                                    setShow={() => {
                                                    }}
                                                    title={"Remove Current Token"}
                                                    bodyMessage={"Are you sure you want to remove this token ?"}
                                                    onSubmit={handleRemoveSubmit}
                                                    isLoading={false}
                                                />
                                            }
                                        />
                                    </>
                                }

                            />


                            <ButtonTray
                                buttons={[
                                    {
                                        buttonProps: {
                                            btnText: "Cancel",
                                            onClick: () => setShow(false),
                                            type: "close",
                                        },
                                        buttonType: "button"
                                    },
                                    {
                                        buttonProps: {
                                            btnText: "Submit",
                                            type: "submit",
                                            isLoading: (updateSimplifileTokenApiResponse.isLoading),
                                            isDisabled: !token
                                        },
                                        buttonType: "button",

                                    }
                                ]}
                                align="end"
                                gap={2}
                            />

                        </>
                    }/>
            }/>

        </>
    )

}

export default SimplifileTokenModal;