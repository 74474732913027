// Define types for your enums and form data
export interface EnumOption {
    constant: string;
    displayName: string;
}

export let priorityCategories = [
    {displayName: "Low", constant: "Low"},
    {displayName: "Medium", constant: "Medium"},
    {displayName: "High", constant: "High"},
    {displayName: "Extreme", constant: "Extreme"}
]