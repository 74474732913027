import {useState} from "react";
import StateForm from "./StateForm";

const CreateState = () => {

    const [crumbs] = useState([
        {content: "Home", url: "/"},
        {content: "States", url: "/states"},
        {content: "Create State", url: "/states/create"},
    ]);

    return (
        <>
            <StateForm crumbs={crumbs} title={"Create State"} btnText={"Create"}/>
        </>
    )
}
export default CreateState;