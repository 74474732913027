// SideBar.tsx
import React, {useState} from "react";
import "./sideBar.modules.css";
import {
    FaAngleDoubleRight,
    FaAngleDoubleLeft,
    FaDatabase,
    FaTable,
    FaUsers,
    FaFileInvoiceDollar,
    FaUserLock, FaUserFriends,
} from "react-icons/fa";
import {GoHomeFill} from "react-icons/go";
import {useDispatch, useSelector} from "react-redux";
import {setShowSidebar} from "../../../app/globalSlice/globalSlice";
import NavItem from "./NavItem";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";

function SideBar() {
    const [openDropdown, setOpenDropdown] = useState<string | null>(null);

    const isCollapsed = useSelector((state: any) => state.global.isCollapsed);
    const dispatch = useDispatch();

    const toggleDropdown = (dropdown: string) => {
        setOpenDropdown(openDropdown === dropdown ? null : dropdown);
    };

    return (
        <aside
            id="default-sidebar"
            className={`default_sidebar pt-12 left-0 sm:translate-x-0 min-h-screen ${
                !isCollapsed && "w-52"
            } text-text-1 top-0 transition-transform z-40 bg-highlight-2 fixed h-screen`}
            aria-label="Sidebar"
        >
            <div className="sm:mt-5 md:mt-0 lg:mt-0">
                <ul className="space-y-2 font-medium sidebar_list_Wrapper">

                    <NavItem
                        route="/home"
                        icon={<GoHomeFill className="text-2xl"/>}
                        label="Home"
                    />

                    {hasPermission(["ORDR-V"]) && <NavItem
                        route="/orders"
                        icon={<FaTable className="text-xl"/>}
                        label="Orders"
                    />}

                    {hasPermission(["USR-V", "CUST-V", "ROL-V", "TEAM-V"]) && <NavItem
                        route="/access-control"
                        icon={<FaUsers className="text-2xl"/>}
                        label="User"
                        dropdownOptions={{
                            childDropdownItems:
                                [
                                    {
                                        label: "Users",
                                        route: "/access-control/users",
                                        hasPermission: hasPermission(["USR-V"])
                                    },
                                    {
                                        label: "Customers",
                                        route: "/access-control/customers",
                                        hasPermission: hasPermission(["CUST-V"])
                                    },
                                    {
                                        label: "Roles",
                                        route: "/access-control/roles",
                                        hasPermission: hasPermission(["ROL-V"])
                                    },
                                    {
                                        label: "Teams",
                                        route: "/access-control/teams",
                                        hasPermission: hasPermission(["TEAM-V"])
                                    },
                                ],
                            isDropdownOpen: openDropdown === "User",
                            onDropdownToggle: () => toggleDropdown("User")
                        }}
                    />}

                    {hasPermission(["PLPRC-V", "TNPRC-V", "PTPRC-V", "VPRC-V"]) && <NavItem
                        route="/accounting"
                        icon={<FaFileInvoiceDollar className="text-xl"/>}
                        label="Accounting"
                        dropdownOptions={{
                            childDropdownItems:
                                [
                                    {
                                        label: "Platform Pricing",
                                        route: "/accounting/platform-pricing",
                                        hasPermission: hasPermission(["PLPRC-V"])
                                    },
                                    {
                                        label: "Tenant Pricing",
                                        route: "/accounting/tenant-pricing",
                                        hasPermission: hasPermission(["TNPRC-V"])
                                    },
                                    {
                                        label: "Partner Pricing",
                                        route: "/accounting/partner-pricing",
                                        hasPermission: hasPermission(["PTPRC-V"])
                                    },
                                    {
                                        label: "Vendor Pricing",
                                        route: "/accounting/vendor-pricing",
                                        hasPermission: hasPermission(["VPRC-V"])
                                    },
                                    {
                                        label: "Revenue Events",
                                        route: "/accounting/revenue-events",
                                        hasPermission: hasPermission(["REVEVT-V"])
                                    }
                                ],
                            isDropdownOpen: openDropdown === "Accounting",
                            onDropdownToggle: () => toggleDropdown("Accounting")
                        }}
                    />}

                    {hasPermission(["STAT-V", "METR-V", "COUN-V", "CANFN-V", "CANNOTE-V", "CANIL-V"]) && <NavItem
                        route="/data"
                        icon={<FaDatabase className="text-xl"/>}
                        label="Data"
                        dropdownOptions={{
                            childDropdownItems: [
                                {
                                    label: "States",
                                    route: "/states",
                                    hasPermission: hasPermission(["STAT-V"])
                                },
                                {
                                    label: "Metro Areas",
                                    route: "/metro-areas",
                                    hasPermission: hasPermission(["METR-V"])
                                },
                                {
                                    label: "Counties",
                                    route: "/counties",
                                    hasPermission: hasPermission(["COUN-V"])
                                },
                                {
                                    label: "Canned File Names",
                                    route: "/canned-file-names",
                                    hasPermission: hasPermission(["CANFN-V"])
                                },
                                {
                                    label: "Canned Notes",
                                    route: "/canned-notes",
                                    hasPermission: hasPermission(["CANNOTE-V"])
                                },
                                {
                                    label: "Canned Invoice Lines",
                                    route: "/canned-invoice-lines",
                                    hasPermission: hasPermission(["CANIL-V"])
                                },
                            ],
                            isDropdownOpen: openDropdown === "Data",
                            onDropdownToggle: () => toggleDropdown("Data"),
                        }}
                    />}

                    {hasPermission(["FOLLUP-V"]) && <NavItem
                        route="/tenant-admin"
                        icon={<FaUserFriends className="text-2xl"/>}
                        label="Follow Up Types"
                        dropdownOptions={{
                            childDropdownItems:
                                [
                                    {
                                        label: "Follow Up Types",
                                        route: "/follow-up-types",
                                        hasPermission: hasPermission(["FOLLUP-V"])
                                    },
                                ],
                            isDropdownOpen: openDropdown === "Follow Up Types",
                            onDropdownToggle: () => toggleDropdown("Follow Up Types"),
                        }}
                    />}

                    {hasPermission(["SPRO-V"]) && <NavItem
                        route="/system-admin"
                        icon={<FaUserLock className="text-2xl"/>}
                        label="System Admin"
                        dropdownOptions={{
                            childDropdownItems: [
                                {
                                    label: "System Properties",
                                    route: "/system-properties",
                                    hasPermission: hasPermission(["SPRO-V"])
                                },
                                {
                                    label: "Simplifile Config",
                                    route: "/simplifile-configs",
                                    hasPermission: hasPermission(["SFCON-V"])
                                },
                            ],
                            isDropdownOpen: openDropdown === "System Properties",
                            onDropdownToggle: () => toggleDropdown("System Properties"),
                        }}
                    />}


                    <li className={`toggle_btn fixed bottom-3 ${isCollapsed === true ? "left-5" : "left-40 "} `}>
                        <button onClick={() => dispatch(setShowSidebar(!isCollapsed))}
                                className="navbar-brand navbar_toggle_btn" id="sidebar-toggle">
                            {isCollapsed === true ? <FaAngleDoubleRight/> : <FaAngleDoubleLeft/>}
                        </button>
                    </li>
                </ul>
            </div>
        </aside>
    );
}

export default SideBar;
