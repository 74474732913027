import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import StateForm from "./StateForm";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";
import {useGetStateByIdQuery} from "./statesApi";

const UpdateState = () => {

    let {stateId} = useParams();

    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getStateById, {stateId}, {
        redirectToErrorPage: true,
        skip: !stateId
    });

    const stateByIdApi = useGetStateByIdQuery(stateId);
    const [crumbs, setCrumbs] = useState<any[]>([]);

    useEffect(() => {
        let stateObj = stateByIdApi?.data;
        if (stateObj) {
            setCrumbs([
                {content: "Home", url: "/home"},
                {content: "States", url: "/states"},
                {content: stateObj?.stateName, url: ""}
            ]);

            document.title = `${stateObj?.stateName}`;
        }
    }, [stateByIdApi]);

    return (
        <>
            <StateForm crumbs={crumbs} title={"Update State"} btnText={"Update"}
                       isEditable={hasPermission(["STAT-U"])}/>

        </>
    )
}
export default UpdateState;