import {isNullOrEmpty} from "./Utils";
import {sliceNames} from "../constants";
import {globalSliceReducerInitialState} from "../../app/globalSlice/globalSlice";


export const getInitialReducerState = (sliceName: string, reducerName: string) => {
    if (isNullOrEmpty(sliceName) || isNullOrEmpty(reducerName)) {
        return {}
    }
    switch (sliceName) {
        case sliceNames.GLOBAL: {
            // @ts-ignore
            return globalSliceReducerInitialState[reducerName]
        }
    }
}
export const getInitialSliceState = (sliceName: string) => {
    if (isNullOrEmpty(sliceName)) {
        return {}
    }
    switch (sliceName) {
        case sliceNames.GLOBAL: {
            // @ts-ignore
            return globalSliceReducerInitialState
        }
    }
}
export const resetReducerByName = (newState: any, reducerNames = []) => {
    Object.keys(newState).forEach(curKey => {
        Object.keys(newState[curKey]).forEach(curReducer => {
            // @ts-ignore
            if (reducerNames.includes(curReducer)) {
                newState[curKey] = {
                    ...newState[curKey],
                    [curReducer]: getInitialReducerState(curKey,curReducer)
                };
            }
        });
    });
    return newState;
}
export const resetAllReducers = (newState: any) => {
    Object.keys(newState).forEach(curKey => {
        newState[curKey] = getInitialSliceState(curKey)
    });
    return newState;
}