import Modal from "../../../components/common/form/Modal";
import {ModalProps} from "../../../helpers/constants";
import React, {FC, useState} from "react";
import {DataTable} from "../../../components/common/datatable/DataTable";
import {useGetAllAssignToMembersQuery} from "../../user/teams/teamsApi";
import Button from "../../../components/common/form/Button";
import {formatValueAsDate} from "../../../helpers/utils/DateUtils";
import {useUpdateAssignmentInfoMutation} from "../productsApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";

interface props extends ModalProps {
    tenantId: string,
    productId: string
}

const AssignProductToUserModal: FC<props> = ({tenantId, productId, show, setShow}) => {

    const columns = [
        {
            label: "Name", accessor: "u.username",cell: (props: any) => {
                return <span>{props.row.original.username}</span>;
            },
        },

        {
            label: "Email", accessor: "u.email", cell: (props: any) => {
                return <span>{props.row.original.email}</span>;
            },
        },

        {
            label: "Last Logged On", accessor: "u.last_login_date",
            cell: (props: any) => {
                return formatValueAsDate(props.row.original.lastLoginDate);
            },
        },

        {
            label: "Queue Size", accessor: "productQueueSize", disableSortBy: true
        }
    ]

    const [selectedId, setSelectedId] = useState<any>(null);
    const [updateAssignmentInfoApi, updateAssignmentInfoApiResponse] = useUpdateAssignmentInfoMutation();

    const handleRowSelection = (newSelectedRow: any, row: any) => {
        setSelectedId(row?.original?.id);
    };

    const handleSubmit = async () => {

        if (selectedId) {
            await handleRTKQuery(() => {
                updateAssignmentInfoApi({productId: productId, assigneeId: selectedId, assigneeType: "USER",reason : "Manually assigned to user"});
            }, () => {
                setShow(false);
            });
        }
    }

    return (
        <>
            <Modal title={"Add Product to User"} show={show} setShow={setShow} className={"w-3/5 mx-4"}
                   fields={
                       <>

                           <DataTable
                               columns={columns}
                               actions={{
                                   fetchAction: useGetAllAssignToMembersQuery
                               }}
                               defaultFilter={{
                                   tenantId: tenantId,
                               }}
                               renderOptions={{
                                   skipUserSettingRender: true
                               }}
                               rowSelectionOptions={{
                                   enableRowSelection: true,
                                   onSelectionChange: handleRowSelection,
                                   enableMultiRowSelection: false,
                                   rowIdAccessor: 'id'
                               }}
                               defaultSortOptions={{
                                   accessor: "u.username",
                                   direction: "asc"
                               }}
                               appendFilterToUrl={false}
                           ></DataTable>

                           <div className="flex justify-end gap-1 border-t border-surface-3 pt-4 mt-4">
                               <Button btnText={"Close"} isLoading={false} type={"close"}
                                       onClick={() => setShow(false)}/>
                               <Button btnText={"Assign"} isLoading={false}
                                       onClick={handleSubmit}
                                       isDisabled={updateAssignmentInfoApiResponse.isLoading || !selectedId}
                                       type={"submit"}></Button>
                           </div>

                       </>
                   }
            />
        </>
    )
}
export default AssignProductToUserModal;