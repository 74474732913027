import {useParams} from "react-router-dom";

import {useEffect, useState} from "react";
import PartnerPricingForm from "./PartnerPricingForm";
import {hasPermission} from "../../../helpers/utils/AccessControlUtils";
import {fetchWithExtraOptions, strApi} from "../../../app/strApi";
import {useGetPartnerPricingByIdQuery} from "./partnerPricingApi";

const UpdatePartnerPricing = () => {

    let {partnerPricingId} = useParams();
    // @ts-ignore
    fetchWithExtraOptions(strApi.endpoints.getPartnerPricingById, {partnerPricingId}, {
        redirectToErrorPage: true,
        skip: !partnerPricingId
    });

    const partnerByIdApi = useGetPartnerPricingByIdQuery(partnerPricingId);
    const [crumbs, setCrumbs] = useState<any[]>([]);

    useEffect(() => {
        let obj = partnerByIdApi?.data;
        if (obj) {
            setCrumbs([
                {content: "Home", url: "/"},
                {content: "Partner Pricing", url: "/accounting/partner-pricing"},
                {content: obj?.productType, url: ""}
            ]);

            document.title = `${obj?.productType}`;
        }
    }, [partnerByIdApi]);

    return (
        <>
            <PartnerPricingForm crumbs={crumbs} title={"Update Partner Pricing"} btnText={"Update"}
                                isEditable={hasPermission(["PTPRC-U"])}/>
        </>
    )
}
export default UpdatePartnerPricing;