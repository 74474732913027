import {LazyQueryTrigger, UseMutation, UseQuery} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {ReactNode} from "react";

export interface FilterOptions {
    // List of column accessors that are dependent on this column's value. Defaults to [].
    dependentColumns?: string[]

    // Function that will run when a column that has this column listed as a dependency updates.
    onDependencyUpdated?: Function;

    // Component used to select the value by which to filter this column.
    filterComponent?: any;

    // Whether to hide the label for this filter option. Defaults to false.
    hideLabel?: boolean

    //Text to display for this filter option. Only necessary if the label field on the column isn't desired.
    overrideFilterLabel?: string;

    // Name of the property this option filters by. Only necessary if the accessor on the column isn't desired.
    overrideFilterAccessor?: string;

    // Whether to force this onto a new row of filter fields. Defaults to false. NOT IMPLEMENTED YET :)
    forceRowBreakBefore?: boolean;
}

export interface ColumnVisibility {
    // If false, will never show on the table or in the user settings. Defaults to true.
    canColumnShow?: boolean;

    // If true, show. If false, don't show. Overridden by user settings. Defaults to true.
    showByDefault?: boolean;

    // If true, the column can be hidden/shown by user in User Settings modal. Requires canColumnShow to be true. Defaults to true.
    hasShowSetting?: boolean;

    // The customer levels that should not be able to see this column. Defaults to [].
    disallowedCustomerLevels?: string[];
}

export const getDefaultColumnVisibility = () => {
    return {
        canColumnShow: true,
        showByDefault: true,
        hasShowSetting: true,
        disallowedCustomerLevels: []
    } as ColumnVisibility;
}

export interface DataTableColumn {
    // The header text to show for this column
    label: string | any;

    // The name of the property this column reads from the data
    accessor: string;

    // Whether this column can be sorted. Defaults to true.
    canSort?: boolean;

    // The value to use when sorting - by default, uses the accessor, but this overrides that.
    overrideSortColumn?: string;

    // The options that define how a user would filter by this column
    filterOptions?: FilterOptions[];

    // The Cell element, this should be JSX/TSX. If unspecified, will display the value as text.
    cell?: any;

    visibility?: ColumnVisibility;

}

export interface DataItem {
}

export interface DataPage {
    data: DataItem[];

    hasContent: boolean;
    hasNext: boolean;
    hasPrevious: boolean;
    isFirst: boolean;
    isLast: boolean;

    totalElements: number;
    totalPages: number;

    perPage: number;
    pageNumber: number;
    size: number;
    pluralResourceName: string;
    displayingText: string;

    isSorted: boolean;
    sortColumn: string;
    sortOrder: string;
}

export interface RowSelectionOptions {
    // Whether rows can be selected for this table. Defaults to false.
    enableRowSelection: boolean;

    // Function that takes in the selected rows and the row that was clicked, and handles the event.
    // The function signature is (selectedRows: Set<any>, targetRow: any) => void.
    onSelectionChange: (selectedRows: Set<any>, targetRow: any) => void;

    // Whether multiple rows can be selected for this table. 'enableRowSelection' must be true for this to be evaluated. Defaults to false.
    enableMultiRowSelection?: boolean;

    // Function that takes in a row, evaluates it, and returns whether it should be selectable. If unset, all rows will be selectable.
    rowSelectionPredicate?: (row: any) => boolean;

    // The accessor to use when storing rows in the rowSelectionState. Defaults to "id".
    rowIdAccessor?: string;

    // The extra styles to apply when a row is selected.
    rowSelectedStyle?: string;

    // The extra styles to apply when a row is not selectable.
    rowUnselectableStyle?: string;
}


export interface DataTableActions {
    // The RTK Query used to fetch the data
    fetchAction: UseQuery<any>;

    // The RTK query used to export the data
    exportAction?: LazyQueryTrigger<any>;

    // The RTK query used to delete the data
    deleteAction?: UseMutation<any>;
}

export interface DefaultSortOptions {
    accessor: string;
    direction?: 'asc' | 'desc';
}

export interface RenderOptions {

    // Whether the table render should be skipped. This is helpful in cases to provide an export without a view. Default to false.
    skipTableRender?: boolean;

    // Whether the filter section render should be skipped. Default to false.
    skipFilterRender?: boolean;

    // Whether the button section render should be skipped. Default to false.
    skipButtonSectionRender?: boolean;

    // Whether the pagination section render should be skipped. Default to false.
    skipPaginationRender?: boolean;

    // Whether the user setting render should be skipped. Default to false.
    skipUserSettingRender?: boolean;
}


export interface DataTableButton {
    // The button component itself, which could be any React element (e.g., IconButton, Button).
    button: ReactNode;

    // An optional array of permissions required to display or enable this button.
    // If provided, the button will only be rendered or enabled if the user has at least one of the specified permissions (ANY permission in the list).
    // If not provided, the button will be rendered without any permission checks.
    permissions?: string[];
}


export interface ButtonOptions {
    // Component used to facilitate adding an item
    addButton?: DataTableButton;

    // An array of components that will be rendered in the button row above the table.
    // They will be rendered in the provided order, with the 'Toggle Filter Form Expanded' button and the 'Add' button (if applicable) following them.
    extraButtons?: DataTableButton[];
}

export interface DataTableProps {
    // Defines the columns to render in the table and their corresponding filter fields.
    columns: DataTableColumn[]
    actions: DataTableActions;

    defaultSortOptions?: DefaultSortOptions;

    // All options related to row selection. Defaults to default RowSelectionOption.
    rowSelectionOptions?: RowSelectionOptions;

    // Defines the buttons, and their behavior, that are placed above the table. Defaults to null.
    buttonOptions?: ButtonOptions;

    // Defines the sections that should be rendered. Defaults to empty, resulting in everything being rendered. Defaults to null.
    renderOptions?: RenderOptions;

    // Name of the group of settings within user settings for this table. Defaults to null.
    userSettingGroup?: string;

    // Default filter to apply to this table. Defaults to null.
    defaultFilter?: object;

    // Default value of filterFormExpanded. If true, the filterForm will be expanded when the DataTable is first loaded. Defaults to false.
    defaultFilterFormExpanded?: boolean;

    // Whether to append the filter parameters to the URL. Usually this will be disabled for modals or otherwise embedded tables. Defaults to true.
    appendFilterToUrl?: boolean;

    // The base permission required to access this data i.e "ORDR","USR". Defaults to null.
    basePermission?: string;
}