import React, {FC, useState} from "react";
import "./Footer.css"

const Footer: FC = () => {

    const [modalState,setModalState]=useState({
        show:false,
        type:"",
    })

    const handleShowModal=(type:string)=>{
        setModalState({
            show: true,
            type:type
        })
    }

    return (
        <div className=' w-full bg-highlight-1 py-3 relative'>
            <div className='footer_outer m-auto  '>
                <div className=''>
                    <ul className='  block text-center sm:flex sm:items-center sm:justify-center sm:gap-4 text-text-1'>
                        <div className={"mb-0 mt-2 sm:mt-0"}> © {new Date().getFullYear()} Settlement Reporting, LLC</div>
                    </ul>
                </div>

            </div>
        </div>
    );
}
export default Footer