import Breadcrumb, {Crumb} from "../../../components/common/Breadcrumbs";
import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Form from "../../../components/common/form/Form";
import {useCreateCountyMutation, useGetCountyByIdQuery, useUpdateCountyMutation} from "./countiesApi";
import {skipToken} from "@reduxjs/toolkit/query";
import InputField from "../../../components/common/form/InputField";
import {OptionType} from "../../../helpers/utils/StyleUtils";
import {createOptionListForSelectTag} from "../../../helpers/utils/Utils";
import SelectField from "../../../components/common/form/SelectField";
import {useGetAllStatesQuery} from "../states/statesApi";
import {handleRTKQuery} from "../../../helpers/utils/RtkQueryUtils";
import AuditableInfoButton from "../../../components/common/form/AuditableInfoButton";
import {Routes as AppRoutes} from "../../../routes";
import ButtonTray from "../../../components/common/form/ButtonTray";

interface props {
    crumbs: Crumb[];
    title: string,
    btnText: string,
    isEditable?: boolean
}

const CountyForm: FC<props> = ({crumbs, title, btnText, isEditable}) => {

    const navigate = useNavigate();
    let {countyId} = useParams();

    const [createCounty, createCountyApiResponse] = useCreateCountyMutation();
    const [updateCounty, updateCountyApiResponse] = useUpdateCountyMutation();
    const countyByIdApi = useGetCountyByIdQuery(countyId ?? skipToken);
    const [countyData, setCountyData] = useState<any>({});
    const statesApiResult = useGetAllStatesQuery("");

    useEffect(() => {
        let obj = countyByIdApi?.data;
        if (obj) {
            setCountyData({
                ...obj,
                state: {
                    label: obj.state?.stateName,
                    value: obj.state?.id,
                }
            })
        }
    }, [countyByIdApi?.data]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let commonObj = {
            ...countyData,
            state: statesApiResult?.data.filter((el: any) => el.id === countyData.state.value)?.[0]
        };

        const createOrUpdateCounty = async () => {
            if (btnText === "Create") {
                await createCounty(commonObj).unwrap();
            } else {
                await updateCounty({
                    id: countyId,
                    requestBody: commonObj,
                }).unwrap();
            }
        };

        await handleRTKQuery(createOrUpdateCounty, () => {
            setCountyData({});
            navigate("/counties");
        });
    }

    const handleOnChange = (e: ChangeEvent<HTMLInputElement> | { name: string; value: OptionType | null }) => {
        const {name, value} = "target" in e ? e.target : e;
        setCountyData({
            ...countyData,
            [name]: value,
        })
    }

    return (
        <>
            <div className="px-4 py-2">

                <div className={"justify-between sm:block md:flex lg:flex"}>
                    <Breadcrumb crumbs={crumbs}/>
                    <AuditableInfoButton obj={countyByIdApi?.data}/>
                </div>

                <div className="flex justify-center mt-3">
                    <div className="bg-surface-2 lg:w-4/6 sm:w-full p-5 rounded-md">
                        <h3 className="text-xl font-semibold text-text-1 pb-3 border-b border-surface-3">{title}</h3>
                        <Form onSubmit={handleSubmit} fields={<>
                            <div className="grid grid-cols-12">

                                <InputField
                                    name={"name"}
                                    label="Name"
                                    onChange={handleOnChange}
                                    value={countyData?.name || ""}
                                    type="text"
                                    placeholder="Enter Name..."
                                    required={true}
                                />

                                <SelectField
                                    placeholder='Select State...'
                                    value={countyData?.state}
                                    options={createOptionListForSelectTag(statesApiResult?.data, "stateName", "id")}
                                    onChange={(selectedOption) => {
                                        handleOnChange({name: "state", value: selectedOption})
                                    }}
                                    label={"State"}
                                    required={true}
                                />

                                <InputField
                                    name={"fullFipsCode"}
                                    label="FIPS Code"
                                    onChange={handleOnChange}
                                    value={countyData?.fullFipsCode || ""}
                                    type="number"
                                    placeholder="Enter FIPS Code..."
                                    required={true}
                                />

                            </div>

                            <ButtonTray
                                buttons={[
                                    {
                                        buttonProps: {
                                            btnText: "Cancel",
                                            onClick: () => navigate(AppRoutes.Counties.path),
                                            type: "close",
                                        },
                                        buttonType: "button"
                                    },
                                    {
                                        buttonProps: {
                                            btnText: "Submit",
                                            type: "submit",
                                            isLoading: (createCountyApiResponse.isLoading || updateCountyApiResponse.isLoading),
                                            isVisible: isEditable
                                        },
                                        buttonType: "button",

                                    }
                                ]}
                                align="end"
                                gap={2}
                            />

                        </>}
                        />

                    </div>
                </div>
            </div>
        </>
    )
}
export default CountyForm;